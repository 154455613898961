import {defaultPlot, hemoglucoPlot} from "./Plots.js";

export function structureData(inputData) {
    let formattedData = [
        [
            "date",
            "value",
            "unit",
        ]
    ]
    
    Object.values(inputData).forEach(exam => {
        formattedData.push([
            exam.date,
            exam.value,
            exam.unit,
        ])
    })

    return formattedData;
};

export function structureMeanData(inputData) {
    let formattedData = [
        [
            "date",
            "value"
        ]
    ]
    
    Object.values(inputData).forEach(exam => {
        formattedData.push([
            exam.date,
            exam.value
        ])
    })

    return formattedData;
};

export function structureMedsData(inputData) {
    let formattedData = [
    ]
    
    Object.values(inputData).forEach(exam => {
        formattedData.push([
            exam.date,
            exam.meds
        ])
    })

    return formattedData;
};

export function plotExam(data, examType, t) {
    switch (examType.type) {
        case "41653-7":
            return hemoglucoPlot(data, t)
        default:
            return defaultPlot(data, t)
    }
};