import React from "react";
import "react-h5-audio-player/lib/styles.css";
import { connect } from "react-redux";
import AppointmentStep from "../../components/appointment/AppointmentStep";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PhysicalExploration = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div>
        <AppointmentStep
          profession={props.appointment.practitioner}
          practitionerName={props.appointment.practitionerName}
          step="physicalExploration"
          stepName={t("diagnosis-and-exams")}
          subStepName={t("physical-exploration")}
          stepNumber={t("step-2")}
          maxStepsAllForm={8}
          patientName={props.appointment.patientName}
          motive={props.appointment.appointmentName}
          activeStep={3}
          currentStep={3}
        />
      </div>
    </>
  );
};

PhysicalExploration.propTypes = {
  appointment: PropTypes.object,
};

export default connect()(PhysicalExploration);
