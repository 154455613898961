import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = (theme) =>
  makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    displayFlex: {
      display: "flex",
    },
    marginTop: {
      marginTop: 15,
    },
    displayHidden: {
      display: "none",
    },
    centerVertically: {
      display: "flex",
      "align-items": "center",
      margin: "auto",
      "justify-content": "center",
      "flex-wrap": "nowrap",
    },
    marginCircular: {
      margin: 10,
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between",
    },
    verticalAlignBlock: {
      display: "block",
      margin: "auto",
      padding: 10,
    },
    rightAlign: {
      display: "flex",
      "justify-content": "flex-end",
    },
  }));

export const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    backgroundColor: "#e0e0e0",
  },
}))(LinearProgress);

export const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#F1F8FF",
      borderBottom: "2px solid",
      borderColor: "#FA6A09",
    },
  },
};

export default useStyles;
