import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import clsx from "clsx";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "react-h5-audio-player/lib/styles.css";
import SwipeableViews from "react-swipeable-views";
import {
  CardContent,
  Card,
  Typography,
  Grid,
  Avatar,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  IconButton,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
  loadExpertSystem,
  loadPatientMedicalRecord,
  loadPractitioners,
} from "../../services/utils/load-data-services";
import {
  AccountDetailsOutline,
  WaterCheckOutline,
  AccountOutline,
  AccountTieOutline,
  ClipboardText,
  Pencil,
} from "mdi-material-ui";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./styles";
import TabPanel from "./sections/TabPanel";

const getData = (state) => ({
  user: state.user,
  patients: state.patients.patients,
  practitioners: state.practitioners.practitioners,
  expertSystem: state.patientExpertSystem.expertSystem,
  medicalRecord: state.patientMedicalRecord.medicalRecord,
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MedicalRecord = ({
  props,
  user,
  patients,
  practitioners,
  expertSystem,
  medicalRecord,
  dispatch,
}) => {
  let history = useHistory();
  const dialogRef = useRef();
  const classes = useStyles(theme);
  let { id } = useParams();
  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const [loadingMedicalRecord, setLoadingMedicalRecord] = React.useState(false);

  if (!patients) {
    history.push("/patients");
  }

  const [tab, setTab] = React.useState(0);
  const [patient, setPatientValue] = React.useState(
    patients ? patients.find((pat) => pat.id === id) : null
  );

  useEffect(() => {
    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }

    if (!expertSystem) {
      loadExpertSystem(id, user, dispatch);
    }

    if (
      (!medicalRecord.length && !loadingMedicalRecord) ||
      id !== medicalRecord.id
    ) {
      loadPatientMedicalRecord(id, user, dispatch);
    }
  }, [user, patients, expertSystem, practitioners]);

  console.log(medicalRecord, "medical-page");

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  function parseToDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  function parseExam(exam) {
    return exam.unit === "-"
      ? exam.value.toString() + " - " + parseToDate(exam.date)
      : exam.value.toString() + exam.unit + " - " + parseToDate(exam.date);
  }

  const birthday_parts = medicalRecord
    ? medicalRecord.data.patient_birthDate
      ? medicalRecord.data.patient_birthDate.split("-")
      : ""
    : "";
  const birthday = medicalRecord
    ? new Date(
        birthday_parts[1] + "/" + birthday_parts[2] + "/" + birthday_parts[0]
      )
    : "";
  const ageDifMs = medicalRecord ? Date.now() - birthday.getTime() : "";
  const exams = medicalRecord
    ? medicalRecord.data.exams
      ? Object.keys(medicalRecord.data.exams)
      : ""
    : "";
  const last_encounter = medicalRecord
    ? medicalRecord.data.last_encounter
      ? Object.keys(medicalRecord.data.last_encounter)[0]
        ? parseToDate(
            Object.keys(medicalRecord.data.last_encounter)[0].split("T")
          )
        : "No hay encuentros. "
      : "No hay encuentros."
    : "";
  console.log(last_encounter);
  function medicalRecordPatient(medicalRecord) {
    return {
      lastEncounter: moment(medicalRecord.data.last_encounter)
        ? moment(medicalRecord.data.last_encounter).format("DD/MM/YYYY")
        : "No data",
      id: id,
      gender: t(medicalRecord.data.patient_gender),
      organization: medicalRecord.data.organization_name,
      ageDate: new Date(ageDifMs), // miliseconds from epoch
      cholesterol:
        "Colesterol [masa / volumen] en suero o plasma." in
        medicalRecord.data.exams
          ? parseExam(
              medicalRecord.data.exams[
                "Colesterol [masa / volumen] en suero o plasma."
              ]
            )
          : "No data", // A traductor
      creatinine:
        "Creatinina [masa / volumen] en suero o plasma." in
        medicalRecord.data.exams
          ? parseExam(
              medicalRecord.data.exams[
                "Creatinina [masa / volumen] en suero o plasma."
              ]
            )
          : "No data", // A traductor
      hemoglobin:
        "Hemoglobina glicosilada en sangre." in medicalRecord.data.exams
          ? parseExam(
              medicalRecord.data.exams["Hemoglobina glicosilada en sangre."]
            )
          : "No data", // A traductor
      microalbuminuria:
        "Microalbúmina [masa / volumen] en la orina." in
        medicalRecord.data.exams
          ? parseExam(
              medicalRecord.data.exams[
                "Microalbúmina [masa / volumen] en la orina."
              ]
            )
          : "No data", // A traductor
      glycemia:
        "Glucosa capilar [masa / volumen] por glucómetro." in
        medicalRecord.data.exams
          ? parseExam(
              medicalRecord.data.exams[
                "Glucosa capilar [masa / volumen] por glucómetro."
              ]
            )
          : "No data", // A traductor
    };
  }

  let currentRecord = medicalRecord ? medicalRecordPatient(medicalRecord) : [];
  console.log(currentRecord);
  return (
    <>
      <div>
        {/*<Title backButton={true} title="Historial Paciente" addButton={true} addButtonText="Nueva Consulta"*/}
        {/*       addButtonFunc={handleClickOpen}/>*/}
        <Card className={classes.noPadding} elevation={0}>
          <CardContent>
            <Card
              className={classes.root}
              variant="outlined"
              style={{ border: 0 }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item className={classes.verticalAlign}>
                    <Avatar
                      className={clsx(
                        classes.extraLargeAvatar,
                        classes.borderRadiusAvatar
                      )}
                      style={{
                        minWidth: "180px",
                        border: "3px solid transparent",
                        background: "#0C2975",
                      }}
                      alt="Travis Howard"
                      src="/profile.png"
                    />

                    {/*<Avatar src="/broken-image.jpg" className={classes.large} />*/}
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <Typography>
                          <span style={{ fontSize: 16 }}>
                            {t("medical-record-patient-name")}
                          </span>
                        </Typography>
                        <span style={{ fontSize: 28 }}>
                          {patient ? patient.given + " " + patient.family : ""}
                        </span>
                      </Grid>
                      <Divider className={classes.marginBottom} />
                    </Grid>

                    <Divider
                      style={{
                        background: "#FA6A09",
                        marginBottom: 10,
                        marginTop: 5,
                      }}
                    />

                    <Grid container direction="row">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={clsx(
                          classes.spaceBetween,
                          classes.paddingTop3
                        )}
                      >
                        <Grid item xs={4}>
                          <Grid container direction="column">
                            <Typography
                              component={"span"}
                              className={classes.title}
                            >
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 400,
                                  fontSize: 16,
                                }}
                              >
                                {t("medical-record-patient-sex")}
                              </span>
                            </Typography>
                            {!medicalRecord || id !== medicalRecord.id ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                {currentRecord.gender}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container direction="column">
                            <Typography
                              component={"span"}
                              className={classes.title}
                            >
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 400,
                                  fontSize: 16,
                                }}
                              >
                                {t("medical-record-patient-age")}
                              </span>
                            </Typography>
                            {!medicalRecord || id !== medicalRecord.id ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                {medicalRecord
                                  ? Math.abs(
                                      currentRecord.ageDate.getUTCFullYear() -
                                        1970
                                    )
                                  : ""}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container direction="column">
                            <Typography
                              component={"span"}
                              className={classes.title}
                            >
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 400,
                                  fontSize: 16,
                                }}
                              >
                                {t("medical-record-patient-blood-group")}
                              </span>
                            </Typography>
                            {!medicalRecord || id !== medicalRecord.id ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                HARDCODED BLOOD
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container direction="row">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={clsx(
                          classes.spaceBetween,
                          classes.paddingTop3
                        )}
                      >
                        <Grid item xs={4}>
                          <Grid container direction="column">
                            <Typography
                              component={"span"}
                              className={classes.title}
                            >
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 400,
                                  fontSize: 16,
                                }}
                              >
                                {t(
                                  "medical-record-patient-last-appointment-date"
                                )}
                              </span>
                            </Typography>
                            {!medicalRecord || id !== medicalRecord.id ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                {last_encounter}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container direction="column">
                            <Typography
                              component={"span"}
                              className={classes.title}
                            >
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 400,
                                  fontSize: 16,
                                }}
                              >
                                {t("medical-record-medical-center")}
                              </span>
                            </Typography>
                            {!medicalRecord || id !== medicalRecord.id ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                {currentRecord.organization}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container direction="column">
                            <Typography
                              component={"span"}
                              className={classes.title}
                            >
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 400,
                                  fontSize: 16,
                                }}
                              >
                                {t("medical-record-specialty")}
                              </span>
                            </Typography>
                            {!medicalRecord || id !== medicalRecord.id ? (
                              <>
                                <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                />
                              </>
                            ) : (
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                HARCODED SPECIALITY
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Tabs
                  value={tab}
                  onChange={handleChange}
                  classes={{
                    indicator: classes.indicator,
                    flexContainer: classes.displayFlex,
                  }}
                  style={{ marginTop: 10 }}
                  scrollButtons="auto"
                  variant="scrollable"
                >
                  <Tab
                    label={t("medical-record-summary")}
                    className={tab === 0 ? classes.tab : null}
                    icon={<ClipboardText />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={t("medical-record-section1")}
                    className={tab === 1 ? classes.tab : null}
                    icon={<AccountOutline />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={t("medical-record-section2")}
                    className={tab === 2 ? classes.tab : null}
                    icon={<WaterCheckOutline />}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={t("medical-record-section3")}
                    className={tab === 3 ? classes.tab : null}
                    icon={<AccountDetailsOutline />}
                    {...a11yProps(3)}
                  />
                  <Tab
                    disabled
                    label={t("medical-record-section4")}
                    className={tab === 4 ? classes.tab : null}
                    icon={<AccountTieOutline />}
                    {...a11yProps(4)}
                  />
                </Tabs>
                <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
                  <TabPanel value={tab} index={0}>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      elevation={0}
                      defaultExpanded={true}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Grid container direction="row">
                          <Grid item xs={6} style={{ margin: "auto" }}>
                            <Typography className={classes.heading}>
                              {t("medical-record-latest-laboratory-exams")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Pencil fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={clsx(
                              classes.spaceBetween,
                              classes.paddingTop3
                            )}
                          >
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  {/* A traductor */}
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    Glicemia
                                  </span>
                                </Typography>
                                {!medicalRecord || id !== medicalRecord.id ? (
                                  <>
                                    <Skeleton
                                      animation="wave"
                                      height={30}
                                      width="50%"
                                      style={{ marginBottom: 3 }}
                                    />
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    {currentRecord.glycemia}
                                  </span>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  {/* A traductor */}
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    Colesterol Total
                                  </span>
                                </Typography>
                                {!medicalRecord || id !== medicalRecord.id ? (
                                  <>
                                    <Skeleton
                                      animation="wave"
                                      height={30}
                                      width="50%"
                                      style={{ marginBottom: 3 }}
                                    />
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    {currentRecord.cholesterol}
                                  </span>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={4}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    {/* A traductor */}
                                    Creatininemia
                                  </span>
                                </Typography>
                                {!medicalRecord || id !== medicalRecord.id ? (
                                  <>
                                    <Skeleton
                                      animation="wave"
                                      height={30}
                                      width="50%"
                                      style={{ marginBottom: 3 }}
                                    />
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    {currentRecord.creatinine}
                                  </span>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={clsx(
                              classes.spaceBetween,
                              classes.paddingTop3
                            )}
                          >
                            <Grid item xs={6}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  {/* A traductor */}
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    Hemoglobina Glicosilada
                                  </span>
                                </Typography>
                                {!medicalRecord || id !== medicalRecord.id ? (
                                  <>
                                    <Skeleton
                                      animation="wave"
                                      height={30}
                                      width="50%"
                                      style={{ marginBottom: 3 }}
                                    />
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    {currentRecord.hemoglobin}
                                  </span>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  {/* A traductor */}
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    Microalbuminuria
                                  </span>
                                </Typography>
                                {!medicalRecord || id !== medicalRecord.id ? (
                                  <>
                                    <Skeleton
                                      animation="wave"
                                      height={30}
                                      width="50%"
                                      style={{ marginBottom: 3 }}
                                    />
                                  </>
                                ) : (
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    {currentRecord.microalbuminuria}
                                  </span>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-current-medication")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={clsx(
                              classes.spaceBetween,
                              classes.paddingTop3
                            )}
                          >
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    {t(
                                      "medical-record-treatment-and-diagnosis"
                                    )}
                                  </span>
                                </Typography>
                                <span
                                  style={{
                                    color: "#032D60",
                                    fontWeight: 700,
                                    fontSize: 16,
                                  }}
                                >
                                  Hipertiroidismo
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={clsx(
                              classes.spaceBetween,
                              classes.paddingTop3
                            )}
                          >
                            <Grid item xs={6}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    {t("medical-record-drug")}
                                  </span>
                                </Typography>
                                <span
                                  style={{
                                    color: "#032D60",
                                    fontWeight: 700,
                                    fontSize: 16,
                                  }}
                                >
                                  xxxxxx xxx-xx
                                </span>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    {t("medical-record-dosage")}
                                  </span>
                                </Typography>
                                <span
                                  style={{
                                    color: "#032D60",
                                    fontWeight: 700,
                                    fontSize: 16,
                                  }}
                                >
                                  xxxxxx xxx-xx
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Divider
                              style={{
                                borderColor: "black",
                                borderBottom: "transparent",
                                borderWidth: "thin",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid
                              container
                              direction="column"
                              className={clsx(classes.spaceBetween)}
                            >
                              <Typography
                                component={"span"}
                                className={classes.title}
                              >
                                <span
                                  style={{
                                    color: "#032D60",
                                    fontWeight: 400,
                                    fontSize: 16,
                                  }}
                                >
                                  {t("medical-record-treatment-and-diagnosis")}
                                </span>
                              </Typography>
                              <span
                                style={{
                                  color: "#032D60",
                                  fontWeight: 700,
                                  fontSize: 16,
                                }}
                              >
                                Depresión
                              </span>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={clsx(
                              classes.spaceBetween,
                              classes.paddingTop3
                            )}
                          >
                            <Grid item xs={6}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    {t("medical-record-drug")}
                                  </span>
                                </Typography>
                                <span
                                  style={{
                                    color: "#032D60",
                                    fontWeight: 700,
                                    fontSize: 16,
                                  }}
                                >
                                  xxxxxx xxx-xx
                                </span>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid
                                container
                                direction="column"
                                className={clsx(classes.spaceBetween)}
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.title}
                                >
                                  <span
                                    style={{
                                      color: "#032D60",
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }}
                                  >
                                    {t("medical-record-dosage")}
                                  </span>
                                </Typography>
                                <span
                                  style={{
                                    color: "#032D60",
                                    fontWeight: 700,
                                    fontSize: 16,
                                  }}
                                >
                                  xxxxxx xxx-xx
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-health-and-lifestyle-habits")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>

                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-social-support")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-personal-data")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-contact-data")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                  </TabPanel>

                  <TabPanel value={tab} index={2}>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-feeding-habits")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-health-and-lifestyle")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                  </TabPanel>

                  <TabPanel value={tab} index={3}>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-physical-and-mental-exams")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                    <Accordion
                      style={{
                        border: "none",
                        marginTop: 10,
                        backgroundColor: "none",
                      }}
                      classes={{
                        root: classes.rootAccordion,
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        style={{
                          background: "#F1F8FF",
                          border: "1px solid rgba(0, 0, 0, .125)",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography className={classes.heading}>
                          {t("medical-record-family-history")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails></AccordionDetails>
                    </Accordion>
                  </TabPanel>
                </SwipeableViews>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default connect(getData)(MedicalRecord);
