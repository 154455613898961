import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/locale/es";
import "react-h5-audio-player/lib/styles.css";

import DataTable from "react-data-table-component";
import { Grid, Typography } from "@material-ui/core/";

import Layout from "../../components/layouts/Base";
import PatientDialog from "../../components/forms/PatientDialog";
import NewAppointmentDialog from "../../components/forms/NewAppointmentForm";
import { loadPatients } from "../../services/utils/load-data-services";
import PatientSubHeader from "../../components/PatientSubHeader";

import { customStyles, BorderLinearProgressCustom } from "./styles";
import Columns from "./sections/Columns";
import usePatients from "./hooks";

const Patients = ({
  user,
  users,
  patients,
  organizations,
  practitioners,
  statisticsPlotData,
  dispatch,
  state,
}) => {
  const [
    filterText,
    setFilterText,
    styles,
    classes,
    dialogRef,
    appointmentRef,
    currentUser,
    events,
  ] = usePatients(
    user,
    users,
    patients,
    practitioners,
    organizations,
    statisticsPlotData,
    dispatch
  );
  const {
    filteredItems,
    goToPatientDetails,
    handleClear,
    addNewAppointment,
    handleClickOpen,
  } = events;

  const { t, i18n } = useTranslation();
  const BorderLinearProgress = BorderLinearProgressCustom();
  const columns = Columns(t, classes, goToPatientDetails, handleClickOpen);

  return (
    <Layout>
      <Grid className={classes.verticalAlignBlock}>
        <div className={classes.spaceBetween}>
          <div>
            <Typography>
              {t("user-area")}:{" "}
              {currentUser
                ? t(currentUser.practitioner)
                : t("user-not-defined")}
            </Typography>
            <Typography variant="h5" gutterBottom>
              <b>
                {currentUser
                  ? currentUser.name || currentUser.username
                  : t("user-no-name")}
              </b>
            </Typography>
          </div>
          <div>
            <Typography>
              {t("user-medical-center")}:{" "}
              {currentUser ? currentUser.organization : t("user-not-defined")}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {moment().locale("es").format("llll")}
            </Typography>
          </div>
        </div>
      </Grid>

      <DataTable
        noHeader
        subHeader
        striped
        persistTableHead
        responsive
        pagination
        paginationComponentOptions={{
          rowsPerPageText: t("elements-per-page"),
          rangeSeparatorText: t("range-separator-text"),
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: t("select-all-rows"),
        }}
        noDataComponent={t("no-patients-registered")}
        subHeaderComponent={
          <PatientSubHeader
            handleClear={handleClear}
            organizations={organizations}
            patients={patients}
            user={user}
            filterText={filterText}
            setFilterText={setFilterText}
            styles={styles}
            t={t}
            appointmentRef={appointmentRef}
            practitioners={practitioners}
            dialogRef={dialogRef}
            handleClickOpen={handleClickOpen}
          />
        }
        subHeaderAlign="left"
        columns={columns}
        customStyles={customStyles}
        data={filteredItems}
      />
      {!patients ? (
        <BorderLinearProgress
          user={user}
          organizations={organizations}
          patients={patients}
        />
      ) : null}
      <PatientDialog
        ref={dialogRef}
        reload={() => loadPatients(user, dispatch)}
      />
      <NewAppointmentDialog
        ref={appointmentRef}
        addAppointment={(a, a_id) => addNewAppointment(a, a_id)}
      />
    </Layout>
  );
};

const mapStatesToProps = (state) => ({
  state,
  user: state.user,
  users: state.users,
  patients: state.patients,
  organizations: state.organizations,
  practitioners: state.practitioners,
  statisticsPlotData: state.statisticsPlotData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch(login(user)),
    dispatch,
  };
};
export default connect(mapStatesToProps, mapDispatchToProps)(Patients);
