import React from "react";
import "react-h5-audio-player/lib/styles.css";
import { connect } from "react-redux";
import AppointmentStep from "../../components/appointment/AppointmentStep";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Treatment = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div>
        <AppointmentStep
          profession={props.appointment.practitioner}
          practitionerName={props.appointment.practitionerName}
          step="treatment"
          stepName={t("treatment-and-suggestions")}
          subStepName={t("treatment")}
          stepNumber={t("step-3")}
          maxStepsAllForm={8}
          patientName={props.appointment.patientName}
          motive={props.appointment.appointmentName}
          activeStep={6}
          currentStep={6}
        />
      </div>
    </>
  );
};

Treatment.propTypes = {
  appointment: PropTypes.object,
};

export default connect()(Treatment);
