import { makeStyles } from "@material-ui/core/styles";

const useStyles = (theme) =>
  makeStyles((theme) => ({
    root: {},
    seeMore: {
      marginTop: theme.spacing(3),
    },
    displayFlex: {
      display: "flex",
    },
    marginTop: {
      marginTop: 15,
    },
    displayHidden: {
      display: "none",
    },
    centerVertically: {
      display: "flex",
      "align-items": "center",
      margin: "auto",
      "justify-content": "center",
    },
    marginCircular: {
      margin: 10,
    },
    paper: {
      padding: "6px 16px",
    },
    marginBottom: {
      marginBottom: 5,
    },
    verticalAlign: {
      display: "flex",
      margin: "auto",
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    noPadding: {
      padding: 0,
    },
    noRightPadding: {
      "padding-right": 0,
    },
    borderRadiusAvatar: {
      borderRadius: "8px 0px 8px 0px",
    },
    extraLargeAvatar: {
      width: "12vw",
      height: "25vh",
    },
    cardClass: {},
    spanButton: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    button: {
      [theme.breakpoints.down("md")]: {
        minWidth: 32,
        paddingLeft: 8,
        paddingRight: 8,
        "& .MuiButton-startIcon": {
          margin: 0,
        },
      },
    },
    indicator: {
      backgroundColor: "#FA6A09",
    },
    tab: {
      color: "#FA6A09",
    },
    rightAlign: {
      display: "flex",
      "justify-content": "flex-end",
    },
    spaceBetween: {
      display: "flex",
      justifyContent: "space-between",
    },
    paddingTop3: {
      paddingTop: theme.spacing(2),
    },

    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    rootAccordion: {
      "&::before": {
        backgroundColor: "transparent",
      },
    },
  }));

export default useStyles;
