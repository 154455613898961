import React, { useState, useRef, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import Layout from "../../components/layouts/Base";
import {
  Typography,
  Grid,
  Toolbar,
  AppBar,
  Button,
  IconButton,
  Container,
  Card,
  CardContent,
  TextField,
} from "@material-ui/core";
import PatientHistory from "./PatientHistory";
import AppointmentTopic from "./AppointmentTopic";
import { connect } from "react-redux";
import { loadAppointmentFromJSON } from "../../store/actions";
import CurrentHealthRecord from "./CurrentHealthRecord";
import PhysicalExploration from "./PhysicalExploration";
import Procedures from "./Procedures";
import Diagnosis from "./Diagnosis";
import Treatment from "./Treatment";
import AppointmentSummary from "./AppointmentSummary";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import AppointmentStep from "../../components/appointment/AppointmentStep";
import { useStyles } from "./style";
import { Qrcode } from "mdi-material-ui";
import TitleSteepAppointment from "../../components/appointment/TitleSteepAppointment";
import AppointmentFormStepper from "../../components/appointment/AppointmentFormStepper";
import steepStatus from "../../constants/steepStatus";
import SteepsForm from "../../components/appointment/SteepsForm";
import Stepper from "react-stepper-horizontal";
import CaptureAudioForm from "../../components/appointment/CaptureAudioForm";
import AppointmentFormStepName from "../../components/appointment/AppointmentFormStepName";
const mapStateToProps = (state) => ({
  state,
  appointments: state.patientAppointments,
  step: state.appointmentStep,
  appointmentObj: state.appointmentStep,
  patients: state.patients,
  summary: state.appointmentStep,
});

const AppointmentForm = ({
  step,
  appointments,
  appointmentObj,
  dispatch,
  user,
  patients,
  summary,
  state,
}) => {
  let { idPatient, idForm } = useParams();
  let history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [loadingComprehend, setLoadingComprehend] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [listDataState, setListDataState] = useState([
    {
      titleSteep: t("patient-background"),
      spanSteep: t("background"),
      Description: "",
      steepState: steepStatus.isPending,
    },
    {
      titleSteep: t("appointment-topic"),
      spanSteep: t("ananmesis"),
      Description: "",
      steepState: steepStatus.isPending,
    },
    {
      titleSteep: t("supportive-exams"),
      spanSteep: t("medical-record-examens"),
      Description: "",
      steepState: steepStatus.isPending,
    },
    {
      titleSteep: t("diagnosis"),
      spanSteep: t("diagnosis"),
      Description: "",
      steepState: steepStatus.isPending,
    },
    {
      titleSteep: t("treatment-plan"),
      spanSteep: t("medical-record-examens"),
      Description: "",
      steepState: steepStatus.isPending,
    },
    {
      titleSteep: t("review-and-suggestions"),
      spanSteep: t("summary-step-title"),
      Description: "",
      steepState: steepStatus.isPending,
    },
  ]);

  const [currentSteepState, setCurrentSteepState] = useState(0);
  listDataState[currentSteepState].titleSteep;
  console.log(listDataState[currentSteepState].titleSteep);
  useEffect(() => {
    const newState = [...listDataState];
    newState[currentSteepState].steepState = steepStatus.isActive;
    setListDataState(newState);
  }, []);

  if (!patients) {
    history.push("/patients");
  }

  const currentAppointment = appointments
    ? appointments.find((a) => a.id === idForm)
    : {};
  const transcriptionDescription = listDataState[currentSteepState].Description;

  const handleNextSteep = () => {
    const nextSteep = currentSteepState + 1;
    const newState = [...listDataState];
    newState[currentSteepState].steepState = true
      ? steepStatus.isComplete
      : steepStatus.isEmpty;
    newState[currentSteepState].Description = transcriptionDescription;
    newState[nextSteep].steepState = steepStatus.isActive;
    setListDataState(newState);
    setCurrentSteepState(nextSteep);
  };
  const handleBackSteep = () => {
    const backSteep = currentSteepState - 1;
    const newState = [...listDataState];
    newState[currentSteepState].steepState = true
      ? steepStatus.isPending
      : steepStatus.isEmpty;
    newState[currentSteepState].Description = "";
    newState[backSteep].steepState = steepStatus.isActive;
    setListDataState(newState);
    setCurrentSteepState(backSteep);
  };

  const handleChanceDescription = (text) => {
    const newState = [...listDataState];
    newState[currentSteepState].Description = text;
    setListDataState(newState);
  };
  const handleTextChange = (event) => {
    setChanged(true);
    setTranscription(event.target.value);
  };
  const savePatientHistory = () => {
    console.log(currentStep);
    if (transcription && (changed || summary.loaded)) {
      console.log("here");
      sendComprehendMedical()
        .then((response) => {
          setComprehend(response);
          const data = {
            step: currentStep,
            appointment: {
              loaded: false,
              transcription: transcription,
              transcriptionId: transcriptionId,
              comprehend: response.data,
              stepName: subStepName,
            },
          };
          dispatch(savePatientData(data));
          setTimeout(() => {
            dispatch(nextStep());
          }, 0);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        savePatientData({
          step: step,
          appointment: {
            transcription: transcription,
            transcriptionId: transcriptionId,
            comprehend: comprehend.data,
            stepName: subStepName,
          },
        })
      );
      dispatch(nextStep());
    }
  };

  console.log(listDataState);
  //const currentPatient = patient ? patient.find((b) => b.id === idPatient) : {};
  console.log("span", listDataState[currentSteepState].Description);
  return (
    <Layout clientHeader>
      <TitleSteepAppointment
        title={listDataState[currentSteepState].titleSteep}
        practitioner={currentAppointment.practitioner}
      />
      <div className={classes.steeperContainer}>
        {listDataState.map((el) => (
          <SteepsForm steepState={el.steepState} spanSteep={el.spanSteep} />
        ))}
      </div>

      <AppointmentFormStepName
        updateStreamingText={(text) => handleChanceDescription(text)}
      />
      <Container maxWidth="lg">
        <Card className={classes.noBorder} variant="outlined">
          <CardContent className={classes.noPadding}>
            <Typography variant="subtitle1" style={{ marginTop: "50px" }}>
              {t("transcription")}
            </Typography>
            <TextField
              id="outlined-multiline-static"
              value={transcriptionDescription || ""}
              onChange={(e) => handleTextChange(e)}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
            />
          </CardContent>
        </Card>
      </Container>
      {/* <strong>{step}</strong> */}
      {/* <AppointmentStep
        currentAppointment={currentAppointment}
        step={"patientHistory"}
        stepName={t("background")}
        subStepName={t("patient-background")}
        stepNumber={t("step-1")}
        maxStepsAllForm={9}
        currentStep={step}
        summary={summary}
      /> */}
      <Container maxWidth="lg" className={classes.marginTop}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.marginBottom}
        >
          <Grid item xs={6} md={6} className={classes.flexStart}>
            <Button
              className={useStyles().buttonOtherColor}
              disabled={loadingComprehend}
              style={{ borderColor: "#346DFF" }}
              disableElevation
              variant="outlined"
              onClick={() => cleanAll()}
            >
              <span className={(useStyles().spanButton, classes.colorBlue)}>
                {t("clean-all")}
              </span>
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            className={classes.displayFlex}
            justify="flex-end"
          >
            {!loadingComprehend ? (
              <>
                <Button
                  onClick={() => {
                    console.log("qreader");
                  }}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "15px" }}
                >
                  <IconButton style={{ padding: 0 }} component="span">
                    <Qrcode className={classes.colorWhite} />
                  </IconButton>
                </Button>
                <Button
                  className={useStyles().buttonOtherColor}
                  disabled={step === "patientHistory"}
                  style={{ borderColor: "#346DFF", marginRight: "15px" }}
                  disableElevation
                  variant="outlined"
                  onClick={() => {
                    handleBackSteep();
                  }}
                >
                  <span className={(useStyles().spanButton, classes.colorBlue)}>
                    {t("Atrás")}
                  </span>
                </Button>
                <Button
                  className={classes.marginTopTextField}
                  disabled={uploading}
                  onClick={() => {
                    handleNextSteep();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {t("next")}
                </Button>
              </>
            ) : (
              <CircularProgress className={classes.marginRight} />
            )}
          </Grid>
        </Grid>
      </Container>
      {parseInt(step) == 7 && (
        <AppointmentSummary
          appointment={currentAppointment}
          patient={currentPatient}
        />
      )}
    </Layout>
  );
};

AppointmentForm.propTypes = {
  step: PropTypes.number,
  stepName: PropTypes.string,
};

export default connect(mapStateToProps)(AppointmentForm);
