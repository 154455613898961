import React, { forwardRef, useImperativeHandle } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {
  Button,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  AppBar,
  Divider,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import { CloseCircleOutline } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  createUserFromApi,
  editUserFromApi,
} from "../../services/fhir-api-services";
import { updatePatientAppointments } from "../../store/actions";
import { loadPatients } from "../../services/utils/load-data-services";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useStyles from "./style";

const UserForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const genders = [
    { value: "male", text: t("male") },
    { value: "female", text: t("female") },
    { value: "unknown", text: t("unknown") },
    { value: "other", text: t("other") },
  ];

  const specialties = [
    { name: t("general-medicine"), value: "Medicina General" },
    { name: t("internal-medicine"), value: "Medicina Interna" },
    { name: t("psychology"), value: "Psicología" },
    { name: t("nursing"), value: "Enfermería" },
    { name: t("nutrition"), value: "Nutrición" },
    { name: t("kinesiology"), value: "Kinesiólogía" },
    { name: t("occupational-therapy"), value: "Terapia Ocupacional" },
    { name: t("social-assistant"), value: "Asistente Social" },
    { name: t("support"), value: "Soporte" },
  ];

  const userRole = [
    { value: "practitioner", text: t("practitioner") },
    { value: "hospitalAdmin", text: t("hospitalAdmin") },
    { value: "healthService", text: t("healthService") },
  ];

  const [open, setOpen] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [isEdit, setEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [nUser, setUserValue] = React.useState({
    given: "",
    family: "",
    email: "",
    gender: "",
    username: "",
    password: "",
    group: "practitioner",
    role: "practitioner",
    organization: "",
    practitioner: "",
  });

  const [errors, setError] = React.useState({
    given: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    family: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    email: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation: (obj, val) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(val);
      },
      extraValidationMessage: "incorrectEmail",
    },
    gender: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    role: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    username: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    password: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation: (obj, val) => {
        const re =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return !re.test(val);
      },
      extraValidationMessage: "incorrectPassword",
      notEdit: true,
    },
    organization: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    practitioner: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
  });

  const handleUserValueChange = (prop) => (event, value) => {
    let val = "";
    if (
      event &&
      event.target &&
      (event.target.value || event.target.value === "")
    ) {
      val = event.target.value;
      setUserValue({ ...nUser, [prop]: event.target.value });
    } else if (event instanceof Date) {
      val = event;
      setUserValue({ ...nUser, [prop]: event });
    } else if (value) {
      val = value.name;
      setUserValue({ ...nUser, [prop]: value.name });
    } else {
      val = event;
      setUserValue({ ...nUser, [prop]: event });
    }
    if (prop in errors) {
      if (
        val !== "" &&
        errors[prop].extraValidation &&
        errors[prop].extraValidation(nUser, val)
      ) {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: true,
            errorMessage: t(errors[prop].extraValidationMessage),
          }),
        });
      } else {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: false,
            errorMessage: "",
          }),
        });
      }
    }
  };

  const validateForm = () => {
    let validate = true;

    Object.entries(errors).forEach((array, index) => {
      const key = array[0];
      const value = array[1];

      if (
        value.mandatory &&
        ((!value.notEdit && isEdit) || !isEdit) &&
        (!nUser[key] || nUser[key] === "")
      ) {
        validate = false;
        setError({
          ...errors,
          [key]: Object.assign(value, {
            error: true,
            errorMessage: value.mandatoryMessage,
          }),
        });
      }
    });

    return validate;
  };

  const saveUser = () => {
    if (validateForm()) {
      setLoading(true);
      if (isEdit) {
        editUserFromApi(
          nUser,
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            props.reload();
            setLoading(false);
            setOpen(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setOpen(false);
          });
      } else {
        createUserFromApi(
          nUser,
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            props.reload();
            setLoading(false);
            setOpen(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setOpen(false);
          });
      }
    }
  };

  const resetUserObj = () => {
    setUserValue({
      given: "",
      family: "",
      email: "",
      gender: "",
      username: "",
      password: "",
      group: "practitioner",
      role: "practitioner",
      organization: "",
      practitioner: "",
    });
  };

  useImperativeHandle(ref, () => ({
    openDialog(userObj, user, isEdit, organizations = [], practitioners) {
      setEdit(isEdit);
      setOpen(true);
      setUser(user);
      setOrganizations(organizations ? organizations : []);
      resetUserObj();
      if (userObj) {
        let practitionerObj = practitioners.find(
          (p) => p.practitioner_id === userObj.practitioner_id
        );
        setUserValue({
          given: practitionerObj ? practitionerObj.given : "",
          family: practitionerObj ? practitionerObj.family : "",
          email: userObj.email,
          username: userObj.username,
          gender: practitionerObj ? practitionerObj.gender : "",
          role: userObj.role,
          group: "practitioner",
          organization: userObj.organization,
          practitioner: userObj.practitioner,
        });
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppBar
            position="static"
            className={classes.appBarStyle}
            elevation={0}
          >
            <Toolbar variant="dense">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={useStyles().displayContent}
              >
                <Grid container item={true} xs={"auto"}>
                  <Typography
                    component="h2"
                    variant="h6"
                    className={useStyles().titleStyle}
                  >
                    {isEdit ? t("editUser") : t("newUser")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="flex-end"
                  className={useStyles().rightAlign}
                  item={true}
                  xs={"auto"}
                >
                  <IconButton
                    color="primary"
                    aria-label="close button"
                    onClick={handleClose}
                  >
                    <CloseCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              {isEdit ? t("editUserDescription") : t("newUserDescription")}
            </DialogContentText>
            <Divider className={classes.marginDivider} />
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  value={nUser.given}
                  onChange={handleUserValueChange("given")}
                  id="firstname"
                  label={t("form-names")}
                  error={errors.given.error}
                  helperText={errors.given.errorMessage}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  value={nUser.family}
                  onChange={handleUserValueChange("family")}
                  error={errors.family.error}
                  helperText={errors.family.errorMessage}
                  id="lastname"
                  label={t("form-surnames")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.gender.error}
                >
                  <InputLabel htmlFor="outlined-gender">
                    {t("gender")}
                  </InputLabel>
                  <Select
                    value={nUser.gender}
                    onChange={handleUserValueChange("gender")}
                    label={t("gender")}
                    inputProps={{
                      name: "Sexo",
                      id: "outlined-gender",
                    }}
                  >
                    {genders.map((gender) => (
                      <MenuItem key={gender.value} value={gender.value}>
                        {gender.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.gender.error && (
                    <FormHelperText>
                      {errors.gender.errorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  disabled={isEdit}
                  error={errors.username.error}
                  helperText={t(errors.username.errorMessage)}
                  value={nUser.username}
                  onChange={handleUserValueChange("username")}
                  id="username"
                  label={t("username")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  value={nUser.email}
                  disabled={isEdit}
                  error={errors.email.error}
                  helperText={t(errors.email.errorMessage)}
                  onChange={handleUserValueChange("email")}
                  id="email"
                  label={t("email")}
                  fullWidth
                />
              </Grid>
            </Grid>

            {!isEdit && (
              <Grid container spacing={3} className={classes.marginPerGrid}>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    type={values.showPassword ? "text" : "password"}
                    error={errors.password.error}
                    helperText={t(errors.password.errorMessage)}
                    value={nUser.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleUserValueChange("password")}
                    id="password"
                    label={t("password")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.role.error}
                >
                  <InputLabel htmlFor="outlined-role">{t("role")}</InputLabel>
                  <Select
                    value={nUser.role}
                    onChange={handleUserValueChange("role")}
                    label={t("role")}
                    inputProps={{
                      name: "Rol",
                      id: "outlined-role",
                    }}
                  >
                    {userRole.map((role) => (
                      <MenuItem key={role.value} value={role.value}>
                        {role.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.role.error && (
                    <FormHelperText>{errors.role.errorMessage}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={organizations}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    disableClearable
                    value={
                      nUser.organization ? { name: nUser.organization } : null
                    }
                    onChange={handleUserValueChange("organization")}
                    noOptionsText={t("noOrganizations")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.organization.error}
                        helperText={t(errors.organization.errorMessage)}
                        value={nUser.organization}
                        label={t("organization")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={specialties}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    disableClearable
                    value={
                      nUser.practitioner ? { name: nUser.practitioner } : null
                    }
                    onChange={handleUserValueChange("practitioner")}
                    noOptionsText={t("noSpecialty")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.practitioner.error}
                        helperText={t(errors.practitioner.errorMessage)}
                        value={nUser.practitioner}
                        label={t("specialty")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <CircularProgress className={classes.marginCircular} />
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => saveUser()}
                >
                  <span className={classes.whiteText}>{t("save")}</span>
                </Button>
              </div>
            )}
          </DialogActions>
        </MuiPickersUtilsProvider>
      </Dialog>
    </>
  );
});

export default UserForm;
