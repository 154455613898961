import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "react-h5-audio-player/lib/styles.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  AccountPlus,
  AccountSearch,
  MicrophonePlus,
  PencilOutline,
} from "mdi-material-ui";
import withStyles from "@material-ui/core/styles/withStyles";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import UserForm from "../../components/forms/UserForm";
import {
  loadOrganizations,
  loadPatients,
  loadPractitioners,
  loadUsers,
} from "../../services/utils/load-data-services";
import { editUserFromApi } from "../../services/fhir-api-services";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import * as moment from "moment";
//import Skeleton from "@material-ui/core/Skeleton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
//import Autocomplete from "@material-ui/core/Autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#F1F8FF",
      borderBottom: "2px solid",
      borderColor: "#FA6A09",
    },
  },
};

const getData = (state) => ({
  user: state.user,
  users: state.users,
  organizations: state.organizations,
  practitioners: state.practitioners,
});

const Profile = ({ user, users, dispatch, organizations, practitioners }) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [nUser, setUserValue] = React.useState({
    name: "",
    email: "",
    gender: "",
    username: "",
    organization: "",
    practitioner: "",
    previous_password: "",
    new_password: "",
    new_repeat_password: "",
  });

  const specialties = [
    { name: t("general-medicine"), value: "Medicina General" },
    { name: t("internal-medicine"), value: "Medicina Interna" },
    { name: t("psychology"), value: "Psicología" },
    { name: t("nursing"), value: "Enfermería" },
    { name: t("nutrition"), value: "Nutrición" },
    { name: t("kinesiology"), value: "Kinesiólogía" },
    { name: t("occupational-therapy"), value: "Terapia Ocupacional" },
    { name: t("social-assistant"), value: "Asistente Social" },
  ];

  useEffect(() => {
    if (!users) {
      loadUsers(user, dispatch);
    }

    if (!organizations) {
      loadOrganizations(user, dispatch);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }

    if (user.isAuth) {
      const cUser = users
        ? users.find((u) => u.username === user.username)
        : {};
      setUserValue({
        name: cUser.name,
        email: cUser.email,
        gender: cUser.gender,
        username: cUser.username,
        organization: cUser.organization,
        practitioner: cUser.practitioner,
        previous_password: cUser.previous_password,
        new_password: cUser.new_password,
        new_repeat_password: cUser.new_repeat_password,
      });
    }
  }, [user]);

  const [valuesPrevious, setValuesPrevious] = React.useState({
    showPreviousPassword: false,
  });
  const [valuesNew, setValuesNew] = React.useState({
    showNewPassword: false,
  });
  const [valuesNewRepeat, setValuesNewRepeat] = React.useState({
    showNewRepeatPassword: false,
  });

  const handleClickShowPasswordPrevious = () => {
    setValuesPrevious({
      ...valuesPrevious,
      showPreviousPassword: !valuesPrevious.showPreviousPassword,
    });
  };

  const handleClickShowPasswordNew = () => {
    setValuesNew({ ...valuesNew, showNewPassword: !valuesNew.showNewPassword });
  };

  const handleClickShowPasswordNewRepeat = () => {
    setValuesNewRepeat({
      ...valuesNewRepeat,
      showNewRepeatPassword: !valuesNewRepeat.showNewRepeatPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [errors, setError] = React.useState({
    name: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    email: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation: (obj, val) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(val);
      },
      extraValidationMessage: "incorrectEmail",
    },
    // gender: {
    //     error: false,
    //     errorMessage: '',
    //     mandatoryMessage: 'mandatoryField',
    //     mandatory: true
    // },
    role: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    username: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    organization: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    practitioner: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    previous_password: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation: (obj, val) => {
        const re =
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        return !re.test(val);
      },
      extraValidationMessage: "incorrectPassword",
      notEdit: true,
    },
    new_password: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation: (obj, val) => {
        const re =
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        return !re.test(val);
      },
      extraValidation2: (obj, val1, val2) => {
        return val1 !== val2;
      },
      extraValidationMessage: "incorrectPassword",
      extraValidationMessage2: "incorrectRepeatPassword",
      notEdit: true,
    },
    new_repeat_password: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation2: (obj, val1, val2) => {
        return val1 !== val2;
      },
      extraValidationMessage2: "incorrectRepeatPassword",
      notEdit: true,
    },
  });

  const handleUserValueChange = (prop) => (event, value) => {
    let val = "";
    if (
      event &&
      event.target &&
      (event.target.value || event.target.value === "")
    ) {
      val = event.target.value;
      setUserValue({ ...nUser, [prop]: event.target.value });
    } else if (event instanceof Date) {
      val = event;
      setUserValue({ ...nUser, [prop]: event });
    } else if (value) {
      val = value.name;
      setUserValue({ ...nUser, [prop]: value.name });
    } else {
      val = event;
      setUserValue({ ...nUser, [prop]: event });
    }

    if (prop in errors) {
      // Primera validación (formato)
      if (
        val !== "" &&
        errors[prop].extraValidation &&
        errors[prop].extraValidation(nUser, val)
      ) {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: true,
            errorMessage: t(errors[prop].extraValidationMessage),
          }),
        });
      } else {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: false,
            errorMessage: "",
          }),
        });
      }

      // El repetir es igual al primero
      if (
        prop == "new_password" &&
        val !== "" &&
        errors[prop].extraValidation2(
          nUser,
          nUser.new_repeat_password,
          event.target.value
        )
      ) {
        setError({
          ...errors,
          [new_repeat_password]: Object.assign(errors["new_repeat_password"], {
            error: true,
            errorMessage: t(
              errors["new_repeat_password"].extraValidationMessage2
            ),
          }),
        });
        // El primero es igual al repetir
      } else if (
        prop == "new_repeat_password" &&
        val !== "" &&
        errors[prop].extraValidation2(
          nUser,
          nUser.new_password,
          event.target.value
        )
      ) {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: true,
            errorMessage: t(errors[prop].extraValidationMessage2),
          }),
        });
        // Primera validación (formato)
      } else if (
        val !== "" &&
        errors[prop].extraValidation &&
        errors[prop].extraValidation(nUser, val)
      ) {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: true,
            errorMessage: t(errors[prop].extraValidationMessage),
          }),
        });
      } else {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: false,
            errorMessage: "",
          }),
        });
      }
    }
  };

  const validateForm = () => {
    let validate = true;

    Object.entries(errors).forEach((array, index) => {
      const key = array[0];
      const value = array[1];

      if (value.mandatory && (!nUser[key] || nUser[key] === "")) {
        validate = false;
        setError({
          ...errors,
          [key]: Object.assign(value, {
            error: true,
            errorMessage: value.mandatoryMessage,
          }),
        });
      }
    });

    return validate;
  };

  const resetUserObj = () => {
    setUserValue({
      name: "",
      email: "",
      gender: "",
      username: "",
      organization: "",
      practitioner: "",
      previous_password: "",
      new_password: "",
      new_repeat_password: "",
    });
  };

  const [open, setOpen] = React.useState(false);
  const saveProfile = () => {
    setLoading(true);
    editUserFromApi(
      nUser,
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        setLoading(false);
        setOpen(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setOpen(false);
      });
    resetUserObj();
  };

  const currentUser =
    user && users ? users.find((u) => u.username === user.username) : null;
  return (
    <>
      <div>
        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography variant="h5" gutterBottom>
                <b>{t("my-profile")}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              value={nUser.name}
              onChange={handleUserValueChange("name")}
              id="name"
              label={t("form-names")}
              disabled
              error={errors.name.error}
              helperText={errors.name.errorMessage}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              disabled
              error={errors.username.error}
              helperText={t(errors.username.errorMessage)}
              value={nUser.username}
              onChange={handleUserValueChange("username")}
              id="username"
              label={t("username")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              value={nUser.email}
              disabled
              error={errors.email.error}
              helperText={t(errors.email.errorMessage)}
              onChange={handleUserValueChange("email")}
              id="email"
              label={t("email")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              value={nUser.organization}
              disabled
              error={errors.organization.error}
              helperText={t(errors.organization.errorMessage)}
              onChange={handleUserValueChange("organization")}
              id="organization"
              label={t("organization")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Autocomplete
                options={specialties}
                getOptionLabel={(option) => t(option.name)}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                disableClearable
                value={nUser.practitioner ? { name: nUser.practitioner } : null}
                onChange={handleUserValueChange("practitioner")}
                noOptionsText={t("noSpecialty")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.practitioner.error}
                    helperText={t(errors.practitioner.errorMessage)}
                    value={nUser.practitioner}
                    label={t("specialty")}
                    variant="outlined"
                  />
                )}
              ></Autocomplete>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              type={valuesPrevious.showPreviousPassword ? "text" : "password"}
              error={errors.previous_password.error}
              helperText={t(errors.previous_password.errorMessage)}
              value={nUser.previous_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordPrevious}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {valuesPrevious.showPreviousPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleUserValueChange("previous_password")}
              id="previous_password"
              label={t("previous_password")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              type={valuesNew.showNewPassword ? "text" : "password"}
              error={errors.new_password.error}
              helperText={t(errors.new_password.errorMessage)}
              value={nUser.new_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNew}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {valuesNew.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleUserValueChange("new_password")}
              id="new_password"
              label={t("new_password")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              type={valuesNewRepeat.showNewRepeatPassword ? "text" : "password"}
              error={errors.new_repeat_password.error}
              helperText={t(errors.new_repeat_password.errorMessage)}
              value={nUser.new_repeat_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNewRepeat}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {valuesNewRepeat.showNewRepeatPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleUserValueChange("new_repeat_password")}
              id="new_repeat_password"
              label={t("new_repeat_password")}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.marginPerGrid}>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress className={classes.marginCircular} />
            ) : (
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                onClick={() => saveProfile()}
              >
                <span className={classes.whiteText}>{t("save")}</span>
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default connect(getData)(Profile);
