import {
  getExamTypes,
  getExam,
  getPatientMedicalRecord,
  listAppointments,
  listOrganizations,
  listPatients,
  listPractitioners,
  listUsersFromApi,
  getStatisticsData,
} from "../fhir-api-services";
import {
  updateExamTypes,
  updateExpertSystem,
  updateOrganizations,
  updatePatientAppointments,
  updatePatientExams,
  updatePatientMedicalRecord,
  updatePatients,
  updatePractitioners,
  updateUsers,
  updateStatisticsData,
} from "../../store/actions";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import React from "react";
import { createExpertSystem } from "../expert-system-services";

export function loadOrganizations(user, dispatch) {
  let organizations = [];
  if (user.isAuth) {
    listOrganizations(
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        response.data.forEach((org) => {
          let organization = Object.assign(org, {
            addressN:
              org.address && org.address[0].line ? org.address[0].line[0] : "",
            district: org.address ? org.address[0].district : "",
            country: org.address ? org.address[0].country : "",
            code: org.identifier ? org.identifier[0] : "",
            phone: org.telecom ? org.telecom[0].value : "",
          });
          organizations.push(organization);
        });
        organizations = organizations.filter(
          (org, index, self) =>
            index === self.findIndex((t) => t.name === org.name)
        );
        dispatch(updateOrganizations(organizations));
      })
      .catch((e) => {
        console.log(e);
      });
  }
}

export function loadPatients(user, dispatch) {
  let patients = [];
  if (user.isAuth) {
    listPatients(
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        response.data.forEach((pat) => {
          let patient = Object.assign(pat, {
            name: pat.given + " " + pat.family,
            lastAppointment: new Date().toISOString().substr(0, 10),
            bd: pat.birthDate,
            nextAppointment: new Date().toISOString().substr(0, 10),
          });
          patient.birthDate = new Date(patient.birthDate);
          patients.push(patient);
        });
        dispatch(updatePatients(patients));
      })
      .catch((e) => {
        console.log(e);
      });
  }
}

export function loadUsers({ isAuth, signInUserSession }, dispatch) {
  let usersList = [];
  if (isAuth) {
    const { idToken, accessToken } = signInUserSession;
    listUsersFromApi(idToken.jwtToken, accessToken.jwtToken)
      .then((response) => {
        response.data.forEach(
          ({
            email,
            email_verified,
            group,
            name,
            organization,
            practitioner,
            practitioner_id,
            role,
            username,
          }) => {
            usersList.push({
              email,
              email_verified,
              group,
              name,
              organization,
              practitioner,
              practitioner_id,
              role,
              username,
            });
            console.log(usersList);
            // localStorage.setItem(
            //   "usersw",
            //   JSON.stringify(signInUserSession.idToken)
            // );
          }
        );
        dispatch(updateUsers(usersList));
      })
      .catch((e) => {
        console.log(e);
      });
  }
}

export function loadAppointments(user, dispatch, id) {
  if (user.isAuth) {
    listAppointments(
      id,
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        dispatch(
          updatePatientAppointments(
            response.data.map((appoint, index) => {
              let practitioner = appoint.practitioner;
              let patient = appoint.patient;
              let interactions = {};

              if (appoint.extension && appoint.extension.count) {
                interactions = appoint.extension.count;
              }

              return {
                id: appoint.id,
                icon: <EventIcon />,
                appointmentObj: appoint.appointmentObj,
                appointmentName: appoint.appointmentType.name
                  ? appoint.appointmentType.name
                  : "Control",
                appointmentType: "Control de diabetes",
                date: moment(appoint.start).format("DD/MM/YYYY"),
                created: appoint.created
                  ? moment(appoint.created).format("DD/MM/YYYY")
                  : null,
                status: appoint.status,
                patientObj: patient,
                patientName: patient.name,
                patient_identifier: patient.identifier,
                practitioner: appoint.specialty.name
                  ? appoint.specialty.name
                  : "Enfermería",
                practitionerName: practitioner.name,
                practitionerObj: practitioner,
                practitionerId: practitioner.id,
                interactions: interactions,
              };
            })
          )
        );
      })
      .catch((e) => {
        dispatch(updatePatientAppointments([]));
        console.log(e);
      });
  }
}

export function loadExpertSystem(id, user, dispatch) {
  let users = [];
  if (user.isAuth) {
    createExpertSystem(
      id,
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        dispatch(updateExpertSystem(response.data));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updateExpertSystem({}));
      });
  }
}

export function loadPractitioners(user, dispatch) {
  let practitioners = [];
  if (user.isAuth) {
    console.log(user);
    listPractitioners(
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        response.data.forEach((prt) => {
          let practitioner = Object.assign(prt, {
            name:
              prt.name ||
              (prt.given && prt.family ? prt.given + " " + prt.family : null) ||
              prt.username,
          });
          practitioners.push(practitioner);
        });
        dispatch(updatePractitioners(practitioners));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updatePractitioners(practitioners));
      });
  }
}

export function loadExam(id, code, user, dispatch) {
  let exams = [];
  if (user.isAuth) {
    getExam(
      id,
      code,
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        response.data.exam_data.forEach((exam) => {
          exams.push({
            date: moment.parseZone(exam.date).format("DD/MM/YYYY HH:mm:ss"),
            unit: exam.unit,
            value: exam.value,
          });
        });
        dispatch(updatePatientExams(exams));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updatePatientExams(exams));
      });
  }
}

export function loadExamTypes(user, dispatch) {
  let exams = [];
  if (user.isAuth) {
    getExamTypes(
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        Object.keys(response.data.exam_codes).forEach((k) => {
          exams.push({
            name: response.data.exam_codes[k].display,
            code: response.data.exam_codes[k].code,
            system: response.data.exam_codes[k].system,
            value: response.data.exam_codes[k].code,
          });
        });
        dispatch(updateExamTypes(exams));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updateExamTypes(exams));
      });
  }
}

export function loadPatientMedicalRecord(id, user, dispatch) {
  var medicalRecord = {};
  const { idToken, accessToken } = user.signInUserSession;
  if (user.isAuth) {
    getPatientMedicalRecord(id, idToken.jwtToken, accessToken.jwtToken)
      .then((response) => {
        medicalRecord = response.data;
        dispatch(updatePatientMedicalRecord(medicalRecord));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updatePatientMedicalRecord({}));
      });
  }
}

export function loadStatisticsData(user, dispatch) {
  if (user.isAuth) {
    const { idToken, accessToken } = user.signInUserSession;
    getStatisticsData(idToken.jwtToken, accessToken.jwtToken)
      .then((response) => {
        dispatch(updateStatisticsData(response.data));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updateStatisticsData({}));
      });
  }
}
