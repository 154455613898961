import React, { useMemo, useState } from "react";
import useStyles from "./style";
import { connect } from "react-redux";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import IconMicrophone from "../../statics/images/icons/microfono.svg";
import PropTypes from "prop-types";
import { TextToSpeech } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
const sdk = require("microsoft-cognitiveservices-speech-sdk");
const config = require("../../config.json");
const subscriptionToken = config["cognitiveServicesSubscriptionToken"];
const region = config["cognitiveServicesRegion"];

const speechConfig = sdk.SpeechConfig.fromSubscription(
  subscriptionToken,
  region
);
speechConfig.speechRecognitionLanguage = "es-ES";
let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
let recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

const CaptureAudioComponent = ({
  dispatch,
  step,
  transcribing,
  updateStreamingText,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const startStreaming = () => {
    setLoading(!loading);

    console.log("Speak into your microphone.");
    recognizer.recognizing = (s, e) => {
      console.log(`RECOGNIZING: Text=${e.result.text}`);
    };

    recognizer.recognized = (s, e) => {
      if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
        if (e.result.text !== "") {
          updateStreamingText(e.result.text);
        }
        console.log(`RECOGNIZED: Text=${e.result.text}`);
      } else if (e.result.reason === sdk.ResultReason.NoMatch) {
        console.log("NOMATCH: Speech could not be recognized.");
      }
    };

    recognizer.canceled = (s, e) => {
      // console.log(`CANCELED: Reason=${e.reason}`);

      if (e.reason === sdk.CancellationReason.Error) {
        // console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
        // console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
        // console.log("CANCELED: Did you update the subscription info?");
      }

      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.sessionStopped = (s, e) => {
      // console.log("\n    Session stopped event.");
      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.startContinuousRecognitionAsync();
  };

  const stopStreaming = () => {
    setLoading(!loading);
    recognizer.stopContinuousRecognitionAsync();
  };

  return (
    <>
      <div>
        <Grid container direction="column" className={classes.wrapper}>
          <Grid item style={{ marginBottom: 5 }}>
            <IconButton
              className={(classes.margin, classes.buttonAudio)}
              onClick={(e) => (!loading ? startStreaming() : stopStreaming())}
            >
              {loading ? (
                <PauseIcon color="secondary" fontSize="large" />
              ) : transcribing ? (
                <TextToSpeech color="secondary" fontSize="large" />
              ) : (
                <img src={IconMicrophone} alt="" />
              )}
              {(loading || transcribing) && (
                <CircularProgress size={68} className={classes.fabProgress} />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            {!loading ? (
              !transcribing ? (
                <span className={classes.record}>{t("record")}</span>
              ) : (
                <span className={classes.record}>{t("transcribing")}</span>
              )
            ) : (
              <span className={classes.record}>{t("transcribe")}</span>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

CaptureAudioComponent.propTypes = {
  step: PropTypes.string,
  transcribing: PropTypes.bool,
  updateStreamingText: PropTypes.func,
};

export default connect()(CaptureAudioComponent);
