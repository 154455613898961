import { useStyles } from "./styles";
import steepStatus from "../../constants/steepStatus";
const SteepsForm = ({ spanSteep, steepState }) => {
  const classes = useStyles();
  return (
    <div>
      {steepState === steepStatus.isActive && (
        <span className={classes.activeNameSteep}>{spanSteep}</span>
      )}
      {steepState === steepStatus.isComplete && (
        <span className={classes.completedNameSteep}>{spanSteep}</span>
      )}
      {steepState === steepStatus.isEmpty && (
        <span className={classes.nameSteep}>{spanSteep}</span>
      )}

      {steepState === steepStatus.isPending && (
        <span className={classes.nameSteep}>{spanSteep}</span>
      )}
    </div>
  );
};

export default SteepsForm;
