import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "react-h5-audio-player/lib/styles.css";

import { Grid, Typography } from "@material-ui/core";
import { AccountSearch } from "mdi-material-ui";
import OrganizationForm from "../../components/forms/OrganizationForm";
import {
  loadOrganizations,
  loadUsers,
} from "../../services/utils/load-data-services";
import useStyles, { BorderLinearProgress, customStyles } from "./styles";
import FilterComponent from "./sections/FilterComponent";
import Columns from "./sections/Columns";

const Organizations = ({ user, users, organizations, dispatch }) => {
  const classes = useStyles();
  const dialogRef = useRef();

  const [filterText, setFilterText] = useState("");
  const [perPage, setPerPage] = useState(10);
  const { t } = useTranslation();

  useEffect(() => {
    if (!users) {
      loadUsers(user, dispatch);
    }
    if (!organizations) {
      loadOrganizations(user, dispatch);
    }
  }, [user]);

  const handleClickOpen = (org, isEdit) => {
    dialogRef.current.openDialog(org, user, isEdit);
  };
  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const subHeaderComponentMemo = (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={useStyles().displayContent}
    >
      <Grid container alignItems="flex-start" item={true} xs={6}></Grid>
      <Grid container item={true} xs={6} className={useStyles().rightAlign}>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          filterLabelText={t("organization-filter-label-text")}
          AccountSearch={AccountSearch}
        />
      </Grid>
    </Grid>
  );

  const filteredItems = organizations
    ? organizations.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.code &&
            item.code.toString().includes(filterText.toLowerCase())) ||
          (item.addressN &&
            item.addressN.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.phone &&
            item.phone.toLowerCase().includes(filterText.toLowerCase()))
      )
    : [];

  const currentUser =
    user && users ? users.find((u) => u.username === user.username) : null;

  return (
    <>
      <>
        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-area")}:{" "}
                {currentUser
                  ? t(currentUser.practitioner)
                  : t("user-not-defined")}
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-medical-center")}:{" "}
                {currentUser ? currentUser.organization : t("user-not-defined")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography variant="h5" gutterBottom>
                <b>
                  {currentUser
                    ? currentUser.name || currentUser.username
                    : t("user-no-name")}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography variant="h7" gutterBottom>
                {moment().locale("es").format("llll")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*<Title title="Organizaciones"/>*/}
        <DataTable
          noHeader
          subHeader
          striped
          persistTableHead
          noDataComponent={t("organization-loading-organizations")}
          subHeaderComponent={subHeaderComponentMemo}
          subHeaderAlign="left"
          pagination
          paginationComponentOptions={{
            rowsPerPageText: t("elements-per-page"),
            rangeSeparatorText: t("range-separator-text"),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: t("select-all-rows"),
          }}
          columns={Columns(t, handleClickOpen)}
          customStyles={customStyles}
          data={filteredItems}
        />
        {!organizations ? <BorderLinearProgress /> : null}
        <OrganizationForm
          ref={dialogRef}
          reload={() => loadOrganizations(user, dispatch)}
        />
      </>
    </>
  );
};

const getData = (state) => ({
  user: state.user,
  users: state.users,
  organizations: state.organizations,
});

export default connect(getData)(Organizations);
