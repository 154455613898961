import React, { forwardRef, useImperativeHandle } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
  Button,
  Toolbar,
  Grid,
  IconButton,
  AppBar,
  Typography,
  Divider,
  DialogContentText,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { CloseCircleOutline } from "mdi-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import { createAppointment } from "../../services/fhir-api-services";
import moment from "moment";
import clsx from "clsx";
import { addPatientAppointment, initAppointment } from "../../store/actions";
import mapStateToProps from "react-redux/lib/connect/mapStateToProps";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EventIcon from "@material-ui/icons/Event";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

function mapDispatchToProps(dispatch) {
  return {
    addAppointment: bindActionCreators(addPatientAppointment, dispatch),
  };
}

const NewAppointmentDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const appointmentOptions = [
    { value: "control", name: t("control") },
    { value: "ingress", name: t("ingress") },
    { value: "reingress", name: t("reingress") },
    { value: "discharge", name: t("discharge") },
  ];

  const appointmentArea = [
    { name: t("general-medicine"), value: "Medicina General" },
    { name: t("internal-medicine"), value: "Medicina Interna" },
    { name: t("psychology"), value: "Psicología" },
    { name: t("nursing"), value: "Enfermería" },
    { name: t("nutrition"), value: "Nutrición" },
    { name: t("kinesiology"), value: "Kinesiólogía" },
    { name: t("occupational-therapy"), value: "Terapia Ocupacional" },
    { name: t("social-assistant"), value: "Asistente Social" },
  ];

  const [open, setOpen] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [practitioners, setPractitioners] = React.useState([]);

  const [appointment, setAppointment] = React.useState({
    patient: "",
    date: new Date(),
    careModality: "",
    careArea: "",
    practitioner: "",
    samePractitioner: false,
    appointmentPractitioner: "",
  });

  const [errors, setError] = React.useState({
    patient: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    date: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    careModality: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    careArea: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    practitioner: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
  });

  const handleAppointmentChangeValue = (prop) => (event, value) => {
    let val = "";
    if (
      event &&
      event.target &&
      (event.target.value || event.target.value === "")
    ) {
      val = event.target.value;
      setAppointment({ ...appointment, [prop]: event.target.value });
    } else if (event instanceof Date) {
      val = event;
      setAppointment({ ...appointment, [prop]: event });
    } else if (value) {
      val = value.name;
      setAppointment({ ...appointment, [prop]: value.name });
    } else {
      val = event;
      setAppointment({ ...appointment, [prop]: event });
    }
    if (prop in errors) {
      setError({
        ...errors,
        [prop]: Object.assign(errors[prop], { error: false, errorMessage: "" }),
      });
    }
  };

  const [uploadingAppointment, setUploadingAppointment] = React.useState(false);

  const validateForm = () => {
    let validate = true;

    Object.entries(errors).forEach((array, index) => {
      const key = array[0];
      const value = array[1];

      if (value.mandatory && (!appointment[key] || appointment[key] === "")) {
        validate = false;
        setError({
          ...errors,
          [key]: Object.assign(value, {
            error: true,
            errorMessage: value.mandatoryMessage,
          }),
        });
      }
    });

    return validate;
  };

  const addAppointment = () => {
    if (validateForm()) {
      setUploadingAppointment(true);

      let careModality = appointmentOptions.find(
        (a) => a.name === appointment.careModality
      );
      let careArea = appointmentArea.find(
        (a) => a.name === appointment.careArea
      );

      let practitioner = practitioners.find(
        (p) => p.name === appointment.practitioner
      );

      let patient;
      if (typeof appointment.patient === "object") {
        patient = appointment.patient;
      } else {
        patient = patients.find((p) => p.name === appointment.patient);
      }

      let nAppointmentObj = {
        patient: {
          name: patient.name,
          id: patient.id,
        },
        practitioner: {
          name: practitioner.name,
          id: practitioner.practitioner_id,
        },
        specialty: careArea,
        appointmentType: careModality,
        start: moment(appointment.date).format("YYYY-MM-DDThh:mm:ss.sssZ"),
        end: moment(appointment.date).format("YYYY-MM-DDThh:mm:ss.sssZ"),
        created: moment().format("YYYY-MM-DDThh:mm:ss.sssZ"),
      };

      if (appointment.samePractitioner) {
        let appointmentPractitioner = practitioners.find(
          (p) => p.name === appointment.appointmentPractitioner
        );
        nAppointmentObj.typingPractitioner = {
          name: appointmentPractitioner.name,
          id: appointmentPractitioner.practitioner_id,
        };
      }

      createAppointment(
        nAppointmentObj,
        user.signInUserSession.idToken.jwtToken,
        user.signInUserSession.accessToken.jwtToken
      )
        .then((response) => {
          let practitioner = response.data.participant.find((part) =>
            part.actor.reference.includes("Practitioner")
          );
          let patient = response.data.participant.find((part) =>
            part.actor.reference.includes("Patient")
          );
          props.addAppointment(
            {
              id: response.data.id,
              icon: <EventIcon />,
              appointmentObj: response.data.appointmentObj,
              appointmentName: response.data.appointmentType.text
                ? response.data.appointmentType.text
                : "Control",
              appointmentType: "Control de diabétes",
              date: moment(response.data.start).format("DD/MM/YYYY"),
              status: response.data.status,
              patientObj: patient,
              patientId: patient.actor.reference.split("/")[1],
              patientName: patient.actor.display,
              practitioner:
                response.data.specialty.length > 0
                  ? response.data.specialty[0].text
                  : "Enfermería",
              practitionerName: practitioner.actor.display,
              practitionerObj: practitioner,
              practitionerId: practitioner.actor.reference.split("/")[1],
            },
            response.data.id
          );
        })
        .catch((e) => {
          setUploadingAppointment(false);
          console.log(e);
        });
    }
  };

  useImperativeHandle(ref, () => ({
    openDialog(user, organizations, patients, practitioners, currentPatient) {
      setOpen(true);
      setOrganizations(organizations);
      setUser(user);
      setPatients(patients);
      setPractitioners(practitioners);
      if (currentPatient) {
        setAppointment({ ...appointment, ["patient"]: currentPatient });
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppBar
            position="static"
            className={classes.appBarStyle}
            elevation={0}
          >
            <Toolbar variant="dense">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={useStyles().displayContent}
              >
                <Grid container item={true} xs={"auto"}>
                  <Typography
                    component="h2"
                    variant="h6"
                    className={useStyles().titleStyle}
                  >
                    {t("new-appointment")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="flex-end"
                  className={useStyles().rightAlign}
                  item={true}
                  xs={"auto"}
                >
                  <IconButton
                    color="primary"
                    aria-label="close button"
                    onClick={handleClose}
                  >
                    <CloseCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              Crear nueva cita, complete el formulario a continuación para
              iniciar captura de información.
            </DialogContentText>
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label={t("appointment-date")}
                  format="dd/MM/yyyy"
                  className={clsx(classes.displayFlex)}
                  value={appointment.date}
                  onChange={handleAppointmentChangeValue("date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
            <Divider className={classes.marginDivider} />

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={patients}
                    getOptionLabel={(option) => {
                      if (typeof option === "object") {
                        return option.identifier
                          ? option.identifier + " - " + option.name
                          : option.name;
                      } else {
                        return option;
                      }
                    }}
                    onChange={handleAppointmentChangeValue("patient")}
                    getOptionSelected={(option, value) => {
                      return option.id === value.id || value === option.name;
                    }}
                    value={appointment.patient ? appointment.patient : null}
                    noOptionsText={t("no-patients-found")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={appointment.patient}
                        error={errors.patient.error}
                        helperText={t(errors.patient.errorMessage)}
                        label={t("select-patient")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={appointmentOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    onChange={handleAppointmentChangeValue("careModality")}
                    noOptionsText={t("no-care-modality-found")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={appointment.careModality}
                        error={errors.careModality.error}
                        helperText={t(errors.careModality.errorMessage)}
                        label={t("attention-modality")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={appointmentArea}
                    getOptionLabel={(option) => option.name}
                    onChange={handleAppointmentChangeValue("careArea")}
                    noOptionsText={t("no-area-found")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={appointment.careArea}
                        error={errors.careArea.error}
                        helperText={t(errors.careArea.errorMessage)}
                        label={t("attention-area")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>

            <Divider className={classes.marginDivider} />
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={practitioners}
                    getOptionLabel={(option) => option.name}
                    onChange={handleAppointmentChangeValue("practitioner")}
                    noOptionsText={t("no-professional-found")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={appointment.practitioner}
                        error={errors.practitioner.error}
                        helperText={t(errors.practitioner.errorMessage)}
                        label={t("attending-professional")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={appointment.samePractitioner}
                      onChange={handleAppointmentChangeValue(
                        "samePractitioner"
                      )}
                      name="samePractitioner"
                      color="secondary"
                    />
                  }
                  label={t("same-practitioner-description")}
                />
              </Grid>
            </Grid>

            {appointment.samePractitioner && (
              <Grid container spacing={3} className={classes.marginPerGrid}>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      options={practitioners}
                      getOptionLabel={(option) => option.name}
                      onChange={handleAppointmentChangeValue(
                        "appointmentPractitioner"
                      )}
                      noOptionsText={"No se encontró el profesional"}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={appointment.appointmentPractitioner}
                          label={t("registering-professional")}
                          variant="outlined"
                        />
                      )}
                    ></Autocomplete>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {uploadingAppointment ? (
              <CircularProgress className={classes.marginCircular} />
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addAppointment}
                >
                  <span className={classes.whiteText}>{t("start")}</span>
                </Button>
              </div>
            )}
          </DialogActions>
        </MuiPickersUtilsProvider>
      </Dialog>
    </>
  );
});

export default NewAppointmentDialog;
