import { UPDATE_PATIENT_EXAMS, UPDATE_EXAM_TYPES } from "../actionTypes";

const defaultState = {
  exams: null,
  types: null,
};

const patientExams = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_PATIENT_EXAMS:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_EXAM_TYPES:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default patientExams;
