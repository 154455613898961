import React, { useRef, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import CardContent from "@material-ui/core/CardContent";
import {
  Grid,
  Button,
  TextField,
  Card,
  InputLabel,
  Select,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import AppointmentFormHeader from "../../components/appointment/AppointmentFormHeader";
import AppointmentFormStepName from "../../components/appointment/AppointmentFormStepName";
import AppointmentFormStepper from "../../components/appointment/AppointmentFormStepper";
import moment from "moment";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PropTypes from "prop-types";
import { createEncounter } from "../../services/fhir-api-services";
import { useHistory } from "react-router-dom";
import {
  nextStep,
  savePatientData,
  updateExpertSystem,
  updatePatientAppointments,
  updatePharmacologicalInteractions,
} from "../../store/actions";
import { getInteractions } from "../../services/structure-data-services";
import { Alert, AlertTitle } from "@material-ui/lab";
import clsx from "clsx";
import { loadUsers } from "../../services/utils/load-data-services";
import UserForm from "../../components/forms/UserForm";
import ExpertSystemForm from "../../components/forms/ExpertSystemForm";
import {
  acceptExpertSystemRecommendation,
  checkExpertSystem,
} from "../../services/expert-system-services";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { CheckCircle } from "@material-ui/icons";
import { AccountCheck, AccountRemove, MinusCircle } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SubjectRoundedIcon from "@material-ui/icons/SubjectRounded";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GraficaIcon from "../../statics/images/icons/grafico.svg";
import WarningIcon from "../../statics/images/icons/warning.svg";
import AppointmentStep from "../../components/appointment/AppointmentStep";
import {
  useStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionMoreInteraction,
} from "./style";

const getSummary = (state) => ({
  user: state.user,
  summary: state.appointmentStep,
  interactions: state.appointmentStep,
  expertSystem: state.patientExpertSystem,
  practitioners: state.practitioners,
  organizations: state.organizations,
});

const AppointmentSummary = ({
  summary,
  interactions,
  user,
  appointment,
  expertSystem,
  practitioners,
  organizations,
  dispatch,
}) => {
  var summary = summary;
  let history = useHistory();
  const classes = useStyles();
  const dialogRef = useRef();
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [loadingEncounter, setLoadingEncounter] = useState(false);
  const [loadingAcceptRecommendation, setLoadingAcceptRecommendation] =
    useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const saveData = () => {
    Object.keys(summary).forEach((key) => {
      if (summary[key].comprehend) {
        let comprehend = summary[key].comprehend;

        if (comprehend.condition_entities) {
          comprehend.condition_entities.forEach((c_e, index) => {
            if (
              !(
                "selected_code" in
                summary[key].comprehend.condition_entities[index]
              )
            ) {
              summary[key].comprehend.condition_entities[index].selected_code =
                summary[key].comprehend.condition_entities[index].ICD10[0];
            }
          });
        }

        if (comprehend.test_entities) {
          comprehend.test_entities.forEach((t_e, index) => {
            if (
              !("selected_code" in summary[key].comprehend.test_entities[index])
            ) {
              summary[key].comprehend.test_entities[index].selected_code =
                summary[key].comprehend.test_entities[index].code_list[0];
            }
            if (!("date" in summary[key].comprehend.test_entities[index])) {
              summary[key].comprehend.test_entities[index].date = moment(
                Date()
              ).toISOString();
            }
          });
        }
      }
    });

    const org = practitioners.find(
      (p) => p.practitioner_id === appointment.practitionerId
    );
    if (org) {
      const org_id = organizations.find((o) => o.name === org.organization);
      if (org_id) {
        setLoadingEncounter(true);
        createEncounter(
          {
            appointment: appointment.id,
            organization: org_id.id,
            conclusion: summary,
          },
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            dispatch(updatePatientAppointments(null));
            history.push(`/patient/${appointment.patientId}`);
            setLoadingEncounter(false);
          })
          .catch((e) => {
            console.log(e);
            setLoadingEncounter(false);
          });
      }
    }
  };

  if (summary && !interactions) {
    if (user.isAuth) {
      getInteractions(
        summary,
        user.signInUserSession.idToken.jwtToken,
        user.signInUserSession.accessToken.jwtToken
      )
        .then((response) => {
          dispatch(
            updatePharmacologicalInteractions(response.data.interaction_list)
          );
        })
        .catch((e) => {
          dispatch(updatePharmacologicalInteractions({}));
          console.log(e);
        });
    }
  }

  let stepsResults = Object.entries(summary)
    .sort((a, b) => a[1].step - b[1].step)
    .map((step) => {
      return {
        step: step[1].step + 1,
        stepName: step[1].stepName,
        name: step[0],
        comprehend: step[1].comprehend,
        transcription: step[1].transcription,
      };
    });

  const openExpertSystemForm = () => {
    dialogRef.current.openDialog(expertSystem, user, false);
  };

  const checkExpert = (e) => {
    dispatch(updateExpertSystem(e));
  };

  const recommendationHandle = (accept) => {
    setLoadingAcceptRecommendation(true);
    if (accept) {
      acceptExpertSystemRecommendation(
        expertSystem.expert_id,
        expertSystem.pharma,
        user.signInUserSession.idToken.jwtToken
      )
        .then((response) => {
          dispatch(
            updateExpertSystem(Object.assign({ accepted: true }, expertSystem))
          );
          setLoadingAcceptRecommendation(false);
        })
        .catch((e) => {
          console.log(e);
          setLoadingAcceptRecommendation(false);
        });
    } else {
      dispatch(
        updateExpertSystem(Object.assign({ rejected: true }, expertSystem))
      );
      setLoadingAcceptRecommendation(false);
    }
  };

  return (
    <>
      <Card className={classes.noPadding}>
        <CardContent>
          <Card className={classes.noBorder} variant="outlined">
            <CardContent className={classes.noPadding}>
              {expertSystem && expertSystem.recommendation ? (
                <TextField
                  className={classes.marginTopTextField}
                  label={t("recommendation")}
                  value={expertSystem.recommendation}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {expertSystem.pharma &&
                          expertSystem.pharma.length > 0 &&
                          ((loadingAcceptRecommendation && (
                            <CircularProgress
                              size={20}
                              color="secondary"
                              className={clsx(classes.marginRight)}
                            />
                          )) ||
                            (expertSystem.accepted && (
                              <AccountCheck color="secondary" />
                            )) ||
                            (expertSystem.rejected && (
                              <AccountRemove className={classes.colorOrange} />
                            )) || (
                              <>
                                <IconButton
                                  onClick={() => recommendationHandle(true)}
                                  edge="end"
                                >
                                  <CheckCircle color="secondary" />
                                </IconButton>
                                <IconButton
                                  onClick={() => recommendationHandle(false)}
                                  edge="end"
                                >
                                  <MinusCircle
                                    className={classes.colorOrange}
                                  />
                                </IconButton>
                              </>
                            ))}
                      </InputAdornment>
                    ),
                  }}
                  readOnly
                  fullWidth
                  variant="outlined"
                ></TextField>
              ) : null}

              {interactions && interactions.length > 0 ? (
                <div className={classes.mt10}>
                  <Accordion
                    className={
                      (classes.iconAlert,
                      classes.bgAlert,
                      classes.accordionAlert)
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className={classes.colorWhite} />
                      }
                      aria-controls="panelContainerAlert-content"
                      id="panelContainerAlert-header"
                      className={classes.headerAccordion}
                    >
                      <Grid container direction="row">
                        <Grid item xs={3} sm={4}>
                          <Typography
                            className={
                              (classes.heading,
                              classes.displayFlex,
                              classes.alignItemsCenter)
                            }
                          >
                            <span className={classes.barraIteracion}></span>
                            <InfoOutlinedIcon className={classes.colorWhite} />
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={8}>
                          <Typography
                            className={
                              (classes.heading,
                              classes.displayFlex,
                              classes.alignItemsCenter)
                            }
                          >
                            {interactions && interactions.length === 1 ? (
                              <span className={classes.nameInteracciones}>
                                {" "}
                                Se han encontrado {interactions.length} alerta o
                                sugerencia
                              </span>
                            ) : (
                              <span className={classes.nameInteracciones}>
                                {" "}
                                Se han encontrado {interactions.length} alertas
                                y sugerencias
                              </span>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accodionContent}>
                      {interactions
                        .sort(({ severity: item1 }, { severity: item2 }) => {
                          const sever1 =
                            item1 != "mayor"
                              ? item1 == "moderado"
                                ? 1
                                : 0
                              : 2;
                          const sever2 =
                            item2 != "mayor"
                              ? item2 == "moderado"
                                ? 1
                                : 0
                              : 2;

                          if (sever1 < sever2) {
                            return 1;
                          } else if (sever1 > sever2) {
                            return -1;
                          }

                          return 0;
                        })
                        .map((interaction, index) => {
                          return (
                            <Accordion
                              key={index}
                              style={{}}
                              className={
                                ((interaction.severity === "moderada" &&
                                  classes.bgInteractionModerate) ||
                                  (interaction.severity === "mayor" &&
                                    classes.bgInteractionHigher) ||
                                  classes.bgInteractionLess,
                                classes.accordionAlertContent)
                              }
                            >
                              <AccordionSummary
                                className={classes.titleInteractions}
                                aria-controls="panelContentAlert-content"
                                id="panelContentAlert-header"
                                expandIcon={
                                  <ExpandMoreIcon
                                    className={
                                      (interaction.severity === "moderada" &&
                                        classes.colorInteractionModerate) ||
                                      (interaction.severity === "mayor" &&
                                        classes.colorInteractionHigher) ||
                                      classes.colorInteractionLess
                                    }
                                  />
                                }
                              >
                                <Typography className={classes.textSeverity}>
                                  <ReportProblemOutlinedIcon
                                    className={
                                      (interaction.severity === "moderada" &&
                                        classes.colorInteractionModerate) ||
                                      (interaction.severity === "mayor" &&
                                        classes.colorInteractionHigher) ||
                                      classes.colorInteractionLess
                                    }
                                  />
                                  {(interaction.severity === "moderada" && (
                                    <span className={classes.typeSeverity}>
                                      Severidad moderada -{" "}
                                      <span className={classes.fontWeight500}>
                                        {interaction.ingredient.name} +{" "}
                                        {interaction.affected_ingredient.name}
                                      </span>
                                    </span>
                                  )) ||
                                    (interaction.severity === "mayor" && (
                                      <span className={classes.typeSeverity}>
                                        Severidad alta -{" "}
                                        <span className={classes.fontWeight500}>
                                          {interaction.ingredient.name} +{" "}
                                          {interaction.affected_ingredient.name}
                                        </span>
                                      </span>
                                    )) || (
                                      <span className={classes.typeSeverity}>
                                        Severidad menor -{" "}
                                        <span className={classes.fontWeight500}>
                                          {interaction.ingredient.name} +{" "}
                                          {interaction.affected_ingredient.name}
                                        </span>
                                      </span>
                                    )}
                                </Typography>
                                <span style={{ marginLeft: "30px" }}>
                                  {interaction.description}
                                </span>
                              </AccordionSummary>
                              <AccordionDetails
                                style={{
                                  borderBottom: "none",
                                  flexDirection: "column",
                                  backgroundColor: "#fff",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: "#021842",
                                    fontWeight: "400",
                                    fontStyle: "16px",
                                  }}
                                >
                                  {/* {interaction.description} */}
                                </Typography>
                                <AccordionDetails
                                  style={{
                                    flexDirection: "column",
                                    borderBottom: "none",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#021842",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <SubjectRoundedIcon /> Interacción
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: "#021842",
                                      padding: "10px 12px",
                                      borderRadius: "4px",
                                      boxShadow:
                                        "0px 4px 10px rgb(0 0 0 / 10%)",
                                    }}
                                  >
                                    {interaction.description}
                                  </Typography>

                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#021842",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginBottom: "10px",
                                      marginTop: "28px",
                                    }}
                                  >
                                    <SubjectRoundedIcon /> Descripción extensa
                                    de la interacción
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: "#021842",
                                      padding: "10px 12px",
                                      borderRadius: "4px",
                                      boxShadow:
                                        "0px 4px 10px rgb(0 0 0 / 10%)",
                                    }}
                                  >
                                    {interaction.extended_description}
                                  </Typography>
                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#021842",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginBottom: "10px",
                                        marginTop: "28px",
                                        marginRight: "30px",
                                      }}
                                    >
                                      <img
                                        style={{ marginRight: "4px" }}
                                        src={WarningIcon}
                                        alt=""
                                      />{" "}
                                      <span> Severidad del efecto</span>
                                    </span>
                                    <Typography
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#021842",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginBottom: "10px",
                                        marginTop: "28px",
                                      }}
                                    >
                                      <img
                                        style={{ marginRight: "4px" }}
                                        src={GraficaIcon}
                                        alt=""
                                      />{" "}
                                      <span> Nivel de evidencia</span>
                                    </Typography>
                                  </Typography>
                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        color: "#021842",
                                        padding: "10px 12px",
                                        borderRadius: "4px",
                                        boxShadow:
                                          "0px 4px 10px rgb(0 0 0 / 10%)",
                                        width: "182px",
                                        marginRight: "30px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {interaction.severity}
                                    </Typography>
                                    <Typography
                                      style={{
                                        color: "#021842",
                                        padding: "10px 12px",
                                        borderRadius: "4px",
                                        boxShadow:
                                          "0px 4px 10px rgb(0 0 0 / 10%)",
                                        width: "182px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {interaction.evidence_level.replace(
                                        /[_]/g,
                                        " "
                                      )}
                                    </Typography>
                                  </Typography>

                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#021842",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      marginBottom: "10px",
                                      marginTop: "28px",
                                    }}
                                  >
                                    <StarBorderOutlinedIcon /> Recomendación de
                                    DrugBank
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: "#021842",
                                      padding: "10px 12px",
                                      borderRadius: "4px",
                                      boxShadow:
                                        "0px 4px 10px rgb(0 0 0 / 10%)",
                                    }}
                                  >
                                    {interaction.management}
                                  </Typography>
                                </AccordionDetails>
                                {/* <AccordionMoreInteraction
                                  style={{
                                    border: "none",
                                  }}
                                  className={classes.accordionMoreInteration}
                                >
                                  <AccordionSummary
                                    className={
                                      classes.moreInformationInteraction
                                    }
                                    expandIcon={
                                      <span
                                        style={{
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                          boxShadow:
                                            "0px 2px 10px rgb(0 0 0 / 10%)",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        className={
                                          (interaction.severity ===
                                            "moderada" &&
                                            classes.bgMoreInteractionModerate) ||
                                          (interaction.severity === "mayor" &&
                                            classes.bgMoreInteractionHigher) ||
                                          classes.bgMoreInteractionLess
                                        }
                                      >
                                        <ArrowForwardRoundedIcon
                                          style={{
                                            color: "#fff",
                                            fontSize: "18px",
                                          }}
                                        />
                                      </span>
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{
                                      borderBottom: "none",
                                      backgroundColor: "transparent",
                                    }}
                                  ></AccordionSummary>
                                </AccordionMoreInteraction> */}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) : null}

              <Grid container className={classes.justifySpaceBetween}>
                <Grid item xs={6}>
                  <Typography
                    className={classes.summaryStepSubtitle}
                    gutterBottom
                  >
                    {t("summary-step-subtitle")}
                  </Typography>
                </Grid>

                <Grid item xs={"auto"} className={classes.buttonClass}>
                  <Button
                    className={classes.marginRight}
                    onClick={() => openExpertSystemForm()}
                    size="small"
                    variant="outlined"
                    color="secondary"
                  >
                    {t("summary-step-see-recommendations")}
                  </Button>
                </Grid>
              </Grid>
              {stepsResults.map((step, index) =>
                step.transcription !== "" ? (
                  <Accordion
                    style={{ border: "none" }}
                    expanded={expanded === "panel" + step.step}
                    onChange={handleChange("panel" + step.step)}
                    key={index}
                  >
                    <AccordionSummary
                      style={{
                        background: "#F1F8FF",
                        marginBottom: "15px",
                        border: "1px solid #D3DCE2",
                        borderRadius: "4px",
                      }}
                      expandIcon={
                        <ExpandMoreIcon className={classes.colorBlue} />
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#021842",
                        }}
                      >
                        {t("step") + " " + step.step}
                      </Typography>
                      <Typography
                        className={classes.secondaryHeading}
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#021842",
                        }}
                      >
                        {step.stepName}
                      </Typography>
                    </AccordionSummary>
                    {/* Trasncription */}
                    <AccordionDetails
                      style={{ flexDirection: "column", borderBottom: "0" }}
                    >
                      <Typography
                        className={classes.subTitle}
                        color="textSecondary"
                        gutterBottom
                      >
                        Transcipción
                      </Typography>
                      <Card className={classes.cardTranscription}>
                        <CardContent>
                          <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            {step.transcription}
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                    <AccordionDetails
                      style={{ flexDirection: "column", borderBottom: "0" }}
                    >
                      {step.comprehend &&
                      step.comprehend.condition_entities &&
                      step.comprehend.condition_entities.length > 0 ? (
                        <Typography
                          className={classes.blueGrayTitle}
                          gutterBottom
                          style={{ marginBottom: "15px" }}
                        >
                          {t("findings")}
                        </Typography>
                      ) : null}

                      {step.comprehend && step.comprehend.condition_entities
                        ? step.comprehend.condition_entities.map(
                            (value, index) => {
                              return (
                                <Grid container spacing={1} key={index}>
                                  <Grid
                                    item
                                    xs={2}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Typography
                                      className={classes.blueGraySmall}
                                      gutterBottom
                                    >
                                      {t("findings")}
                                      <span
                                        style={{
                                          color: "#346DFF",
                                          marginLeft: "3px",
                                        }}
                                      >
                                        {value.presence
                                          ? [t("presence-single")]
                                          : t("missing")}
                                      </span>
                                    </Typography>
                                    <Typography
                                      className={classes.inputBase}
                                      gutterBottom
                                    >
                                      {value.finding}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Typography
                                      className={classes.blueGraySmall}
                                      gutterBottom
                                    >
                                      {t("code")}
                                    </Typography>
                                    <FormControl className={classes.selectBase}>
                                      <Select
                                        native
                                        fullWidth
                                        IconComponent={KeyboardArrowDownIcon}
                                        onChange={(event) => {
                                          summary[
                                            step.name
                                          ].comprehend.condition_entities[
                                            index
                                          ].selected_code = summary[
                                            step.name
                                          ].comprehend.condition_entities[
                                            index
                                          ].ICD10.find(
                                            (i) => i.Code === event.target.value
                                          );
                                        }}
                                        inputProps={{
                                          name: "Código",
                                          id: "outlined-code",
                                        }}
                                      >
                                        {value.ICD10.map((icd10) => (
                                          <option value={icd10.Code}>
                                            {icd10.Description}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    xs={1}
                                  >
                                    <Button
                                      margin="dense"
                                      value={
                                        value.attributes.length > 0
                                          ? value.attributes
                                              .map(
                                                (attr) =>
                                                  attr.Type +
                                                  ": " +
                                                  attr.Text_es
                                              )
                                              .join(",")
                                          : "- - - - -"
                                      }
                                      onChange={(event) => {}}
                                      fullWidth
                                      className={classes.btnTooltip}
                                    >
                                      {t("attributes")}
                                    </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    xs={1}
                                  >
                                    <HighlightOffIcon
                                      className={classes.colorBlue}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }
                          )
                        : null}

                      {step.comprehend &&
                      step.comprehend.pharma_entities &&
                      step.comprehend.pharma_entities.length > 0 ? (
                        <Typography
                          className={classes.resultSubtitle}
                          gutterBottom
                        >
                          {t("medicines")}
                        </Typography>
                      ) : null}

                      {step.comprehend && step.comprehend.pharma_entities
                        ? step.comprehend.pharma_entities.map(
                            (value, index) => {
                              return (
                                <Grid container spacing={1} key={index}>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Typography
                                      className={classes.blueGraySmall}
                                      gutterBottom
                                    >
                                      {t("name")}
                                    </Typography>
                                    <Typography
                                      className={classes.inputBase}
                                      gutterBottom
                                    >
                                      {value.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Typography
                                      className={classes.blueGraySmall}
                                      gutterBottom
                                    >
                                      {t("composition")}
                                    </Typography>
                                    <Typography
                                      className={classes.inputBase}
                                      gutterBottom
                                    >
                                      {value.composition}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Typography
                                      className={classes.blueGraySmall}
                                      gutterBottom
                                    >
                                      {t("dosage")}
                                    </Typography>
                                    <Typography
                                      className={classes.inputBase}
                                      gutterBottom
                                    >
                                      {value.dosage}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Typography
                                      className={classes.blueGraySmall}
                                      gutterBottom
                                    >
                                      {t("frequency")}
                                    </Typography>
                                    <Typography
                                      className={classes.inputBase}
                                      gutterBottom
                                    >
                                      {value.frequency}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            }
                          )
                        : null}

                      {step.comprehend &&
                      step.comprehend.test_entities &&
                      step.comprehend.test_entities.length > 0 ? (
                        <Typography
                          className={classes.resultSubtitle}
                          gutterBottom
                        >
                          {t("exams")}
                        </Typography>
                      ) : null}

                      {step.comprehend && step.comprehend.test_entities
                        ? step.comprehend.test_entities.map((value, index) => {
                            const [examDate, setExamDateValue] = React.useState(
                              {
                                date: new Date(),
                              }
                            );

                            const handleExamDateValueChange =
                              (props) => (event) => {
                                setExamDateValue({
                                  ...examDate,
                                  [props]: event,
                                });
                                summary[step.name].comprehend.test_entities[
                                  index
                                ].date = moment(event).toISOString();
                              };

                            return (
                              <Grid container spacing={1} key={index}>
                                <Grid item xs={4}>
                                  <Typography
                                    className={classes.blueGraySmall}
                                    gutterBottom
                                  >
                                    {t("name")}
                                  </Typography>
                                  <Typography
                                    className={classes.inputBase}
                                    gutterBottom
                                  >
                                    {value.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography
                                    className={classes.blueGraySmall}
                                    gutterBottom
                                  >
                                    {t("value")}
                                  </Typography>
                                  <Typography
                                    className={classes.inputBase}
                                    gutterBottom
                                  >
                                    {value.value}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography
                                    className={classes.blueGraySmall}
                                    gutterBottom
                                  >
                                    {t("date")}
                                  </Typography>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      id="date-picker-dialog"
                                      openTo="date"
                                      format="dd/MM/yyyy"
                                      className={classes.inputBase}
                                      style={{ padding: "8px 11px" }}
                                      value={examDate.date}
                                      onChange={handleExamDateValueChange(
                                        "date"
                                      )}
                                      KeyboardButtonProps={{
                                        "aria-label": "change date",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={12}>
                                  <Typography
                                    className={classes.blueGraySmall}
                                    gutterBottom
                                  >
                                    {t("code")}
                                  </Typography>
                                  <FormControl className={classes.selectBase}>
                                    <Select
                                      native
                                      fullWidth
                                      label={t("code")}
                                      onChange={(event) => {
                                        summary[
                                          step.name
                                        ].comprehend.test_entities[
                                          index
                                        ].selected_code = summary[
                                          step.name
                                        ].comprehend.test_entities[
                                          index
                                        ].code_list.find(
                                          (code) =>
                                            code.name === event.target.value
                                        );
                                      }}
                                      inputProps={{
                                        name: "Código",
                                        id: "outlined-code",
                                      }}
                                    >
                                      {value.code_list.map((code) => (
                                        <option value={code.name}>
                                          {code.display || code.name}
                                        </option>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            );
                          })
                        : null}
                    </AccordionDetails>
                  </Accordion>
                ) : null
              )}

              <Grid container className={classes.flexEnd}>
                {!loadingEncounter ? (
                  <Button
                    className={classes.marginTopTextField}
                    onClick={() => {
                      saveData();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    {t("finalize")}
                  </Button>
                ) : (
                  <CircularProgress
                    className={clsx(
                      classes.marginRight,
                      classes.marginTopTextField
                    )}
                  />
                )}
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      <ExpertSystemForm ref={dialogRef} checkExpert={(e) => checkExpert(e)} />
    </>
  );
};

AppointmentSummary.propTypes = {
  appointment: PropTypes.object,
};

export default connect(getSummary)(AppointmentSummary);
