import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bgOrange: {
    backgroundColor: "#FA6A09",
  },
  bgWhite: {
    backgroundColor: "#FFF",
  },
  colorWhite: {
    color: "#FFF",
  },
  textField: {
    width: "100%",
  },
  textContactUs: {
    fontSize: "18px",
    textAlign: "center",
    color: "#FFF",
    textDecoration: "none",
  },
  textContactEmailVy: {
    color: "#FB8F46",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  btnPrimary: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FA6A09",
    height: "45px",
    width: "155px",
    marginTop: "70px",
  },
  marginDivider: {
    marginTop: "47px",
    marginBottom: theme.spacing(3),
    backgroundColor: "#DBDBDB",
    height: "1px",
  },
  checkRememberMe: {
    color: "#FFF;",
    justifyContent: "center",
    display: "flex",
  },
  checkRememberMeColor: {
    color: "rgba(255, 255, 255, 0.5);",
    justifyContent: "center",
    display: "flex",
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  welcome: {
    marginTop: theme.spacing(10),
    color: "white",
    fontWeight: "600",
    "font-size": "20px",
  },
  welcomeSubtitle: {
    color: "white",
    fontWeight: "600",
    "font-size": "20px",
  },
  inputsLogin: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    height: "56px",
    marginTop: "30px",
    "& input:-webkit-autofill,  input:-webkit-autofill:hover,  input:-webkit-autofill:focus,input:-webkit-autofill:active":
      {
        transition: "background-color 5000s",
        "-webkit-text-fill-color": "#fff !important",
      },
  },

  inputUser: {
    color: "white",
    height: "62px",
    "& .MuiFilledInput-underline:before": {
      opacity: "42%",
      borderBottom: "1px solid #000",
    },
    "& .MuiFilledInput-underline:after": {
      opacity: "42%",
      borderBottom: "1px solid #000",
    },
    "& input": {
      padding: "15px 12px 12px",
    },
  },
  inputPassword: {
    color: "white",
    height: "62px",
    "& .MuiFilledInput-underline:before": {
      opacity: "42%",
      borderBottom: "1px solid #000",
    },
    "& .MuiFilledInput-underline:after": {
      opacity: "42%",
      borderBottom: "1px solid #000",
    },
    "& input": {
      padding: "15px 12px 12px",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  forgotPassword: {
    marginTop: theme.spacing(3),
    "text-align": "center",
    color: "white",
    marginTop: "50px",
  },
  whiteText: {
    color: "white",
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  title: {
    "font-size": "45px",
    "font-weight": "300",
  },
  imageIcon: {
    height: "100%",
    width: "100%",
  },
  iconRoot: {
    marginLeft: theme.spacing(1),
    textAlign: "center",
  },
  justifyCenterVertical: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
}));

export default useStyles;
