import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { AccountSearch } from "mdi-material-ui";

const FilterUsers = ({ filterText, onFilter, filterLabelText }) => (
  <TextField
    id="search"
    type="text"
    margin="dense"
    placeholder={filterLabelText}
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountSearch />
        </InputAdornment>
      ),
    }}
  />
);

export default FilterUsers;
