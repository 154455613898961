import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { CodeBracesBox } from "mdi-material-ui";
import { login } from "../../store/actions";
import Layout from "../../components/layouts/Clean";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Divider,
  Snackbar,
  CircularProgress,
} from "@material-ui/core/";
import { AccountCircle, Visibility, VisibilityOff } from "@material-ui/icons/";
import Icon from "@material-ui/core/Icon";
import Alert from "@material-ui/lab/Alert";
import useStyles from "./styles";

const Login = ({ user, setLogin }) => {
  const classes = useStyles();
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(true);
  const [errorLogin, setErrorLogin] = useState(false);
  const [values, setValues] = useState({
    amount: "",
    password: "",
    username: "",
    weight: "",
    weightRange: "",
    code: "",
    showPassword: false,
  });

  useEffect(() => {
    if (user.isAuth) {
      history.push("/patients");
    } else {
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((user) => {
          setLogin(user);
          history.push("/patients");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function signIn() {
    setLoading(true);
    try {
      const user = await Auth.signIn(values.username, values.password);
      setLoading(false);
      setLogin(user);

      history.push("/patients");
    } catch (error) {
      if (error && error.code === "UserNotConfirmedException") {
        setConfirmed(false);
      } else {
        setErrorLogin(true);
        console.log("error signing in", error);
      }
      setLoading(false);
    }
  }

  async function verify() {
    setLoading(true);
    try {
      await Auth.confirmSignUp(values.username, values.code);
      const user = await Auth.signIn(values.username, values.password);
      setLoading(false);
      setLogin(user);
      history.push("/patients");
    } catch (error) {
      setLoading(false);
      console.log("error confirming sign up", error);
    }
  }

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorLogin(false);
  };

  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid
            container
            justify="center"
            className={classes.justifyCenterVertical}
          >
            <Typography
              component="h1"
              variant="h5"
              className={clsx(classes.title, classes.whiteText)}
            >
              VOYAGER
            </Typography>
          </Grid>

          <Typography className={classes.welcome}>{t("welcome")}</Typography>
          {confirmed ? (
            <>
              <Typography className={classes.welcomeSubtitle}>
                {t("welcomeSubtitle")}
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  placeholder={t("username")}
                  autoComplete="username"
                  className={classes.inputsLogin}
                  value={values.username}
                  onChange={handleChange("username")}
                  InputProps={{
                    classes: {
                      root: classes.inputUser,
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginTop: "0" }}
                      >
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                />
                <TextField
                  placeholder={t("password")}
                  autoComplete="current-password"
                  className={classes.inputsLogin}
                  value={values.password}
                  type={values.showPassword ? "text" : "password"}
                  onChange={handleChange("password")}
                  InputProps={{
                    classes: {
                      root: classes.inputPassword,
                    },
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginTop: "0" }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility className={classes.whiteText} />
                          ) : (
                            <VisibilityOff className={classes.whiteText} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="filled"
                />
                <Grid
                  container
                  direction="column"
                  justify="center"
                  className={classes.forgotPassword}
                >
                  <Grid item xs>
                    <Link
                      to="/forgot-password"
                      className={classes.textContactUs}
                    >
                      {t("forgotPassword?")}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Typography
                      href="#"
                      variant="body2"
                      className={classes.textContactUs}
                    >
                      {t("contactUs")}{" "}
                      <span className={classes.textContactEmailVy}>
                        {t("emailContactVoyager")}{" "}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.marginDivider} />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      className={classes.checkRememberMeColor}
                    />
                  }
                  className={classes.checkRememberMe}
                  label={t("rememberMe")}
                />
                {/*<Link style={{ textDecoration: 'none' }} to={`/patients`}>*/}
                <Grid container direction="column" alignItems="center">
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    onClick={signIn}
                    className={classes.btnPrimary}
                  >
                    {!loading ? (
                      <span className={classes.whiteText}>{t("login")}</span>
                    ) : (
                      <CircularProgress
                        size={25}
                        className={classes.colorWhite}
                      />
                    )}
                  </Button>
                </Grid>
                {/*</Link>*/}
                <Snackbar
                  open={errorLogin}
                  autoHideDuration={1000}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  onClose={closeSnackbar}
                >
                  <Typography
                    onClose={closeSnackbar}
                    color="info"
                    severity="error"
                  >
                    {t("wrongLogin")}
                  </Typography>
                </Snackbar>
              </form>
            </>
          ) : (
            <>
              <Typography className={classes.whiteText}>
                {t("notConfirmedSubtitle")}
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  label={t("code")}
                  className={clsx(classes.margin, classes.textField)}
                  value={values.code}
                  onChange={handleChange("code")}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.whiteText,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <CodeBracesBox className={classes.whiteText} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  className={classes.bgOrange}
                  onClick={verify}
                  className={classes.submit}
                >
                  {!loading ? (
                    <span className={classes.whiteText}>{t("verify")}</span>
                  ) : (
                    <CircularProgress size={25} style={{ color: "white" }} />
                  )}
                </Button>
              </form>
            </>
          )}
        </div>
      </Container>
    </Layout>
  );
};

//TODO asi deberia de ir el dispatch
const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) => dispatch(login(user)),
  };
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
