import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  marginPerGrid: {
    margin: -24,
    "& .MuiGrid-item": {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  formControl: {
    marginTop: 0,
    marginBottom: 0,
    minWidth: "100%",
    paddingTop: 12,
    paddingBottom: 12,
  },
  marginCircular: {
    margin: 10,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: "flex",
  },
  marginTop: {
    marginTop: 15,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  verticalAlignBlock: {
    display: "block",
    margin: "auto",
    padding: 10,
  },
  titleStyle: {
    color: "black",
    fontFamily: "Titillium Web",
    fontWeight: "bold",
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  displayContent: {
    display: "contents",
  },
  marginDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  marginPerGrid: {
    marginTop: -10,
  },
}));
export default useStyles;
