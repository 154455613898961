import { UPDATE_STATISTICS_DATA } from "../actionTypes";

const defaultState = {
  data: null,
};
const statisticsPlotData = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_STATISTICS_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default statisticsPlotData;
