import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  verticalAlign: {
    display: "flex",
    margin: "auto",
  },
  displayFlex: {
    display: "flex",
  },
  verticalAlignBlock: {
    display: "block",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  appBarStyle: {
    backgroundColor: "#FFF",
    borderRadius: "4px",
    borderBottom: "1px solid #D3DCE2",
    marginBottom: "15px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1), inset 0px 3px 0px #FA6A09",
  },
  titleStyle: {
    color: "black",
    fontFamily: "Titillium Web",
    fontWeight: "bold",
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  displayContent: {
    display: "contents",
  },
  marginDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: "100%",
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  marginPerGrid: {
    marginTop: theme.spacing(1),
  },
  whiteText: {
    color: "white",
  },
  marginCircular: {
    margin: 10,
  },
}));

export default useStyles;
