import React, { forwardRef, useImperativeHandle } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {
  Button,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  AppBar,
  Divider,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
import { CloseCircleOutline } from "mdi-material-ui";
import districtsJson from "../../constants/dummyData/districts.json";
//import Autocomplete from "@material-ui/core/Autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { createPatient, editPatient } from "../../services/fhir-api-services";
import { useTranslation } from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";
const { validate } = require("rut.js");
import useStyles from "./style";

let districts = [];
let regions = [];
districtsJson.regions.forEach((region) => {
  regions.push({
    name: region.name,
  });
  districts.push(
    ...region.communes.map((district) =>
      Object.assign(district, { state: region.name })
    )
  );
});

const PatientDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const genders = [
    { value: "male", text: t("male") },
    { value: "female", text: t("female") },
    { value: "unknown", text: t("unknown") },
    { value: "other", text: t("other") },
  ];

  const sexOptions = [
    { value: "male", text: t("male") },
    { value: "female", text: t("female") },
  ];

  const identifierTypes = [
    { value: "rut", text: t("rut") },
    { value: "passport", text: t("passport") },
  ];

  const [open, setOpen] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [isEdit, setEdit] = React.useState(false);

  // New Patient form values/ Edit Patient also

  const [patient, setPatientValue] = React.useState({
    firstName: "",
    lastName: "",
    birthDate: new Date(),
    address: "",
    identifier: "",
    identifierType: "",
    gender: "",
    genderNotEqualSex: false,
    sex: "",
    district: "",
    state: "",
    email: "",
    phoneNumber: "",
    organization: "",
  });

  const [errors, setError] = React.useState({
    firstName: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    lastName: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    identifier: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
      extraValidation: (obj, val) => {
        if (obj.identifierType === "rut") {
          return !validate(val);
        } else if (obj.identifierType === "passport") {
        }
        return false;
      },
      extraValidationMessage: "incorrectIdentifier",
    },
    identifierType: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    gender: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
    sex: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: false,
      extraValidation: (obj, val) => {
        if (obj.genderNotEqualSex) {
          return val == null || val === "";
        }
        return false;
      },
      extraValidationMessage: "mandatoryField",
    },
    email: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: false,
      extraValidation: (obj, val) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !re.test(val);
      },
      extraValidationMessage: "incorrectEmail",
    },
    phoneNumber: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: false,
      extraValidation: (obj, val) => {
        const re = /^[+]*[\s/0-9]{8,}$/;
        return !re.test(val);
      },
      extraValidationMessage: "incorrectPhoneNumber",
    },
    organization: {
      error: false,
      errorMessage: "",
      mandatoryMessage: "mandatoryField",
      mandatory: true,
    },
  });

  const handlePatientValueChange = (prop) => (event, value) => {
    let val = "";
    if (
      event &&
      event.target &&
      (event.target.value || event.target.value === "")
    ) {
      if (["true", "false"].includes(event.target.value)) {
        val = value;
        setPatientValue({ ...patient, [prop]: value });
      } else {
        val = event.target.value;
        setPatientValue({ ...patient, [prop]: event.target.value });
      }
    } else if (event instanceof Date) {
      val = event;
      setPatientValue({ ...patient, [prop]: event });
    } else if (value) {
      val = value.name;
      setPatientValue({ ...patient, [prop]: value.name });
    } else {
      val = event;
      setPatientValue({ ...patient, [prop]: event });
    }
    if (prop in errors) {
      if (
        val !== "" &&
        errors[prop].extraValidation &&
        errors[prop].extraValidation(patient, val)
      ) {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: true,
            errorMessage: t(errors[prop].extraValidationMessage),
          }),
        });
      } else {
        setError({
          ...errors,
          [prop]: Object.assign(errors[prop], {
            error: false,
            errorMessage: "",
          }),
        });
      }
    }
  };

  const validateForm = () => {
    let validate = true;

    Object.entries(errors).forEach((array, index) => {
      const key = array[0];
      const value = array[1];

      if (value.mandatory && (!patient[key] || patient[key] === "")) {
        validate = false;
        setError({
          ...errors,
          [key]: Object.assign(value, {
            error: true,
            errorMessage: t(value.mandatoryMessage),
          }),
        });
      }
    });

    return validate;
  };

  const [uploadingPatient, setUploadingPatient] = React.useState(false);

  const addPatient = () => {
    if (validateForm()) {
      let cOrg = organizations.find((org) => org.name === patient.organization);
      let pat = {
        given: patient.firstName,
        family: patient.lastName,
        gender: patient.gender,
        identifier: patient.identifier,
        identifierType: patient.identifierType,
        // "genderNotEqualSex": patient.genderNotEqualSex,
        address: patient.address,
        birthDate: patient.birthDate.toISOString().substr(0, 10),
        district: patient.district,
        state: patient.state,
        email: patient.email,
        phoneNumber: patient.phoneNumber,
        organization: {
          reference: `Organization/${cOrg.id}`,
          display: `${cOrg.name}`,
        },
        idHealthService: cOrg.partOf.identifier[0],
      };

      if (patient.genderNotEqualSex) {
        pat["sex"] = patient.sex;
      }

      setUploadingPatient(true);

      if (!isEdit) {
        createPatient(
          pat,
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            setUploadingPatient(false);
            props.reload();
            resetNewPatient();
            handleClose();
          })
          .catch((e) => {
            setUploadingPatient(false);
            console.log(e);
          });
      } else {
        pat.id = patient.id;
        editPatient(
          pat,
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            setUploadingPatient(false);
            props.reload();
            resetNewPatient();
            handleClose();
          })
          .catch((e) => {
            setUploadingPatient(false);
            console.log(e);
          });
      }
    }
  };

  const resetNewPatient = () => {
    setPatientValue({
      firstName: "",
      lastName: "",
      birthDate: new Date(),
      gender: "",
      address: "",
      identifier: "",
      identifierType: "",
      genderNotEqualSex: false,
      sex: "",
      district: "",
      state: "",
      email: "",
      phoneNumber: "",
      organization: "",
    });
  };

  useImperativeHandle(ref, () => ({
    openDialog(patient, user, isEdit, organizations) {
      setEdit(isEdit);
      setOpen(true);
      setUser(user);
      setOrganizations(organizations ? organizations : []);
      resetNewPatient();
      if (patient) {
        setPatientValue({
          id: patient.id || null,
          firstName: patient.given || "",
          lastName: patient.family || "",
          birthDate: patient.birthDate || new Date(),
          gender: patient.gender || "",
          genderNotEqualSex:
            patient.sex != null && patient.gender !== patient.sex,
          sex: patient.sex || "",
          address: patient.address || "",
          identifier: patient.identifier || "",
          identifierType: patient.identifierType || "",
          district: patient.district || "",
          state: patient.state || "",
          email: patient.email || "",
          phoneNumber: patient.phoneNumber || "",
          organization: patient.managingOrganization
            ? patient.managingOrganization.display
            : "",
        });
      }
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppBar
            position="static"
            className={classes.appBarStyle}
            elevation={0}
          >
            <Toolbar variant="dense">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={useStyles().displayContent}
              >
                <Grid container item={true} xs={"auto"}>
                  <Typography
                    component="h2"
                    variant="h6"
                    className={useStyles().titleStyle}
                  >
                    {isEdit ? t("edit-patient") : t("new-patient")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="flex-end"
                  className={useStyles().rightAlign}
                  item={true}
                  xs={"auto"}
                >
                  <IconButton
                    color="primary"
                    aria-label="close button"
                    onClick={handleClose}
                  >
                    <CloseCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              {isEdit
                ? " Si desea modificar los datos de este paciente, modifique los campos a continuación."
                : " Para crear un registro de paciente nuevo, complete el formulario a continuación con los datos de ingreso administrativo."}
            </DialogContentText>
            <Divider className={classes.marginDivider} />
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  value={patient.firstName}
                  onChange={handlePatientValueChange("firstName")}
                  id="firstname"
                  label={t("form-names")}
                  error={errors.firstName.error}
                  helperText={errors.firstName.errorMessage}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  value={patient.lastName}
                  onChange={handlePatientValueChange("lastName")}
                  error={errors.lastName.error}
                  helperText={errors.lastName.errorMessage}
                  id="lastname"
                  label={t("form-surnames")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.identifierType.error}
                >
                  <InputLabel htmlFor="outlined-identifier">
                    {t("identifier2")}
                  </InputLabel>
                  <Select
                    value={patient.identifierType}
                    onChange={handlePatientValueChange("identifierType")}
                    label={t("identifier2")}
                    inputProps={{
                      name: "Identificación",
                      id: "outlined-identifier",
                    }}
                  >
                    {identifierTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.identifierType.error && (
                    <FormHelperText>
                      {errors.identifierType.errorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={patient.identifier}
                  disabled={!patient.identifierType}
                  error={errors.identifier.error}
                  helperText={errors.identifier.errorMessage}
                  onChange={handlePatientValueChange("identifier")}
                  id="identifier"
                  label={t("identifier")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.gender.error}
                >
                  <InputLabel htmlFor="outlined-gender">
                    {t("gender")}
                  </InputLabel>
                  <Select
                    value={patient.gender}
                    onChange={handlePatientValueChange("gender")}
                    label={t("gender")}
                    inputProps={{
                      name: "Género",
                      id: "outlined-gender",
                    }}
                  >
                    {genders.map((gender) => (
                      <MenuItem key={gender.value} value={gender.value}>
                        {gender.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.gender.error && (
                    <FormHelperText>
                      {errors.gender.errorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={patient.genderNotEqualSex}
                      onChange={handlePatientValueChange("genderNotEqualSex")}
                      name="genderNotEqualSex"
                      color="secondary"
                    />
                  }
                  label={t("gender-not-equal-sex")}
                />
              </Grid>
            </Grid>

            {patient.genderNotEqualSex && (
              <Grid container spacing={3} className={classes.marginPerGrid}>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    error={errors.gender.error}
                  >
                    <InputLabel htmlFor="outlined-gender">
                      {t("biological-sex")}
                    </InputLabel>
                    <Select
                      value={patient.sex}
                      onChange={handlePatientValueChange("sex")}
                      label={t("biological-sex")}
                      inputProps={{
                        name: "Sexo Biológico",
                        id: "outlined-gender",
                      }}
                    >
                      {sexOptions.map((sex) => (
                        <MenuItem key={sex.value} value={sex.value}>
                          {sex.text}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.sex.error && (
                      <FormHelperText>{errors.sex.errorMessage}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label={t("birth-date")}
                  openTo="year"
                  format="dd/MM/yyyy"
                  className={clsx(classes.displayFlex)}
                  value={patient.birthDate}
                  onChange={handlePatientValueChange("birthDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>

            <Divider className={classes.marginDivider} />
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  value={patient.address}
                  onChange={handlePatientValueChange("address")}
                  id="address"
                  label={t("address")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={regions}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    value={
                      patient.state !== "" ? { name: patient.state } : null
                    }
                    onChange={handlePatientValueChange("state")}
                    noOptionsText={t("no-region-found")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={patient.state}
                        label={t("region")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={
                      patient.state
                        ? districts.filter((d) => d.state === patient.state)
                        : districts
                    }
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    value={
                      patient.district !== ""
                        ? { name: patient.district }
                        : null
                    }
                    onChange={handlePatientValueChange("district")}
                    noOptionsText={t("no-district-found")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={patient.district}
                        label={t("district")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  value={patient.email}
                  error={errors.email.error}
                  helperText={errors.email.errorMessage}
                  onChange={handlePatientValueChange("email")}
                  id="email"
                  label={t("email")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  value={patient.phoneNumber}
                  error={errors.phoneNumber.error}
                  helperText={errors.phoneNumber.errorMessage}
                  onChange={handlePatientValueChange("phoneNumber")}
                  id="phoneNumber"
                  label={t("phone")}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Autocomplete
                    options={organizations}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    value={
                      patient.organization !== ""
                        ? { name: patient.organization }
                        : null
                    }
                    onChange={handlePatientValueChange("organization")}
                    noOptionsText={t("noOrganizations")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.organization.error}
                        helperText={errors.organization.errorMessage}
                        value={patient.organization}
                        label={t("organization")}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {uploadingPatient ? (
              <CircularProgress className={classes.marginCircular} />
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addPatient}
                >
                  <span className={classes.whiteText}>{t("save")}</span>
                </Button>
              </div>
            )}
          </DialogActions>
        </MuiPickersUtilsProvider>
      </Dialog>
    </>
  );
});

export default PatientDialog;
