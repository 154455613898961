import React from "react";

import { TextField, InputAdornment } from "@material-ui/core";

const FilterComponent = ({
  filterText,
  onFilter,
  filterLabelText,
  AccountSearch,
}) => (
  <TextField
    id="search"
    type="text"
    margin="dense"
    placeholder={filterLabelText}
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountSearch />
        </InputAdornment>
      ),
    }}
  />
);

export default FilterComponent;
