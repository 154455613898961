import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  previousStep,
  updatePatientEncounterDetails,
} from "../../store/actions";
import { searchEncounter } from "../../services/fhir-api-services";

const useAppointmentDetails = (
  appointment,
  appointments,
  user,
  practitioners,
  organizations,
  dispatch,
  id
) => {
  let history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchEncounterById = (id) => {
    if (user.isAuth) {
      searchEncounter(
        id,
        user.signInUserSession.idToken.jwtToken,
        user.signInUserSession.accessToken.jwtToken
      )
        .then((response) => {
          dispatch(
            updatePatientEncounterDetails(
              response.data.conclusion !== ""
                ? JSON.parse(response.data.conclusion)
                : {}
            )
          );
        })
        .catch((e) => {
          dispatch(updatePatientEncounterDetails({}));
        });
    }
  };
  if (!appointment) {
    searchEncounterById(id);
  }
  console.log(id);
  const events = {
    handleChange,
    searchEncounterById,
  };

  return [expanded, setExpanded, events];
};

export default useAppointmentDetails;
