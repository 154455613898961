import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    zIndex: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  fabProgress: {
    position: "absolute",
    zIndex: 1,
    color: "#0999FA",
  },
  buttonAudio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  record: {
    fontSize: "15px",
    fontWeight: "700",
    fontFamily: "Mulish",
  },
}));

export default useStyles;
