import React, { useEffect, useRef, useContext } from "react";
import DataTable from "react-data-table-component";
import * as moment from "moment";
import { connect } from "react-redux";
import useStyles, { BorderLinearProgress, customStyles } from "./styles";
import "react-h5-audio-player/lib/styles.css";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import { PencilOutline } from "mdi-material-ui";
import AddIcon from "@material-ui/icons/Add";
import UserForm from "../../components/forms/UserForm";
import {
  loadOrganizations,
  loadPractitioners,
  loadUsers,
} from "../../services/utils/load-data-services";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTranslation } from "react-i18next";
import FilterUsers from "../../components/FilterUsers";
import GlobalContext from "../../context";
import Layout from "../../components/layouts/Base";
const getData = (state) => ({
  user: state.user,
  users: state.users,
  organizations: state.organizations,
  practitioners: state.practitioners,
});

const Users = ({ user, users, dispatch, organizations, practitioners }) => {
  const classes = useStyles();
  const dialogRef = useRef();
  const { t, i18n } = useTranslation();

  const [filterText, setFilterText] = React.useState("");
  const [styles, setStyles] = React.useState({
    buttonPrimaryStyle: useStyles().button,
    buttonPrimarySpanStyle: useStyles().spanButton,
    buttonSecondaryStyle: useStyles().buttonOtherColor,
    buttonSecondarySpanStyle: useStyles().spanButton,
  });

  useEffect(() => {
    if (!users) {
      loadUsers(user, dispatch);
    }

    if (!organizations) {
      loadOrganizations(user, dispatch);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }
  }, [user]);

  const handleClickOpen = (nUser, isEdit) => {
    dialogRef.current.openDialog(
      nUser,
      user,
      isEdit,
      organizations,
      practitioners
    );
  };

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const subHeaderComponentMemo = (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={useStyles().displayContent}
    >
      <Grid container alignItems="flex-start" item={true} xs={6}>
        {(!organizations || !user) && (
          <>
            <Skeleton width={141} height={59} style={{ marginRight: 10 }} />
          </>
        )}
        {organizations && user && (
          <>
            <Button
              className={styles.buttonSecondaryStyle}
              classes={{ endIcon: classes.buttonAddStyle }}
              style={{ borderColor: "#346DFF" }}
              disableElevation
              variant="outlined"
              endIcon={<AddIcon />}
              onClick={() => handleClickOpen(null, false)}
            >
              <span
                className={styles.buttonSecondarySpanStyle}
                style={{ color: "#346DFF" }}
              >
                {t("new-user")}
              </span>
            </Button>
          </>
        )}
      </Grid>
      <Grid container item={true} xs={6} className={useStyles().rightAlign}>
        <FilterUsers
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          filterLabelText={t("filter-label-text")}
        />
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: t("name"),
      selector: "name",
      sortable: true,
    },
    {
      name: t("users-username"),
      selector: "username",
      sortable: true,
      hide: "sm",
    },
    {
      name: t("email"),
      selector: "email",
      sortable: true,
      right: true,
      hide: "sm",
    },
    {
      name: t("role"),
      selector: "role",
      sortable: true,
      right: true,
      hide: "md",
    },
    {
      name: t("organization"),
      selector: "organization",
      sortable: true,
      right: true,
      hide: "lg",
    },
    {
      name: t("actions"),
      cell: (a) => (
        <Grid container className={classes.centerVertically}>
          <IconButton
            disabled={!users || !organizations}
            size="small"
            style={{ backgroundColor: "#E6ECF2" }}
            onClick={() => handleClickOpen(a, true)}
          >
            <PencilOutline color="secondary" />
          </IconButton>
        </Grid>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: false,
    },
  ];

  const filteredItems = users
    ? users.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.username &&
            item.username.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.role &&
            item.role.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.organization &&
            item.organization.toLowerCase().includes(filterText.toLowerCase()))
      )
    : [];

  const currentUser =
    user && users ? users.find((u) => u.username === user.username) : null;
  return (
    <>
      <Layout>
        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-area")}:{" "}
                {currentUser
                  ? t(currentUser.practitioner)
                  : t("user-not-defined")}
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-medical-center")}:{" "}
                {currentUser ? currentUser.organization : t("user-not-defined")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography variant="h5" gutterBottom>
                <b>
                  {currentUser
                    ? currentUser.name || currentUser.username
                    : t("user-no-name")}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography variant="h7" gutterBottom>
                {moment().locale("es").format("llll")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <DataTable
          noHeader
          subHeader
          striped
          persistTableHead
          pagination
          paginationComponentOptions={{
            rowsPerPageText: t("elements-per-page"),
            rangeSeparatorText: t("range-separator-text"),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: t("select-all-rows"),
          }}
          noDataComponent={t("loading-users")}
          subHeaderComponent={subHeaderComponentMemo}
          subHeaderAlign="left"
          columns={columns}
          customStyles={customStyles}
          data={filteredItems}
        />
        {!users ? <BorderLinearProgress /> : null}
        <UserForm
          ref={dialogRef}
          reload={() => {
            loadUsers(user, dispatch);
            loadPractitioners(user, dispatch);
          }}
        />
      </Layout>
    </>
  );
};

export default connect(getData)(Users);
