import React, { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import ReactECharts from "echarts-for-react";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import "react-h5-audio-player/lib/styles.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { AccountSearch } from "mdi-material-ui";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { loadExamTypes } from "../../services/utils/load-data-services";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/locale/es";
import { Tab, Tabs } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button";
//import Autocomplete from "@material-ui/core/Autocomplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import { getExam } from "../../services/fhir-api-services";
import { updatePatientExams } from "../../store/actions";
import useStyles from "./style";

import {
  structureData,
  structureMeanData,
  structureMedsData,
  plotExam,
} from "./../patient_exams_plots/Utils.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const getData = (state) => ({
  user: state.user,
  users: state.users,
  patients: state.patients,
  exams: state.patientExams,
  types: state.patientExams,
});

// Other //////////

const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
  <TextField
    id="search"
    type="text"
    margin="dense"
    placeholder={filterLabelText}
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountSearch />
        </InputAdornment>
      ),
    }}
  />
);

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#F1F8FF",
      borderBottom: "2px solid",
      borderColor: "#FA6A09",
    },
  },
};

///////////////////

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    backgroundColor: "#e0e0e0",
  },
}))(LinearProgress);

const PatientExams = ({ user, users, exams, types, patients, dispatch }) => {
  const classes = useStyles();
  let history = useHistory();
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tryLoading, setTryLoading] = useState(false);
  const [filterText, setFilterText] = useState("");

  const [plotData, setPlotData] = useState({});

  const [plotReady, setPlotReady] = useState(false);

  const [examType, setExamType] = useState({
    type: "41653-7", // LOINC glucometro
  });

  const handleChangeExamType = (prop) => (event, value) => {
    setPlotReady(false);
    if (
      event &&
      event.target &&
      (event.target.value || event.target.value === "")
    ) {
      setExamType({ ...examType, [prop]: event.target.value });
    } else if (value) {
      setExamType({ ...examType, [prop]: value.code });
    } else {
      setExamType({ ...examType, [prop]: event });
    }
  };

  if (!patients) {
    history.push("/patients");
  }

  useEffect(() => {
    if (!types) {
      loadExamTypes(user, dispatch);
    }
  }, [user, exams]);

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const subHeaderComponentMemo = (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={useStyles().displayContent}
    >
      <Grid container alignItems="flex-start" item={true} xs={6}></Grid>
      <Grid container item={true} xs={6} className={useStyles().rightAlign}>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          filterLabelText={t("filter-label-text")}
        />
      </Grid>
    </Grid>
  );

  const columns = [
    {
      name: t("date"),
      selector: "date",
      sortable: true,
    },
    {
      name: t("value"),
      selector: "value",
      sortable: true,
      right: true,
    },
    {
      name: t("measurement"),
      selector: "unit",
      sortable: true,
      right: true,
      hide: "sm",
    },
  ];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  const loadExams = () => {
    if (types && user.isAuth) {
      setLoading(true);
      let examsArray = [];
      getExam(
        id,
        types.find((t) => t.code === examType.type).code,
        user.signInUserSession.idToken.jwtToken,
        user.signInUserSession.accessToken.jwtToken
      )
        .then((response) => {
          var dict = {};

          if (response.data.exam_data) {
            response.data.exam_data.forEach((exam) => {
              examsArray.push({
                date: moment.utc(exam.date).format("YYYY/MM/DD HH:mm:ss a"),
                unit: exam.unit,
                value: exam.value,
              });
            });

            dict.exam_data = structureData(response.data.exam_data);
          }

          if (response.data.weekly_mean) {
            dict.weekly_mean = structureMeanData(response.data.weekly_mean);
          }

          if (response.data.meds) {
            dict.meds = structureMedsData(response.data.meds);
          }

          setPlotData(dict);

          if (dict.exam_data.length > 1) {
            setPlotReady(true);
          }

          dispatch(updatePatientExams(examsArray));
          setTryLoading(true);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          dispatch(updatePatientExams(examsArray));
          setLoading(false);
        });
    }
  };

  const filteredItems = exams
    ? exams.filter(
        (item) =>
          item.value && item.value.toString().includes(filterText.toLowerCase())
      )
    : [];

  const currentUser =
    user && users ? users.find((u) => u.username === user.username) : null;

  return (
    <>
      <div>
        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-area")}:{" "}
                {currentUser
                  ? t(currentUser.practitioner)
                  : t("user-not-defined")}
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-medical-center")}:{" "}
                {currentUser ? currentUser.organization : t("user-not-defined")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography variant="h5" gutterBottom>
                <b>
                  {currentUser
                    ? currentUser.name || currentUser.username
                    : t("user-no-name")}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography variant="h7" gutterBottom>
                {moment().locale("es").format("llll")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t("data")} {...a11yProps(0)} />
          <Tab label={t("analysis")} {...a11yProps(1)} />
        </Tabs>

        <Grid
          item
          xs={"auto"}
          className={clsx(
            classes.verticalAlignBlock,
            classes.paddingTopExamSelect
          )}
        >
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={clsx(classes.formControl)}
              >
                <Autocomplete
                  options={types ? types : []}
                  getOptionLabel={(option) => option.name}
                  disableClearable
                  getOptionSelected={(option, value) =>
                    option.code === value.code
                  }
                  onChange={handleChangeExamType("type")}
                  getOptionLabel={(option) =>
                    !option.name ? t("exams-hemogluco-name") : option.name
                  }
                  defaultValue="41653-7" // LOINC glucómetro
                  noOptionsText={t("patient-appointment-no-exam-found")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={examType.type}
                      label={t("exam")}
                      variant="outlined"
                    />
                  )}
                ></Autocomplete>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.displayCenterFlexEnd}>
              {loading ? (
                <CircularProgress className={classes.marginCircular} />
              ) : (
                <div>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => loadExams()}
                  >
                    <span className={classes.whiteText}>{t("load-exams")}</span>
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
          <TabPanel value={tab} index={0}>
            <DataTable
              noHeader
              subHeader
              striped
              persistTableHead
              responsive
              pagination
              paginationComponentOptions={{
                rowsPerPageText: t("elements-per-page"),
                rangeSeparatorText: t("range-separator-text"),
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: t("select-all-rows"),
              }}
              noDataComponent={
                tryLoading
                  ? t("patient-appointment-no-exam-found")
                  : t("patient-appointment-no-exam-loaded")
              }
              subHeaderComponent={subHeaderComponentMemo}
              subHeaderAlign="left"
              columns={columns}
              customStyles={customStyles}
              data={filteredItems}
            />
            {!types ? <BorderLinearProgress /> : null}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {!plotReady ? (
              <>
                <Skeleton
                  animation="wave"
                  height={1000}
                  style={{ marginTop: -150 }}
                />
              </>
            ) : (
              <ReactECharts
                option={plotExam(plotData, examType, t)}
                style={{ marginTop: 20, height: 600 }}
              />
            )}
          </TabPanel>
        </SwipeableViews>
      </div>
    </>
  );
};

export default connect(getData)(PatientExams);
