import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "min-height": "100vh",
    background:
      "linear-gradient(200.06deg, #09FAE3 -11.16%, #0999FA 26.16%, #021842 75.98%)",
  },
  content: {
    flexGrow: 1,
    height: "100%",
    maxWidth: "100%",
    paddingTop: 10,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    container: {
      "padding-left": 10,
      "padding-right": 10,
    },
  },
}));

export default useStyles;
