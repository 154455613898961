import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Saira'
  },
  overrides: {
    MuiPickersModal: {
      dialogAction: {
        color: "secondary",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {

        }
      }
    }
  },
  palette: {
    primary: {
      main: '#000',
    },
    black: {
      main: '#000',
    },
    accent: {
      main: '#FA6A09',
    },
    secondary: {
      main: '#346DFF',
    },
    white: {
      main: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },

});

export default theme;
