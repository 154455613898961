import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  bgOrange: {
    backgroundColor: "#FA6A09",
  },
  bgWhite: {
    backgroundColor: "#FFF",
  },
  noPadding: {
    padding: "0 !important",
  },
  noBorder: {
    border: "none",
    "padding-bottom": 0,
  },
  marginTopTextField: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    "justify-content": "flex-end",
  },
  textAreaTitle: {
    "font-size": 18,
    "font-weight": 500,
    "text-align": "left",
  },
  resultSubtitle: {
    "margin-top": theme.spacing(1),
    "font-size": 15,
    "font-weight": 500,
    "text-align": "left",
  },
  resultTitle: {
    "margin-top": theme.spacing(1),
    "font-size": 16,
    "font-weight": 500,
    "text-align": "left",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  buttonClass: {
    "justify-content": "flex-end",
    "margin-bottom": "auto",
    "margin-top": "auto",
    "align-items": "center",
    display: "block",
  },
  justifySpaceBetween: {
    "margin-top": theme.spacing(6),
    "margin-bottom": theme.spacing(3),
    display: "flex",
    "justify-content": "space-between",
  },
  iconTransparent: {
    color: "transparent",
    marginRight: "10px",
  },
  fontValueSize: {
    fontSize: "18px",
  },
  iconTitleData: {
    color: "#021842",
    marginRight: "10px",
  },
  fontTitleData: {
    fontSize: "16px",
    color: "#021842",
    fontWeight: 600,
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  appBar: {
    backgroundColor: "#F1F8FF",
    paddingBottom: "2px",
  },
  toolBar: {
    backgroundColor: "#FFF",
    borderRadius: "0px 0px 20px 20px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  toolBarSecondary: {
    backgroundColor: "#F1F8FF",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderRadius: "0px 0px 20px 20px",
  },
  accordionDetails: {
    background: "#F1F8FF",
    marginBottom: "15px",
    border: "1px solid rgba(0, 0, 0, .125)",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

export const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
  },
}))(MuiAccordionDetails);

export default useStyles;
