import {
  UPDATE_PATIENT_APPOINTMENTS,
  ADD_PATIENT_APPOINTMENT,
} from "../actionTypes";

const defaultState = {
  appointments: null,
};
const patientAppointments = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_PATIENT_APPOINTMENTS:
      return payload;
    case ADD_PATIENT_APPOINTMENT:
      return payload;
    // {
    //   ...state,
    //   appointments: state.appointments
    //     ? state.appointments.push(payload.appointment)
    //     : [payload.appointment],
    // };
    default:
      return state;
  }
};

export default patientAppointments;
