import { UPDATE_MEDICAL_RECORD } from "../actionTypes";

const defaultState = {
  patient_birthDate: "",
  organization_name: "",
  patient_gender: "",
  exams: {},
  last_encounter: "",
  patient_id: "",
  id: null,
};

const patientMedicalRecord = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_MEDICAL_RECORD:
      return payload;
    default:
      return state;
  }
};
// ...state,
// medicalRecord: payload !== {} ? payload : defaultState,
export default patientMedicalRecord;
