import { Toolbar, AppBar, Paper, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
const TitleSteepAppointment = ({ title, practitioner }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.appBarAttention}>
      <Paper className={classes.toolbarAttention}>
        <Grid container direction="row" className={classes.contentAttention}>
          <Grid item xs={2}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              className={classes.namePractitioner}
            >
              {t("new-attention")}: <br /> <b>{practitioner}</b>
              {console.log(practitioner)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            style={{ position: "relative", left: "20%", top: "20px" }}
          >
            <Typography
              style={{
                color: "#021842",
                fontSize: "18px",
                fontWeight: "500",
              }}
              gutterBottom
            >
              {" "}
              {title}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default TitleSteepAppointment;
