import { LOGIN, LOGOUT } from "../actionTypes";
const defaultState = {
  isAuth: false,
  username: "",
  pool: {
    userPoolId: "",
    clientId: "",
    client: {
      endpoint: "",
      fetchOptions: {},
    },
    advancedSecurityDataCollectionFlag: true,
  },
  Session: null,
  client: {
    endpoint: "",
    fetchOptions: {},
  },
  signInUserSession: {
    idToken: {
      jwtToken: "",
      payload: {
        "custom:organization": "",
        sub: "",
        "cognito:groups": [""],
        iss: "",
        "cognito:username": "",
        aud: "",
        event_id: "",
        token_use: "",
        auth_time: 0,
        "custom:practitioner_id": "",
        "custom:practitioner": "",
        exp: 0,
        "custom:role": "",
        iat: 0,
      },
    },
    refreshToken: {
      token: "",
    },
    accessToken: {
      jwtToken: "",
      payload: {
        sub: "",
        "cognito:groups": [""],
        event_id: "",
        token_use: "",
        scope: "",
        auth_time: 0,
        iss: "",
        exp: 0,
        iat: 0,
        jti: "",
        client_id: "",
        username: "",
      },
    },
    clockDrift: 0,
  },
  authenticationFlowType: "",
  keyPrefix: "",
  userDataKey: "",
  attributes: {
    "custom:organization": "",
    sub: "",
    "custom:practitioner_id": "",
    "custom:practitioner": "",
    "custom:role": "",
  },
  preferredMFA: "",
};

const user = (state = defaultState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return {
        ...payload,
        isAuth: true,
      };
    case LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default user;
