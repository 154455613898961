import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import {
  loadExpertSystem,
  loadPatientMedicalRecord,
  loadPractitioners,
} from "../.././../services/utils/";
import { login } from "../../../store/actions";

const useBaseLayout = (
  dispatch,
  patients,
  expertSystem,
  practitioners,
  user,
  medicalRecord
) => {
  const [open, setOpen] = useState(false);
  const [loadingMedicalRecord, setLoadingMedicalRecord] = useState(false);
  const { t } = useTranslation();
  let { idPatient } = useParams();
  const classes = useStyles();

  useEffect(() => {
    if (!user) {
      Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then((user) => {
        dispatch(login(user));
      });
    }
  }, []);

  useEffect(() => {
    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }
  }, [user, practitioners]);

  useEffect(() => {
    if (!expertSystem && idPatient) {
      loadExpertSystem(idPatient, user, dispatch);
    }
  }, [user, expertSystem]);

  useEffect(() => {
    if (idPatient) {
      loadPatientMedicalRecord(idPatient, user, dispatch);
    }
  }, [user]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const role =
    user &&
    user.signInUserSession &&
    user.signInUserSession.idToken &&
    user.signInUserSession.idToken.payload &&
    user.signInUserSession.idToken.payload["custom:role"]
      ? user.signInUserSession.idToken.payload["custom:role"]
      : "practitioner";

  return [open, handleDrawerOpen, t, classes, role];
};

export default useBaseLayout;
