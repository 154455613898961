import React, { useEffect } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

import Patients from "./pages/Patients";
import Organizations from "./pages/Organizations";
import PatientAppointments from "./pages/PatientAppointments";
import AppointmentForm from "./pages/appointment_form/AppointmentForm";
import Login from "./pages/Login";
import AppointmentDetails from "./pages/AppointmentDetails";
import NotFound from "./pages/NotFound";
import ForgotPassword from "./pages/ForgotPassword";
import Users from "./pages/Users";
import ChangePassword from "./pages/ChangePassword";
import PatientExams from "./pages/PatientExams";
import MedicalRecord from "./pages/MedicalRecord";
import SearchPatient from "./pages/SearchPatient";
import Profile from "./pages/Profile";
import Statistics from "./pages/Statistics";

const Routes = ({ children }) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" children={<Login />} />
        <Route exact path="/forgot-password" children={<ForgotPassword />} />
        <Route exact path="/login" children={<Login />} />
        <Route exact path="/change-password" children={<ChangePassword />} />
        <Route exact path="/patients" children={<Patients />} />
        <Route
          exact
          path="/patient/:idPatient"
          children={<PatientAppointments />}
        />
        <Route
          exact
          path="/patient/:idPatient/exams/"
          children={<PatientExams />}
        />
        <Route exact path="/organizations" children={<Organizations />} />
        <Route exact path="/users" children={<Users />} />
        <Route exact path="/search-patient" children={<SearchPatient />} />
        <Route exact path="/profile" children={<Profile />} />
        <Route exact path="/statistics" children={<Statistics />} />
        <Route
          exact
          path="/patient/:idPatient/appointment-form/:idForm"
          children={<AppointmentForm />}
        />
        <Route exact path="/medical-record/:id" children={<MedicalRecord />} />
        <Route
          exact
          path="/appointment-details/:id"
          children={<AppointmentDetails />}
        />
        <Route path="/*" children={<NotFound />} />
      </Switch>
      {children}
    </Router>
  );
};

export default Routes;
