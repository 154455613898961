import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: "flex",
  },
  marginTop: {
    marginTop: 15,
  },
  displayHidden: {
    display: "none",
  },
  centerVertically: {
    display: "flex",
    "align-items": "center",
    margin: "auto",
    "justify-content": "center",
    "flex-wrap": "nowrap",
  },
  centerOnlyVertically: {
    display: "flex",
    margin: "auto",
    "align-items": "center",
    "flex-wrap": "nowrap",
  },
  marginCircular: {
    margin: 10,
  },

  verticalAlignBlock: {
    display: "block",
    margin: "auto",
    padding: 10,
  },
  paddingTopExamSelect: {
    paddingTop: 25,
  },
  button: {
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonOtherColor: {
    borderColor: "#346DFF",
    backgroundColor: "#E6ECF2",
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  displayContent: {
    display: "contents",
  },
  spanButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  "MuiButton-outlinedPrimary:hover": {
    /* border: 1px solid #333333; */
    "background-color": "rgba(51, 51, 51, 0.04)",
  },
  backButton: {
    "padding-left": 0,
    "padding-right": 0,
    "margin-right": 10,
  },
  titleStyle: {
    color: "black",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  displayCenterFlexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  formControl: {
    minWidth: "100%",
  },
  buttonAddStyle: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
}));

export default useStyles;
