import {
  NEW_STEP,
  NEXT_STEP,
  PREVIOUS_STEP,
  LOAD_APPOINTMENT,
  SAVE_PATIENT,
  SAVE_AUDIO,
  SAVE_PHARMACOLOGICAL_INTERACTIONS,
} from "../actionTypes";

const defaultState = {
  step: 0,
  appointment: {},
  interactions: null,
};

const appointmentStep = (state = defaultState, { type, payload }) => {
  let appointment = {};

  switch (type) {
    case NEW_STEP:
      console.log("newstep", payload);

      return {
        ...state,
      };
    case NEXT_STEP:
      console.log("NEXT_STEP", payload);
      return payload;
    // {
    //   ...state,
    //   step: state.step < 7 ? state.step + 1 : 7,
    // };
    case PREVIOUS_STEP:
      return {
        ...state,
        step: state.step > 0 ? state.step - 1 : 0,
      };
    case LOAD_APPOINTMENT:
      console.log("LOAD_APPOINTMENT", payload);
      return payload;
    // let nState = JSON.parse(JSON.stringify(payload));

    // Object.keys(nState).forEach((key) => (nState[key].loaded = true));
    // return {
    //   ...state,
    //   appointment: nState,
    // };
    case SAVE_PATIENT:
      if (payload.step in state.appointment) {
        appointment[payload.step] = Object.assign(
          state.appointment[payload.step],
          payload.appointment
        );
      } else {
        appointment[payload.step] = payload.appointment;
      }

      appointment[payload.step].step = state.step;
      return {
        ...state,
        appointment: Object.assign(state.appointment, appointment),
      };
    case SAVE_AUDIO:
      appointment[payload.step] = {
        audioFile: payload.audio,
      };
      return {
        ...state,
        appointment: Object.assign(state.appointment, appointment),
      };

    case SAVE_PHARMACOLOGICAL_INTERACTIONS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default appointmentStep;
