import {
  NEXT_STEP,
  PREVIOUS_STEP,
  NEW_STEP,
  LOAD_APPOINTMENT,
  ADD_PATIENT_APPOINTMENT,
  SAVE_PATIENT,
  SAVE_AUDIO,
  LOGIN,
  LOGOUT,
  UPDATE_PATIENTS,
  UPDATE_PATIENT_ID,
  UPDATE_ORGANIZATIONS,
  UPDATE_USERS,
  UPDATE_PRACTITIONERS,
  UPDATE_PATIENT_APPOINTMENTS,
  UPDATE_PATIENT_ENCOUNTER_DETAILS,
  SAVE_PHARMACOLOGICAL_INTERACTIONS,
  UPDATE_EXPERT_SYSTEM,
  UPDATE_PATIENT_EXAMS,
  UPDATE_EXAM_TYPES,
  UPDATE_MEDICAL_RECORD,
  UPDATE_STATISTICS_DATA,
} from "../actionTypes";

export const nextStep = () => ({
  type: NEXT_STEP,
});

export const previousStep = () => ({
  type: PREVIOUS_STEP,
});

export const initAppointment = () => ({
  type: NEW_STEP,
});

export const loadAppointmentFromJSON = (data) => ({
  type: LOAD_APPOINTMENT,
  payload: data,
});

export const addPatientAppointment = (appointment) => ({
  type: ADD_PATIENT_APPOINTMENT,
  payload: appointment,
});

export const login = (user) => ({
  type: LOGIN,
  payload: user,
});

export const logout = () => ({
  type: LOGOUT,
});

export const updatePatients = (patients) => ({
  type: UPDATE_PATIENTS,
  payload: patients,
});

export const updatePatientId = (patientId) => ({
  type: UPDATE_PATIENT_ID,
  payload: patientId,
});

export const updateOrganizations = (organizations) => ({
  type: UPDATE_ORGANIZATIONS,
  payload: organizations,
});

export const updateUsers = (users) => {
  return {
    type: UPDATE_USERS,
    payload: users,
  };
};

export const updatePractitioners = (practitioners) => ({
  type: UPDATE_PRACTITIONERS,
  payload: practitioners,
});

export const updatePatientAppointments = (appointments) => ({
  type: UPDATE_PATIENT_APPOINTMENTS,
  payload: appointments,
});

export const updatePatientEncounterDetails = (appointment) => ({
  type: UPDATE_PATIENT_ENCOUNTER_DETAILS,
  payload: appointment,
});

export const updatePharmacologicalInteractions = (interactions) => ({
  type: SAVE_PHARMACOLOGICAL_INTERACTIONS,
  payload: interactions,
});

export const updateExpertSystem = (expertSystem) => ({
  type: UPDATE_EXPERT_SYSTEM,
  payload: expertSystem,
});

export const updatePatientExams = (exams) => ({
  type: UPDATE_PATIENT_EXAMS,
  payload: exams,
});

export const updateExamTypes = (types) => ({
  type: UPDATE_EXAM_TYPES,
  payload: types,
});

export const updatePatientMedicalRecord = (medicalRecord) => ({
  type: UPDATE_MEDICAL_RECORD,
  payload: medicalRecord,
});

export const updateStatisticsData = (data) => ({
  type: UPDATE_STATISTICS_DATA,
  payload: data,
});

export const savePatientData = (data) => ({
  type: SAVE_PATIENT,
  payload: data,
});

export const saveAudioStep = (data) => ({
  type: SAVE_AUDIO,
  payload: data,
});

// export const savePatientData = (data) => ({
//   type: SAVE_PATIENT,
//   data: data.appointment,
//   step: data.step,
// });

// export const saveAudioStep = (data) => ({
//   type: SAVE_AUDIO,
//   audio: data.audio,
//   step: data.step,
// });
