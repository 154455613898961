import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  w50: {
    width: "50%" 
  },
  w30: {
    width: "30%" 
  },
  w20: {
    width: "20%" 
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: "flex",
  },
  marginTop: {
    marginTop: 15,
  },
  displayHidden: {
    display: "none",
  },
  centerVertically: {
    display: "flex",
    "align-items": "center",
    margin: "auto",
    "justify-content": "center",
  },
  marginCircular: {
    margin: 10,
  },
  paper: {
    padding: "6px 16px",
  },
  marginBottom: {
    marginBottom: 5,
  },
  verticalAlign: {
    display: "flex",
    alignItems: "center",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  noPadding: {
    padding: 0,
  },
  noRightPadding: {
    "padding-right": 0,
  },
  borderRadiusAvatar: {
    borderRadius: "8px 0px 8px 0px",
  },
  extraLargeAvatar: {
    width: "16vw",
    minWidth: 160,
    minHeight: 100,
    height: "25vh",
  },
  cardClass: {},
  spanButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonAddStyle: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  paddingTop3: {
    paddingTop: theme.spacing(2),
  },
  badgeProfile: {
    transform: "scale(1) translate(10%, 0%)",
    height: "inherit",
  },
  bgHeaderPatient: {
    background: "#F1F8FF",
    padding: 0,
    borderRadius: "0px 0px 20px 20px",
  },
  cardHeaderPatient: {
    background: "#F1F8FF",
    border: 0,
  },
  avatarHeaderPatient: {
    width: "90px",
    height: "114px",
    minWidth: "90px",
    border: "3px solid transparent",
    background: "#0C2975",
  },
  buttonCircular: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    backgroundColor: "#346DFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: "#346DFF",
    },
  },
  paperInformation: {
    minHeight: "42px",
    minWidth: "42px",
    width: "42px",
    height: "42px",
    borderRadius: "50%",
    backgroundColor: "#25AB22",
    boxShadow: "0px 2px 10px rgba(247, 68, 68, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "7px",
    "&:hover": {
      backgroundColor: "#25AB22",
    },
  },
  badgeBlue: {
    width: "50px",
    height: "23px",
    backgroundColor: "#C2E0FF",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    marginLeft: "10px",
  },
  dropdownInfoComplications: {
    borderRadius: "5px",
    border: "1px solid #FAC509",
    color: "#021842",
    width: "100px",

    marginTop: "6px",
  },
  itemTitleInfoComplications: {
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "9px",
    paddingTop: "0px",
    fontSize: "13px",
  },
  lineBlue: {
    height: "1px",
    width: "100%",
    backgroundColor: "#021842",
    display: "block",
  },
  circularProgress: {
    color: "white",
    padding: "4px",
    borderRadius: "30px",
    borderWidth: "",
    background: "transparent",
  },
  iconAddProfilePic: {
    color: "white",
    padding: "4px",
    borderRadius: "8px 0px",
    background: "#0C2975",
    fontSize: 25,
  },
  textItem: {
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#021842",
  },
  contentTextItem:{
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  textItemName: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  textContent: {
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "600",
    color: "#021842",
    width: "100%",
  },
  dividerOrange: {
    background: "#FA6A09",
    marginBottom: "10px",
    marginTop: "5px",
  },
  textBlue13W500:{
    color: "#032D60",
    fontWeight: "500",
    fontSize: "13px",
  },
  textBlue13W400:{
    color: "#032D60",
    fontWeight: "400",
    fontSize: "13px",
  },
  textBlue16W600:{
    color: "#032D60",
    fontWeight: "600",
    fontSize: "16px",
  }
}));

export default useStyles;
