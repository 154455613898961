import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, TextField, InputAdornment, Button } from "@material-ui/core";
import { AccountPlus, AccountSearch, MicrophonePlus } from "mdi-material-ui";
import useStyles from "./style"
const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
  <TextField
    id="search"
    type="text"
    margin="dense"
    placeholder={filterLabelText}
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountSearch />
        </InputAdornment>
      ),
    }}
  />
);

const PatientSubHeader = ({
  organizations,
  patients,
  user,
  handleClear,
  filterText,
  setFilterText,
  t,
  appointmentRef,
  styles,
  practitioners,
  dialogRef,
  handleClickOpen,
}) => {
  const classes = useStyles();

  const openNewAppointment = () => {
    appointmentRef.current.openDialog(
      user,
      organizations,
      patients,
      practitioners
    );
  };

  return (
    <Grid className={classes.verticalAlignBlock}>
      <div className={classes.spaceBetween}>
        <div>
          {(!organizations || !patients || !user) && (
            <>
              <Skeleton width={141} height={59} style={{ marginRight: 10 }} />
              <Skeleton width={141} height={59} />
            </>
          )}
          {organizations && patients && user && (
            <>
              <Button
                className={styles.buttonPrimaryStyle}
                classes={{ endIcon: classes.buttonAddStyle }}
                style={{ marginRight: 10 }}
                endIcon={<MicrophonePlus />}
                onClick={() => openNewAppointment()}
                variant="contained"
                color="secondary"
                disableElevation
              >
                <span className={styles.buttonPrimarySpanStyle}>
                  {t("new-attention")}
                </span>
              </Button>
              <Button
                classes={{ endIcon: classes.buttonAddStyle }}
                className={styles.buttonSecondaryStyle}
                disableElevation
                variant="outlined"
                endIcon={<AccountPlus style={{ color: "#346DFF" }} />}
                onClick={() => handleClickOpen()}
              >
                <span
                  className={styles.buttonSecondarySpanStyle}
                  style={{ color: "#346DFF" }}
                >
                  {t("new-patient")}
                </span>
              </Button>
            </>
          )}
        </div>
        <div>
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            filterLabelText={t("filter-label-text")}
          />
        </div>
      </div>
    </Grid>
  );
};

export default PatientSubHeader;
