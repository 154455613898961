const config = require("../config.json");
const baseUrl = config["baseUrl"];
const apiUrl = config["fhirApiUrl"];
const env = config["env"];
import axios from "axios";

const requestUrl = baseUrl + apiUrl + "-" + env;

export function listPatients(token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + "/patient/list-patients",
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function createPatient(patient, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/patient/create-patient",
    data: patient,
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function editPatient(patient, token, accessToken) {
  let config = {
    method: "put",
    url: requestUrl + "/patient/edit-patient",
    data: patient,
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function createAppointment(appointment, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/appointment/create-appointment",
    data: appointment,
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function listAppointments(id, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/appointment/search-appointment",
    data: {
      size: 5,
      actor_id: id,
    },
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function listUsersFromApi(token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + "/user/list-user",
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function createUserFromApi(user, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/user/create-user",
    data: user,
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function editUserFromApi(user, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/user/edit-user",
    data: user,
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function createEncounter(data, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/encounter/create/v2",
    data: {
      reference: {
        organization_id: data.organization,
        appointment_id: data.appointment,
      },
      encounter: data.conclusion,
    },
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function searchEncounter(appointmentId, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + "/encounter/search",
    data: {
      appointment: appointmentId,
    },
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function listOrganizations(token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + "/organization/list-organization",
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function editOrganization(organization, token, accessToken) {
  let config = {
    method: "put",
    url: requestUrl + "/organization/edit-organization",
    data: organization,
    headers: {
      "content-type": "application/json",
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function listPractitioners(token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + "/user/list-practitioner",
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function getExam(id, code, token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + `/get-exam?id=${id}&code=${code}`,
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function getExamTypes(token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + `/exam-codes`,
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function getPatientMedicalRecord(patientId, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + `/patient/summary-file`,
    data: {
      id: patientId,
    },
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };
  return axios(config);
}

export function uploadPatientImage(patientId, image, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + `/patient/upload-image`,
    data: {
      id: patientId,
      image: image,
    },
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function searchPatients(attribute, value, token, accessToken) {
  let config = {
    method: "post",
    url: requestUrl + `/patient/search-patient`,
    data: {
      value: value,
    },
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}

export function getStatisticsData(token, accessToken) {
  let config = {
    method: "get",
    url: requestUrl + `/statistics/accuchek`,
    headers: {
      Authorization: token,
      "api-token": accessToken,
    },
  };

  return axios(config);
}
