import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import useStyles from "./clean.styles";

const LayoutClean = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <main className={clsx(classes.content)}>
          <Grid className={classes.container}>{children}</Grid>
        </main>
      </div>
    </>
  );
};

export default LayoutClean;
