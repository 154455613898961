import React from "react";
import PropTypes from "prop-types";
import CaptureAudioComponent from "../audio/CaptureAudioComponent";
import { Grid, CircularProgress } from "@material-ui/core";
import { Eye } from "mdi-material-ui";
import { useStyles } from "./styles";

export default function AppointmentFormStepName(props) {
  const classes = useStyles();

  return (
    <>
      <div>
        <Grid container spacing={3} style={{ marginTop: "50px" }}>
          <Grid item xs={"auto"} className={classes.verticalAlign}>
            <div className={classes.stepNameDiv}>
              <div className={classes.columnVerticalAlign}>
                {!props.noAudio ? (
                  <CaptureAudioComponent
                    step={props.step}
                    transcribing={props.transcribing}
                    transcribeOption={props.transcribeOption}
                    updateStreamingText={props.updateStreamingText}
                  />
                ) : (
                  <Grid
                    container
                    direction="column"
                    className={classes.wrapper}
                  >
                    <Grid item style={{ marginBottom: 5 }}>
                      <Eye color="secondary" fontSize="large" />
                    </Grid>
                  </Grid>
                )}
              </div>
              <CircularProgress
                size={210}
                thickness={1.5}
                variant="determinate"
                value={100}
                className={classes.fabProgressIncomplete}
              />
              <CircularProgress
                size={210}
                thickness={1.5}
                variant="determinate"
                value={((props.currentStep + 1) / props.maxStepsAllForm) * 100}
                className={classes.fabProgress}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

AppointmentFormStepName.propTypes = {
  activeStep: PropTypes.number,
  currentStep: PropTypes.number,
  maxSteps: PropTypes.number,
  step: PropTypes.string,
  transcribing: PropTypes.bool,
  updateStreamingText: PropTypes.func,
  maxStepsAllForm: PropTypes.number,
  transcribeOption: PropTypes.string,
  noAudio: PropTypes.bool,
};

AppointmentFormStepName.defaultProps = {
  noAudio: false,
};
