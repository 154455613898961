import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

export const useStyles = makeStyles((theme) => ({
  root: {},
  //steeper
  steeperContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "50px",
    "&::before": {
      content: '" "',
      height: "3px",
      width: "77%",
      backgroundColor: "#FB8F46",
      display: "block",
      position: "absolute",
      bottom: "50%",
      left: "179px",
    },
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: "flex",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  marginTop: {
    marginTop: 15,
  },
  displayHidden: {
    display: "none",
  },
  centerVertically: {
    display: "flex",
    "align-items": "center",
    margin: "auto",
    "justify-content": "center",
  },
  marginCircular: {
    margin: 10,
  },
  paper: {
    padding: "6px 16px",
  },
  marginBottom: {
    marginBottom: 5,
  },
  verticalAlign: {
    display: "flex",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  noPadding: {
    padding: 0,
  },
  noRightPadding: {
    "padding-right": 0,
  },
  button: {
    border: "1px solid rgba(255, 255, 255, 1)",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  displayContent: {
    display: "contents",
  },
  spanButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  "MuiButton-outlinedPrimary:hover": {
    /* border: 1px solid #333333; */
    "background-color": "rgba(51, 51, 51, 0.04)",
  },
  backButton: {
    "margin-right": 10,
  },
  iconTransparent: {
    color: "transparent",
    marginRight: "10px",
  },
  fontValueSize: {
    fontSize: "18px",
  },
  iconTitleData: {
    color: "#021842",
    marginRight: "10px",
  },
  fontTitleData: {
    fontSize: "16px",
    color: "#021842",
    fontWeight: 600,
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  borderRadiusAvatar: {
    borderRadius: "8px 0px 8px 0px",
  },
  extraLargeAvatar: {
    width: "16vw",
    minWidth: 160,
    minHeight: 100,
    height: "25vh",
  },
  badgeProfile: {
    transform: "scale(1) translate(10%, 0%)",
    height: "inherit",
  },
  bgHeaderPatient: {
    background: "#F1F8FF",
    padding: 0,
    borderRadius: "0px 0px 20px 20px",
  },
  cardHeaderPatient: {
    background: "#F1F8FF",
  },
  avatarHeaderPatient: {
    width: "90px",
    height: "114px",
  },
  buttonCircular: {
    height: "64px",
    width: "64px",
    borderRadius: "50%",
    backgroundColor: "#346DFF",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: "#346DFF",
    },
  },
  paperInformation: {
    minHeight: "42px",
    minWidth: "42px",
    width: "42px",
    height: "42px",
    borderRadius: "50%",
    backgroundColor: "#25AB22",
    boxShadow: "0px 2px 10px rgba(247, 68, 68, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "7px",
    "&:hover": {
      backgroundColor: "#25AB22",
    },
  },
  badgeBlue: {
    width: "50px",
    height: "23px",
    backgroundColor: "#C2E0FF",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    marginLeft: "10px",
  },
  dropdownInfoComplications: {
    borderRadius: "5px",
    border: "1px solid #FAC509",
    color: "#021842",
    width: "100px",

    marginTop: "6px",
  },
  itemTitleInfoComplications: {
    fontWeight: "600",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "9px",
    paddingTop: "0px",
    fontSize: "13px",
  },
  lineBlue: {
    height: "1px",
    width: "100%",
    backgroundColor: "#021842",
    display: "block",
  },
  appBarAttention: {
    backgroundColor: "#F1F8FF",
    paddingBottom: "2px",
    marginTop: "-5px",
    boxShadow: "none",
  },
  toolbarAttention: {
    backgroundColor: "#FFF",
    borderRadius: "0px 0px 20px 20px",
    minHeight: "100px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  contentAttention: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textAttention: {
    fontSize: "18px",
    backgroundColor: "#F1F8FF",
    borderRadius: "10px",
    padding: "5px 0 5px 15px ",
  },
  contentStepName: {
    position: "relative",
    left: "27%",
  },
  textStepName: {
    color: "#021842",
    fontSize: "18px",
    fontWeight: "500",
  },

  //sumary
  accordionMoreInteration: {
    backgroundColor: "transparent",
  },
  noPadding: {
    padding: "0 !important",
  },
  noBorder: {
    border: "none",
    "padding-bottom": 0,
  },
  marginTopTextField: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    "justify-content": "flex-end",
  },
  textAreaTitle: {
    "font-size": 18,
    "font-weight": 500,
    "text-align": "left",
  },

  blueGrayTitle: {
    "font-size": 16,
    "font-weight": 400,
    "text-align": "left",
    color: "#293B48",
  },
  blueGraySmall: {
    "font-size": 12,
    "font-weight": 400,
    color: "#293B48",
  },
  resultSubtitle: {
    "margin-top": theme.spacing(1),
    "font-size": 15,
    "font-weight": 500,
    "text-align": "left",
  },
  resultTitle: {
    "margin-top": theme.spacing(1),
    "font-size": 16,
    "font-weight": 500,
    "text-align": "left",
  },
  subTitle: {
    "font-size": 12,
    "font-weight": 400,
    "text-align": "left",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  buttonClass: {
    "justify-content": "flex-end",
    "margin-bottom": "auto",
    "margin-top": "auto",
    "align-items": "center",
    display: "block",
  },
  justifySpaceBetween: {
    "margin-top": theme.spacing(6),
    "margin-bottom": theme.spacing(3),
    display: "flex",
    "justify-content": "space-between",
  },
  formControl: {
    minWidth: "100%",
  },
  iconAlert: {
    "& .MuiAlert-icon": {
      fontSize: 23,
    },
  },
  bgAlert: {
    "background-color": "#FB8F46",
  },
  cardTranscription: {
    width: "100%",
    "border-radius": "4px",
    "box-shadow": "0px 2px 10px rgba(0, 0, 0, 0.1)",
  },
  inputBase: {
    "& .MuiInput-underline:after": {
      borderBottom: "0",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "border-radius": "4px",
    "box-shadow": "0px 2px 20px rgba(0, 0, 0, 0.1)",
    "margin-bottom": "0",
    padding: "12px 11px",
    borderBottom: "none",
  },
  summaryStepSubtitle: {
    color: "#021842",
    "font-size": "20px",
  },
  selectBase: {
    "& .MuiInput-underline:before": {
      borderBottom: "0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
    "& .MuiSelect-icon": {
      color: "#021842",
    },
    "border-radius": "4px",
    "box-shadow": "0px 2px 20px rgba(0, 0, 0, 0.1)",
    "margin-bottom": "0",
    padding: "8px 11px",
    color: "#061621",
    minWidth: "100%",
  },
  btnTooltip: {
    backgroundColor: "#346DFF !important",
    padding: "12px 11px",
    borderRadius: "4px",
    marginTop: "5px",
    fontSize: "12px",
    color: "#fff",
    textTransform: "capitalize",
    "& .MuiButton-root:hover": {
      backgroundColor: "#346DFF",
    },
  },
  bgInteractionLess: {
    backgroundColor: "rgba(175, 214, 255, 0.4)",
  },
  bgInteractionModerate: {
    backgroundColor: "rgba(255, 230, 140, 0.4)",
  },
  bgInteractionHigher: {
    backgroundColor: "rgba(251, 129, 129, 0.4)",
  },
  colorInteractionLess: {
    color: "#2D92FF",
  },
  colorInteractionModerate: {
    color: "#FAC509",
  },
  colorInteractionHigher: {
    color: "#F43737",
  },
  bgMoreInteractionLess: {
    backgroundColor: "#2D92FF",
  },
  bgMoreInteractionModerate: {
    backgroundColor: "#FAC509",
  },
  bgMoreInteractionHigher: {
    backgroundColor: "#F43737",
  },
  titleInteractions: {
    borderBottom: "none",
    backgroundColor: "transparent",
    "& .MuiAccordionSummary-content": {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  moreInformationInteraction: {
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      trasnform: "rotate(270deg)",
    },
  },
  colorOrange: {
    color: "#FA6A09",
  },
  colorWhite: {
    color: "#FFF",
  },
  colorBlue: {
    color: "#021842",
  },
  mt10: {
    marginTop: 10,
  },
  accordionAlert: {
    marginBottom: 10,
    justifyContent: "center",
    border: "none",
    borderRadius: "4px",
  },
  accordionAlertContent: {
    marginBottom: "10px",
    border: "1px solid #D3DCE2",
    borderRadius: "4px",
  },
  fontWeight500: {
    fontWeight: "500",
  },
  headerAccordion: {
    justifyContent: "center",
    padding: "0 10px 0 2px",
    borderBottom: "none",
  },
  barraInteraciones: {
    height: "35px",
    width: "4px",
    backgroundColor: "#FA6A09",
    display: "flex",
    marginRight: "10px",
    borderRadius: "2px",
  },
  nameInteracciones: {
    color: "#fff",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "600",
  },
  accodionContent: {
    backgroundColor: "#fff",
    borderBottom: "none",
    padding: "5px 0",
    display: "flex",
    flexDirection: "column",
  },
  textSeverity: {
    color: "#021842",
    fontWeight: "500",
    fontStyle: "16px",
    display: "flex",
    alignItems: "center",
  },
  typeSeverity: {
    fontWeight: "bold",
    marginTop: "5px",
    marginLeft: "5px",
  },
}));

export const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
  },
}))(MuiAccordionDetails);

export const AccordionMoreInteraction = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    content: {
      "&$expanded": {
        backgroundColor: "#fff",
      },
    },
  },
  expanded: {},
})(MuiAccordion);
