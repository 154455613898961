import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: "flex",
  },
  marginTop: {
    marginTop: 15,
  },
  displayHidden: {
    display: "none",
  },
  centerVertically: {
    display: "flex",
    "align-items": "center",
    margin: "auto",
    "justify-content": "center",
    "flex-wrap": "nowrap",
  },
  marginCircular: {
    margin: 10,
  },

  verticalAlignBlock: {
    display: "block",
    margin: "auto",
    padding: 10,
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  displayContent: {
    display: "contents",
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonOtherColor: {
    backgroundColor: "#E6ECF2",
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  spanButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonAddStyle: {
    color: "#346DFF",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
}));
export default useStyles;
