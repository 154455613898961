import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    selected: {
        color: theme.palette.secondary.main,
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        borderLeft: "5px solid"
    },
    colorSelected: {
        color: theme.palette.secondary.main,
    },
    marginTop: {
        marginTop: 15,
      },
      button: {
        [theme.breakpoints.down("md")]: {
          minWidth: 32,
          paddingLeft: 8,
          paddingRight: 8,
          "& .MuiButton-startIcon": {
            margin: 0,
          },
        },
      },
    
      rightAlign: {
        display: "flex",
        "justify-content": "flex-end",
      },
    
      buttonAddStyle: {
        [theme.breakpoints.down("md")]: {
          margin: 0,
        },
      },
      spaceBetween: {
        display: "flex",
        justifyContent: "space-between",
      },
      verticalAlignBlock: {
        display: "block",
        margin: "auto",
        padding: "10px 0 ",
        width: "100%",
      },
}));

export default useStyles;
