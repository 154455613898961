import React from "react";
import PropTypes from "prop-types";
import CaptureAudioComponent from "./../audio/CaptureAudioComponent";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  verticalAlign: {
    display: "flex",
    margin: "auto",
  },
  verticalAlignBlock: {
    display: "block",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  subtitle: {
    fontFamily: "Titillium Web",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 200,
    color: "#021842",
  },
}));

export default function AppointmentFormHeader(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container style={{ padding: 20 }}>
        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Typography className={classes.title} style={{ textAlign: "center" }}>
            <span>{props.stepName}</span>
          </Typography>
          <Typography
            className={classes.subtitle}
            style={{ textAlign: "center" }}
            gutterBottom
          >
            {props.subStepName}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

AppointmentFormHeader.propTypes = {
  stepName: PropTypes.string,
  subStepName: PropTypes.string,
  stepNumber: PropTypes.string,
};

AppointmentFormHeader.defaultProps = {
  noAudio: false,
};
