import { useHistory } from "react-router";
import { Auth } from "@aws-amplify/auth";
import React from "react";
import "react-h5-audio-player/lib/styles.css";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import { Logout } from "mdi-material-ui";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { connect } from "react-redux";
import { login, logout } from "../store/actions";
import { useTranslation } from "react-i18next";

const LogoutButton = ({ dispatch }) => {
  let history = useHistory();
  const { t, i18n } = useTranslation();

  async function logoutAuth() {
    try {
      await Auth.signOut();
      dispatch(logout());
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <>
      <ListItem button onClick={logoutAuth}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary={t("logout")} />
      </ListItem>
    </>
  );
};

export default connect()(LogoutButton);
