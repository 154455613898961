import React from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import { compose, createStore } from "redux";
import rootReducer from "./store/reducers";
import config from "./config.json";
import Amplify, { Auth } from "aws-amplify";
import { StylesProvider } from "@material-ui/styles";
import persistState from "redux-localstorage";

const enhancer = compose(persistState());

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: config["region"],

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config["userPoolWebClientId"],

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config["userPoolId"],
  },
});

const store = createStore(rootReducer, enhancer);

export default function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <StylesProvider injectFirst>
          <Routes />
        </StylesProvider>
      </Provider>
    </React.StrictMode>
  );
}
