import { UPDATE_PATIENT_ENCOUNTER_DETAILS } from "../actionTypes";

const defaultState = {
  appointment: null,
};
const patientEncounterDetails = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_PATIENT_ENCOUNTER_DETAILS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default patientEncounterDetails;
