import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Divider, List, Drawer, CssBaseline } from "@material-ui/core";
import LogoutButton from "../../LogoutButton";
import HeaderPatient from "../../headerPatients/HeaderPatient";
import NavigationItem from "../../NavigationItem";
import navigationItems from "./navigation";
import NavBarTop from "./NavBarTop";
import useBaseLayout from "./hooks";

const LayoutBase = ({
  children,
  user,
  dispatch,
  patients,
  medicalRecord,
  expertSystem,
  practitioners,
  clientHeader,
  appointments,
  patientId,
}) => {
  const [open, handleDrawerOpen, t, classes, role] = useBaseLayout(
    dispatch,
    patients,
    expertSystem,
    practitioners,
    user,
    medicalRecord
  );

  return (
    <>
      <CssBaseline />
      <NavBarTop handleDrawerOpen={handleDrawerOpen} />
      <Drawer
        anchor="left"
        variant="temporary"
        open={open}
        onClick={handleDrawerOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <Divider />
        <List>
          <div>
            {user ? (
              navigationItems(t).map(
                (item, index) =>
                  item.restrictedTo.some((r) => r === role || r === "all") && (
                    <NavigationItem
                      key={index}
                      icon={item.icon}
                      primary={item.primary}
                      to={item.to}
                      disabled={item.disabled}
                      role="presentation"
                    />
                  )
              )
            ) : (
              <CircularProgress className={classes.marginCircular} />
            )}
            <div style={{ marginBottom: "10px" }}>
              <Divider />
              <LogoutButton />
            </div>
          </div>
        </List>
      </Drawer>
      {clientHeader && medicalRecord.id !== null && (
        <HeaderPatient
          user={user}
          patients={patients}
          medicalRecord={medicalRecord}
          practitioners={practitioners}
          appointments={appointments}
          patientId={patientId}
        />
      )}
      <div>{children}</div>
    </>
  );
};
const mapStateToProps = ({
  user,
  patients,
  practitioners,
  patientAppointments,
  patientExpertSystem,
  patientMedicalRecord,
  patient,
}) => ({
  user: user,
  patients: patients,
  practitioners: practitioners,
  appointments: patientAppointments,
  expertSystem: patientExpertSystem,
  medicalRecord: patientMedicalRecord,
  patientId: patient,
});

export default connect(mapStateToProps)(LayoutBase);
