import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Icon,
} from "@material-ui/core/";
import { VisibilityOff, Visibility } from "@material-ui/icons/";
import useStyles from "./style";
import Layout from "../../components/layouts/Base";

const ChangePassword = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [values, setValues] = React.useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid
          container
          justify="center"
          className={classes.justifyCenterVertical}
        >
          <Typography
            component="h1"
            variant="h5"
            className={clsx(classes.title, classes.whiteText)}
          >
            VOYAGER
          </Typography>
        </Grid>

        <Typography className={classes.welcome}>
          {t("change-password-insert-new-password")}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            label={t("change-password-password-label")}
            autoComplete="current-password"
            className={clsx(classes.textField, classes.whiteText)}
            value={values.password}
            type={values.showPassword ? "text" : "password"}
            onChange={handleChange("password")}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              classes: {
                root: classes.whiteText,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <Visibility className={classes.whiteText} />
                    ) : (
                      <VisibilityOff className={classes.whiteText} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />

          <TextField
            label={t("change-password-confirm-password-label")}
            autoComplete="current-password"
            className={clsx(
              classes.margin,
              classes.textField,
              classes.whiteText
            )}
            value={values.password}
            type={values.showConfirmPassword ? "text" : "password"}
            onChange={handleChange("confirmPassword")}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              classes: {
                root: classes.whiteText,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <Visibility className={classes.whiteText} />
                    ) : (
                      <VisibilityOff className={classes.whiteText} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <Link style={{ textDecoration: "none" }} to={`/login`}>
            <Button
              fullWidth
              variant="contained"
              className={(classes.submit, classes.bgOrange)}
            >
              <span className={classes.whiteText}>
                {t("change-password-change")}
              </span>
            </Button>
          </Link>
        </form>
      </div>
    </Container>
  );
};

export default ChangePassword;
