import { UPDATE_PATIENT_ID } from "../actionTypes";
const defaultState = {
  patientId: "",
};
const patient = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_PATIENT_ID:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default patient;
