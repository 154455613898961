import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useStyles, useQontoStepIconStyles, QontoConnector } from "./styles";
import steepStatus from "../../constants/steepStatus";

function QontoStepIcon({ status }) {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: status === steepStatus.isActive,
      })}
    >
      {status === steepStatus.isEmpty && <div className={classes.circle} />}

      {status ===
        steepStatus.isComplete(<Check className={classes.completed} />)}
    </div>
  );
}

QontoStepIcon.propTypes = {
  status: PropTypes.number,
};

export default function AppointmentFormStepper(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [options, setOption] = React.useState({
    transcribeSelectedOption: "Streaming",
  });

  return (
    <>
      <div className={classes.marginTopStepper}>
        <Grid container spacing={3} className={classes.marginTopStepper}>
          <Stepper
            alternativeLabel
            connector={<QontoConnector />}
            activeStep={steepStatus.isActive}
            className={classes.stepperClass}
          >
            {steepStatus.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  className={classes.titleStepLabel}
                  StepIconComponent={QontoStepIcon}
                >
                  <Typography
                    className={classes.titleStep}
                    gutterBottom
                    key={index}
                  >
                    {props.step}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </div>
    </>
  );
}

AppointmentFormStepper.propTypes = {
  steps: PropTypes.array,
  stepName: PropTypes.string,
  stepNumber: PropTypes.string,
  subStepName: PropTypes.string,
  onChangeTranscribeOption: PropTypes.func,
  activeStep: PropTypes.number,
  maxSteps: PropTypes.number,
  patientName: PropTypes.string,
  motive: PropTypes.string,
  uploading: PropTypes.bool,
  transcription: PropTypes.bool,
};

AppointmentFormStepper.defaultProps = {
  transcription: false,
};
