import { UPDATE_USERS } from "../actionTypes";

const defaultState = [];

const users = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_USERS:
      return payload;
    default:
      return state;
  }
};

export default users;
