import {
  AccountNetwork,
  AccountSettings,
  BellOutline,
  Calendar,
  ChartLine,
  CloudSearchOutline,
  Home,
  HomeOutline,
  HospitalBuilding,
} from "mdi-material-ui";
//Menu principal de navegacion
const navigationItems = (t) => [
  {
    icon: <Home />,
    primary: t("home"),
    to: "/patients",
    restrictedTo: ["all"],
    disabled: false,
  },
  {
    icon: <HospitalBuilding />,
    primary: t("organizations"),
    to: "/organizations",
    restrictedTo: ["admin"],
    disabled: false,
  },
  {
    icon: <AccountNetwork />,
    primary: t("users"),
    to: "/users",
    restrictedTo: ["hospitalAdmin"],
    disabled: false,
  },
  {
    icon: <CloudSearchOutline />,
    primary: t("advanced-search"),
    to: "/search-patient",
    restrictedTo: ["all"],
    disabled: false,
  },
  {
    icon: <Calendar />,
    primary: t("calendar"),
    to: "/calendar",
    restrictedTo: ["all"],
    disabled: true,
  },
  {
    icon: <ChartLine />,
    primary: t("statistics"),
    to: "/statistics",
    restrictedTo: ["all"],
    disabled: false,
  },
];

export default navigationItems;
