import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  verticalAlign: {
    display: "flex",
    margin: "auto",
  },
  displayFlex: {
    display: "flex",
  },
  verticalAlignBlock: {
    display: "block",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  appBarStyle: {
    backgroundColor: "#FFF",
    borderRadius: "4px",
    borderBottom: "1px solid #D3DCE2",
    marginBottom: "15px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1), inset 0px 3px 0px #FA6A09",
  },
  titleStyle: {
    color: "black",
    fontFamily: "Titillium Web",
    fontWeight: "bold",
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  displayContent: {
    display: "contents",
  },
  marginDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: "100%",
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  marginPerGrid: {
    marginTop: theme.spacing(1),
  },
  whiteText: {
    color: "white",
  },
  marginCircular: {
    margin: 10,
  },
  titleDialog: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    borderRadius: "0px 0px 10px 10px",
    padding: " 1px 21px",
    boxShadow: "0px 2px rgb(0 0 0 / 10%)",
    borderTop: "3px solid #FA6A09",
    outline: "none",
  },

  tabItemMedicalRecord: {
    color: "#828282",
    fontSize: 16,
    fontWeight: "400",
  },
  tabItemMedicalRecordActive: {
    fontSize: 16,
    fontWeight: "600",
    color: "#346DFF",
  },
  accordionMedicalRecord: {
    border: "1px solid #E0E0E0",
    marginTop: 10,
    backgroundColor: "none",
    borderRadius: "4px",
  },
  accordionSummaryMedicalRecord: {
    background: "#F1F8FF",
    borderBottom: "1px solid #D3DCE2",
    borderRadius: "6px",
    minHeight: "45px",
    height: "45px",
    "& .Mui-expanded": {
      minHeight: "45px",
    },
  },
  accordionDetailsMedicalRecord: {
    // border: "1px solid #E0E0E0",
    borderRadius: "4px",
  },
  accordionDetailsMedicalRecordTable: {
    // border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "0",
  },
  titleAccordionMedicalRecord: {
    fontSize: "16px",
    color: "#021842",
    fontWeight: "400",
  },
  titleItemMedicalRecord: {
    color: "#000",
    fontWeight: "700",
    fontSize: "13px",
  },
  subtitleItemMedicalRecord: {
    color: "#000",
    fontWeight: "400",
    fontSize: "15px",
  },
  itemBioquimicosParam: {
    backgroundColor: "#3496FF",
    padding: "5px 10px",
    borderRadius: "20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
  },
  itemBioquimicosResult: {
    backgroundColor: "#93C7FF",
    padding: "5px 10px",
    borderRadius: "20px",
    color: "#021842",
    fontSize: "14px",
    fontWeight: "400",
  },
  itemsBioquimicos: {
    backgroundColor: "#C2E0FF",
    padding: "5px 10px",
    borderRadius: "20px",
    color: "#021842",
    fontSize: "14px",
    fontWeight: "400",
  },
  buttonDelete: {
    backgroundColor: "#FA6A09",
    borderRadius: "50%",
    padding: "4px",
    width: "20px",
    height: "20px",
    color: "#fff",
    boxShadow: "0px 2px 10px 0 rgba(0, 0, 0, 0.2)",
    "&:hover": {
      backgroundColor: "#FA6A09",
      color: "#fff",
    },
  },
}));

export default useStyles;
