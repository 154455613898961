import React, { useState, forwardRef, useEffect } from "react";
import useStyles from "./styles";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  PencilOutline,
  Pill,
  AccountCheck,
  Stethoscope,
  ClipboardPulseOutline,
} from "mdi-material-ui";
import {
  CloseCircleOutline,
  ClipboardAccountOutline,
  ClipboardText,
  ClipboardCheckOutline,
} from "mdi-material-ui";
import RemoveIcon from "@material-ui/icons/Remove";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SwipeableViews from "react-swipeable-views";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MedicalRecordDialog = ({
  open,
  handleClose,
  medicalRecord,
  patientId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleChangeIndex = (index) => {
    setTab(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  function parseToDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  function parseExam(exam) {
    return exam.unit === "-"
      ? exam.value.toString() + " - " + parseToDate(exam.date)
      : exam.value.toString() + exam.unit + " - " + parseToDate(exam.date);
  }

  const birthday_parts = medicalRecord
    ? medicalRecord.patient_birthDate
      ? moment(medicalRecord.patient_birthDate).format("DD/MM/YYYY")
      : ""
    : "";
  const birthday = medicalRecord
    ? new Date(
        birthday_parts[1] + "/" + birthday_parts[2] + "/" + birthday_parts[0]
      )
    : "";
  const patient_age = moment().diff(
    moment(birthday_parts, "DDMMYYYY"),
    "years"
  );

  //const ageDifMs = medicalRecord ? Date.now() - birthday.getTime() : "";
  //let medicalRecord = medicalRecord ? medicalRecordPatient(medicalRecord) : [];

  function createData(parametro, resultado, unidad, referencia, fecha) {
    return { parametro, resultado, unidad, referencia, fecha };
  }
  function createDataMedicines(
    medicamento,
    fuerza,
    dosificacion,
    frecuencia,
    fecha,
    action
  ) {
    return { medicamento, fuerza, dosificacion, frecuencia, fecha, action };
  }
  const rows = [
    createData("Glicemia", 108, "mg/dl", "74-100", "30/03/2021"),
    createData(
      "Hemoglobina Glicosilada glidoslada",
      7.6,
      "%",
      37,
      "12/07/2021"
    ),
    createData("Colesterol Total", 140, "---", 24, "19/07/2021"),
  ];
  const rowsMedicines = [
    createDataMedicines(
      "Metformina",
      "850 xx",
      "1 pastilla",
      "2 veces al día",
      "12/05/2021"
    ),
  ];
  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.titleDialog}
          onClose={handleClose}
        >
          {t("Ficha del paciente")}
          <IconButton
            color="primary"
            aria-label="close button"
            onClick={handleClose}
          >
            <CloseCircleOutline />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Tabs */}
          <Tabs
            value={tab}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.displayFlex,
            }}
            style={{ marginBottom: 10 }}
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab
              label={t("mediacal-redor-demografic-data")}
              icon={<ClipboardAccountOutline style={{ fontSize: "30px" }} />}
              className={
                tab === 0
                  ? classes.tabItemMedicalRecordActive
                  : classes.tabItemMedicalRecord
              }
              {...a11yProps(0)}
            />
            <Tab
              label={t("medical-record-examens")}
              className={
                tab === 1
                  ? classes.tabItemMedicalRecordActive
                  : classes.tabItemMedicalRecord
              }
              icon={<ClipboardCheckOutline style={{ fontSize: "30px" }} />}
              {...a11yProps(1)}
            />
            <Tab
              label={t("medicines")}
              className={
                tab === 2
                  ? classes.tabItemMedicalRecordActive
                  : classes.tabItemMedicalRecord
              }
              icon={<Pill style={{ fontSize: "30px" }} />}
              {...a11yProps(2)}
            />
            <Tab
              disabled
              label={t("medical-record-psychosocial-sumary")}
              className={
                tab === 3
                  ? classes.tabItemMedicalRecordActive
                  : classes.tabItemMedicalRecord
              }
              icon={<AccountCheck style={{ fontSize: "30px" }} />}
              {...a11yProps(3)}
            />
            <Tab
              disabled
              label={t("medical-record-derivations")}
              className={
                tab === 4
                  ? classes.tabItemMedicalRecordActive
                  : classes.tabItemMedicalRecord
              }
              icon={<Stethoscope style={{ fontSize: "30px" }} />}
              {...a11yProps(4)}
            />
            <Tab
              disabled
              label={t("medical-record-treatments")}
              className={
                tab === 5
                  ? classes.tabItemMedicalRecordActive
                  : classes.tabItemMedicalRecord
              }
              icon={<ClipboardPulseOutline style={{ fontSize: "30px" }} />}
              {...a11yProps(5)}
            />
          </Tabs>
          <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
            <TabPanel value={tab} index={0}>
              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Grid container direction="row">
                    <Grid item xs={6} style={{ margin: "auto" }}>
                      <Typography
                        className={classes.titleAccordionMedicalRecord}
                      >
                        {t("medical-record-personal-data")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#021842",
                          padding: "0",
                          marginRight: "-7px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <PencilOutline fontSize="18px" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.accordionDetailsMedicalRecord}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={clsx(classes.spaceBetween, classes.paddingTop3)}
                  >
                    <Grid
                      container
                      direction="column"
                      className={clsx(classes.spaceBetween)}
                      style={{ paddingTop: "20px", paddingBottom: "15px" }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography component={"p"} className={classes.title}>
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-name-patient")}
                            </span>
                          </Typography>
                          <Typography
                            component={"span"}
                            className={classes.title}
                          >
                            {!medicalRecord.id ||
                            patientId !== medicalRecord.id ? (
                              <>
                                <span
                                  className={classes.subtitleItemMedicalRecord}
                                >
                                  {medicalRecord.patient_given +
                                    " " +
                                    medicalRecord.patient_family}
                                </span>
                              </>
                            ) : (
                              <Skeleton
                                animation="wave"
                                height={30}
                                width="50%"
                                style={{ marginBottom: 3 }}
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            <span className={classes.titleItemMedicalRecord}>
                              {t("identifier")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_identifier}
                              </span>
                            </>
                          ) : (
                            <Skeleton
                              animation="wave"
                              height={30}
                              width="50%"
                              style={{ marginBottom: 3 }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-patient-age")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {patient_age + " " + "años"}
                              </span>
                            </>
                          ) : (
                            <Skeleton
                              animation="wave"
                              height={30}
                              width="50%"
                              style={{ marginBottom: 3 }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("birth-date")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_birthDate}
                              </span>
                            </>
                          ) : (
                            <Skeleton
                              animation="wave"
                              height={30}
                              width="50%"
                              style={{ marginBottom: 3 }}
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-system-health")}
                            </span>
                          </Typography>
                          <Typography
                            component={"span"}
                            className={classes.title}
                          >
                            {!medicalRecord.id ||
                            patientId !== medicalRecord.id ? (
                              <>
                                {/* <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                /> */}
                                <span
                                  className={classes.subtitleItemMedicalRecord}
                                >
                                  Fonasa
                                </span>
                              </>
                            ) : (
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_given}
                              </span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-patient-sex")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_gender}
                              </span>
                            </>
                          ) : (
                            <Skeleton
                              animation="wave"
                              height={30}
                              width="50%"
                              style={{ marginBottom: 3 }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-occupation")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                Enfermero
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-nationality")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                Chilena
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Grid container direction="row">
                    <Grid item xs={6} style={{ margin: "auto" }}>
                      <Typography
                        className={classes.titleAccordionMedicalRecord}
                      >
                        {t("medical-record-contact")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#021842",
                          padding: "0",
                          marginRight: "-7px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <PencilOutline fontSize="18px" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.accordionDetailsMedicalRecord}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={clsx(classes.spaceBetween, classes.paddingTop3)}
                  >
                    <Grid
                      container
                      direction="column"
                      className={clsx(classes.spaceBetween)}
                      style={{ paddingTop: "20px", paddingBottom: "15px" }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("organization-columns-address")}
                            </span>
                          </Typography>
                          <Typography
                            component={"span"}
                            className={classes.title}
                          >
                            {!medicalRecord.id ||
                            patientId !== medicalRecord.id ? (
                              <>
                                <span
                                  className={classes.subtitleItemMedicalRecord}
                                >
                                  {medicalRecord.patient_address}
                                </span>
                              </>
                            ) : (
                              <Skeleton
                                animation="wave"
                                height={30}
                                width="50%"
                                style={{ marginBottom: 3 }}
                              />
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("phone")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                +562 2884898
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("email")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_email}
                              </span>
                            </>
                          ) : (
                            <Skeleton
                              animation="wave"
                              height={30}
                              width="50%"
                              style={{ marginBottom: 3 }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-use-whatsapp")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                Sí
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-celphone")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_phoneNumber}
                              </span>
                            </>
                          ) : (
                            <Skeleton
                              animation="wave"
                              height={30}
                              width="50%"
                              style={{ marginBottom: 3 }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Grid container direction="row">
                    <Grid item xs={6} style={{ margin: "auto" }}>
                      <Typography
                        className={classes.titleAccordionMedicalRecord}
                      >
                        {t("medical-record-contact-emergency")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        style={{
                          color: "#021842",
                          padding: "0",
                          marginRight: "-7px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <PencilOutline fontSize="18px" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.accordionDetailsMedicalRecord}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={clsx(classes.spaceBetween, classes.paddingTop3)}
                  >
                    <Grid
                      container
                      direction="column"
                      className={clsx(classes.spaceBetween)}
                      style={{ paddingTop: "20px", paddingBottom: "15px" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={3}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-name-patient")}
                            </span>
                          </Typography>
                          <Typography
                            component={"span"}
                            className={classes.title}
                          >
                            {!medicalRecord.id ||
                            patientId !== medicalRecord.id ? (
                              <>
                                {/* <Skeleton
                                  animation="wave"
                                  height={30}
                                  width="50%"
                                  style={{ marginBottom: 3 }}
                                /> */}
                                <span
                                  className={classes.subtitleItemMedicalRecord}
                                >
                                  xxx xxxxx
                                </span>
                              </>
                            ) : (
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                {medicalRecord.patient_given}
                              </span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("address")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                Agustinas 156 Depto 44 Santiago Región
                                Metropolitana
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-name-patient")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                xxx xxxxx
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("address")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                Agustinas 156 Depto 44 Santiago Región
                                Metropolitana
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-celphone")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                +569 76333766
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}>
                          <Typography component={"p"} className={classes.title}>
                            {/* A traductor */}
                            <span className={classes.titleItemMedicalRecord}>
                              {t("medical-record-celphone")}
                            </span>
                          </Typography>
                          {!medicalRecord.id ||
                          patientId !== medicalRecord.id ? (
                            <>
                              <span
                                className={classes.subtitleItemMedicalRecord}
                              >
                                +569 76333766
                              </span>
                            </>
                          ) : (
                            <span className={classes.subtitleItemMedicalRecord}>
                              {medicalRecord.hemoglobin}
                            </span>
                          )}
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-biochemicals")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  className={classes.accordionDetailsMedicalRecordTable}
                >
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {" "}
                            {t("medical-record-parameter")}{" "}
                          </TableCell>
                          <TableCell> {t("medical-record-result")} </TableCell>
                          <TableCell>{t("unit")}</TableCell>
                          <TableCell>{t("medical-record-reference")}</TableCell>
                          <TableCell>{t("date")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              <span className={classes.itemBioquimicosParam}>
                                {" "}
                                {row.parametro}
                              </span>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <span className={classes.itemBioquimicosResult}>
                                {row.resultado}
                              </span>{" "}
                            </TableCell>
                            <TableCell>
                              <span className={classes.itemsBioquimicos}>
                                {row.unidad}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span className={classes.itemsBioquimicos}>
                                {row.referencia}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span className={classes.itemsBioquimicos}>
                                {row.fecha}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-urine")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-hematologic")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <Accordion
                className={classes.accordionMedicalRecord}
                elevation={0}
                defaultExpanded={false}
              >
                <AccordionSummary
                  className={classes.accordionSummaryMedicalRecord}
                  expandIcon={<ExpandMoreIcon style={{ color: "#021842" }} />}
                >
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    {t("medical-record-current-medication")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0" }}>
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell> {t("medicine")} </TableCell>
                          <TableCell> {t("force")} </TableCell>
                          <TableCell>{t("dosages")}</TableCell>
                          <TableCell>{t("frequency")}</TableCell>
                          <TableCell>{t("date")}</TableCell>
                          <TableCell>{t("")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowsMedicines.map((rowsMedicines) => (
                          <TableRow key={rowsMedicines.medicamento}>
                            <TableCell component="th" scope="row">
                              <span className={classes.itemBioquimicosParam}>
                                {" "}
                                {rowsMedicines.medicamento}
                              </span>
                            </TableCell>
                            <TableCell>
                              {" "}
                              <span className={classes.itemBioquimicosResult}>
                                {rowsMedicines.fuerza}
                              </span>{" "}
                            </TableCell>
                            <TableCell>
                              <span className={classes.itemsBioquimicos}>
                                {rowsMedicines.dosificacion}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span className={classes.itemsBioquimicos}>
                                {rowsMedicines.frecuencia}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span className={classes.itemsBioquimicos}>
                                {rowsMedicines.fecha}
                              </span>
                            </TableCell>
                            <TableCell>
                              <span>
                                <IconButton
                                  aria-label="delete"
                                  className={classes.buttonDelete}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </TabPanel>

            <TabPanel value={tab} index={3}>
              <Accordion
                style={{
                  border: "none",
                  marginTop: 10,
                  backgroundColor: "none",
                }}
                classes={{
                  root: classes.rootAccordion,
                }}
                elevation={0}
              >
                <AccordionSummary
                  style={{
                    background: "#F1F8FF",
                    border: "1px solid rgba(0, 0, 0, .125)",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-physical-and-mental-exams")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  border: "none",
                  marginTop: 10,
                  backgroundColor: "none",
                }}
                classes={{
                  root: classes.rootAccordion,
                }}
                elevation={0}
              >
                <AccordionSummary
                  style={{
                    background: "#F1F8FF",
                    border: "1px solid rgba(0, 0, 0, .125)",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-family-history")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <Accordion
                style={{
                  border: "none",
                  marginTop: 10,
                  backgroundColor: "none",
                }}
                classes={{
                  root: classes.rootAccordion,
                }}
                elevation={0}
              >
                <AccordionSummary
                  style={{
                    background: "#F1F8FF",
                    border: "1px solid rgba(0, 0, 0, .125)",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-physical-and-mental-exams")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  border: "none",
                  marginTop: 10,
                  backgroundColor: "none",
                }}
                classes={{
                  root: classes.rootAccordion,
                }}
                elevation={0}
              >
                <AccordionSummary
                  style={{
                    background: "#F1F8FF",
                    border: "1px solid rgba(0, 0, 0, .125)",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-family-history")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <Accordion
                style={{
                  border: "none",
                  marginTop: 10,
                  backgroundColor: "none",
                }}
                classes={{
                  root: classes.rootAccordion,
                }}
                elevation={0}
              >
                <AccordionSummary
                  style={{
                    background: "#F1F8FF",
                    border: "1px solid rgba(0, 0, 0, .125)",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-physical-and-mental-exams")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
              <Accordion
                style={{
                  border: "none",
                  marginTop: 10,
                  backgroundColor: "none",
                }}
                classes={{
                  root: classes.rootAccordion,
                }}
                elevation={0}
              >
                <AccordionSummary
                  style={{
                    background: "#F1F8FF",
                    border: "1px solid rgba(0, 0, 0, .125)",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    {t("medical-record-family-history")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails></AccordionDetails>
              </Accordion>
            </TabPanel>
          </SwipeableViews>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default MedicalRecordDialog;
