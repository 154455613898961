import * as moment from "moment";

export function defaultPlot(data, t) {
    const plot1Options = {
        grid: {
            "bottom": 90
        },
        tooltip: {
            trigger: 'item',
            formatter: (obj) => {
                return "<table>" + 
                "<tr><td>" + t("value") + ": </td>" +
                "<td align = 'right'>" + obj.data[1] + ((obj.data[2] == null) ? "" : " " + obj.data[2]) + "</td></tr>" +
                "<tr><td>" + t("date") + ": </td>" +
                "<td align = 'right'>" + moment(obj.value[0]).format("LL") + "</td></tr>" +
                "<tr><td>" + t("hour") + ": </td>" +
                "<td align = 'right'>" + moment.utc(obj.value[0]).format("HH:mm:ss") + "</td></tr>" +
                "</table>"
            }
        },
        xAxis: {
            type: 'time',
            name: t("date"),
            nameLocation: "middle",
            nameGap: 25
        },
        yAxis: {
            type: 'value',
            min: function (value) {
                return parseInt(value.min - value.min*0.05);
            },
            max: function (value) {
                return parseInt(value.max + value.max*0.05);
            },
            name: t("value"),
            nameLocation: "middle",
            nameGap: 40
        },
        dataZoom: [
            {   
                type: "slider",
                show: true,
                realtime: true,
                start: 70,
                end: 100,
                minValueSpan: 3600*24*1000*14,
                xAxisIndex: [0]
            },
        ],
        dataset: [
            {
                source: data.exam_data,
            }
        ],
        legend: {
            top: "bottom"
        },
        series: [
            {
                type: "line",
                encode: {
                    x: "date",
                    y: "value",
                },
                symbolSize: 8
            }
        ]
    }

    return plot1Options;
};

export function hemoglucoPlot(data, t) {
    const legendList = [t("exams-results-weekly-avg")]
    let legendDict = {}
    legendList.forEach(item =>{
        legendDict[item] = false
    })

    // Meds
    var marks = []

    if ('meds' in data){
        Object.entries(data.meds).map(item => {
            marks.push({
                label: {
                    formatter: item[1][1].join("\n"),
                    position: "end",
                    padding: [0, 0, -12*(item[1][1].length)-12, 8*Math.max(...(item[1][1].map(el => el.length)))],
                },
                xAxis: item[1][0]
            })
        })
    }

    const plot1Options = {
        grid: {
            "bottom": 90
        },
        tooltip: {
            formatter: (obj) => {
                return "<table>" + 
                "<tr><td>" + t("value") + ": </td>" +
                "<td align = 'right'>" + obj.data[1].toFixed(2) + ((obj.data[2] == null) ? "" : " " + obj.data[2]) + "</td></tr>" +
                "<tr><td>" + t("date") + ": </td>" +
                "<td align = 'right'>" + moment(obj.value[0]).format("LL") + "</td></tr>" +
                ((moment.utc(obj.value[0]).format("HH:mm:ss") == "00:00:00") ?
                "" : "<tr><td>" + t("hour") + ": </td>" + "<td align = 'right'>" + moment.utc(obj.value[0]).format("HH:mm:ss") + "</td></tr>") + 
                "</table>"
            }
        },
        xAxis: {
            type: 'time',
            name: t("date"),
            nameLocation: "middle",
            nameGap: 25
        },
        yAxis: {
            type: 'value',
            min: function (value) {
                return parseInt(value.min - value.min*0.05);
            },
            max: function (value) {
                return parseInt(value.max + value.max*0.05);
            },
            name: t("value"),
            nameLocation: "middle",
            nameGap: 40
        },
        dataZoom: [
            {   
                type: "slider",
                show: true,
                realtime: true,
                start: 70,
                end: 100,
                minValueSpan: 3600*24*1000*14,
                xAxisIndex: [0]
            },
        ],
        dataset: [
            {
                source: data.exam_data,
            },
            {
                source: data.weekly_mean,
            }
        ],
        legend: {
            top: "top",
            itemWidth: 12,
            itemHeight: 12,
            selected: legendDict,
            itemGap: 50,
            padding: [0,0,-10,0]
        },
        series: [
            {
                type: "line",
                encode: {
                    x: "date",
                    y: "value",
                },
                symbolSize: 8,
                datasetIndex: 0,
                name: t("exams-results-all")
            },
            {
                type: "line",
                encode: {
                    x: "date",
                    y: "value",
                },
                symbolSize: 8,
                datasetIndex: 1,
                name: t("exams-results-weekly-avg")
            },
            {   
                lineStyle: {
                    opacity: 0,
                    color: "red"
                },
                itemStyle: {
                    opacity: 0,
                    color: "red"
                },
                type: 'line',
                markLine : {
                    silent: true,
                    symbol: "none",
                    data: marks
                },
                name: t("medicines")
            } 
        ]
    }

    return plot1Options;
};