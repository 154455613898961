import { UPDATE_PATIENTS } from "../actionTypes";

const defaultState = [];

const patients = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_PATIENTS:
      return payload;
    default:
      return state;
  }
};

export default patients;
