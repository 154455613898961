import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "min-height": "100vh",
  },
  hide: {
    display: "none",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButtonHidden: {
    display: "none",
  },
  drawerPaper: {
    width: drawerWidth,
    height: "auto",
    borderRadius: "0px 0px 15px 0px",
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    maxWidth: "100%",
    paddingTop: 10,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  container: {
    "padding-left": 10,
    "padding-right": 10,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  maxHeight: {
    height: "100%",
  },
  positionBottom: {
    position: "absolute",
    bottom: "10px",
    width: "100%",
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  blackText: {
    color: "black",
  },
  titleVoyager: {
    "font-size": "35px",
    "font-weight": "bold",
    color: "#021842",
  },
  imageIcon: {
    height: "100%",
    width: "100%",
    filter: "contrast(0) brightness(0)",
  },
  iconRoot: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    marginLeft: theme.spacing(1),
    textAlign: "center",
  },
  justifyCenterVertical: {
    display: "flex",
    "justify-content": "flex-start",
    "align-items": "center",
  },
  justifyCenterVerticalFlexEnd: {
    display: "flex",
    "justify-content": "flex-end",
    "align-items": "center",
  },
  verticalLine: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    backgroundColor: "#021842",
    marginLeft: theme.spacing(1),
    width: "3px",
    height: "30px",
    float: "left",
  },
  toolbarButton: {
    backgroundColor: "#E6ECF2",
    marginLeft: theme.spacing(1),
  },
  toolbarIconColor: {
    color: "#0C2975",
  },
}));

export default useStyles;
