const config = require("../config.json");
const baseUrl = config["baseUrl"];
const apiUrl = 'expert-system';
const env = config["env"];
import axios from 'axios';

const requestUrl = baseUrl + apiUrl + "-" + env

export function createExpertSystem(id, token) {

    let config = {
        method: 'post',
        url: requestUrl + '/create',
        data: {
            'patient_id': id,
        },
        headers: {
            Authorization: token,
        }
    };

    return axios(config);
}

export function checkExpertSystem(id, value, token) {

    let config = {
        method: 'post',
        url: requestUrl + '/check/hba1c',
        data: {
            'expert_id': id,
            'hba1c': parseFloat(value)
        },
        headers: {
            Authorization: token,
        }
    };

    return axios(config);
}

export function acceptExpertSystemRecommendation(id, pharma, token) {

    let config = {
        method: 'post',
        url: requestUrl + '/check/pharma',
        data: {
            'expert_id': id,
            'pharma': pharma
        },
        headers: {
            Authorization: token,
        }
    };

    return axios(config);
}

export function resetExpertSystemRecommendation(id, pharma, token) {

    let config = {
        method: 'post',
        url: requestUrl + '/check/pharma',
        data: {
            'expert_id': id,
            'pharma': pharma
        },
        headers: {
            Authorization: token,
        }
    };

    return axios(config);
}