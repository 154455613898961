import { UPDATE_PRACTITIONERS } from "../actionTypes";

const defaultState = null;

const practitioners = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_PRACTITIONERS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default practitioners;
