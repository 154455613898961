import React from "react";
import "react-h5-audio-player/lib/styles.css";
import {
  CardContent,
  Card,
  Grid,
  TextField,
  Typography,
  Toolbar,
  IconButton,
  Button,
  AppBar,
  Divider,
  FormControl,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Skeleton } from "@material-ui/lab";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  previousStep,
  updatePatientEncounterDetails,
} from "../../store/actions";
import { searchEncounter } from "../../services/fhir-api-services";
import AppointmentFormHeader from "../../components/appointment/AppointmentFormHeader";
import AppointmentFormStepName from "../../components/appointment/AppointmentFormStepName";
import {
  AccountTieOutline,
  AccountOutline,
  Calendar,
  CalendarRange,
  Lan,
} from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import useStyles, {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./styles";
import useAppointmentDetails from "./hooks";
import Layout from "../../components/layouts/Base";
const AppointmentSummary = ({
  appointment,
  appointments,
  user,
  practitioners,
  organizations,
  dispatch,
  id,
}) => {
  const [expanded, setExpanded, events] = useAppointmentDetails(
    appointment,
    appointments,
    user,
    practitioners,
    organizations,
    dispatch,
    id
  );

  const { handleChange, searchEncounterById } = events;

  const classes = useStyles();
  let { idPatient } = useParams();
  const { t, i18n } = useTranslation();

  let stepsResults = appointment
    ? Object.entries(appointment)
        .sort((a, b) => a[1].step - b[1].step)
        .map((step) => {
          return {
            step: step[1].step + 1,
            stepName: step[1].stepName,
            name: step[0],
            comprehend: step[1].comprehend,
            transcription: step[1].transcription,
          };
        })
    : [];

  let currentAppointment = appointments
    ? appointments.find((a) => a.id === id)
    : {};
  console.log(appointment);
  return (
    <Layout>
      <div>
        <AppBar className={classes.appBar} position="static">
          <Toolbar className={classes.toolBar}>
            <Grid container direction="row" className={classes.flexBetween}>
              <Grid item xs={4} className={classes.flexStart}>
                <IconButton
                  size="small"
                  color="primary"
                  className={useStyles().backButton}
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4} className={classes.flexCenter}>
                <Typography
                  component="h2"
                  style={{ fontSize: "18px" }}
                  variant="h6"
                  color="primary"
                  className={useStyles().white}
                >
                  {t("attention-history")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.flexEnd}></Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar elevation={0} classes={classes.bgWhite} position="static">
          <Toolbar className={classes.toolBarSecondary}>
            <Grid container direction="column">
              <Grid container direction="row" className={classes.flexBetween}>
                <Grid item xs={6} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountTieOutline
                            className={classes.iconTitleData}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontTitleData}
                          >
                            {t("professional")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountTieOutline
                            className={classes.iconTransparent}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontValueSize}
                          >
                            {currentAppointment.practitionerName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountOutline className={classes.iconTitleData} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontTitleData}
                          >
                            {t("patient")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <AccountOutline className={classes.iconTransparent} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontValueSize}
                          >
                            {currentAppointment.patientName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container direction="row" className={classes.flexBetween}>
                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTitleData} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontTitleData}
                          >
                            {t("modality")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTransparent} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontValueSize}
                          >
                            {currentAppointment.appointmentName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTitleData} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontTitleData}
                          >
                            {t("appointment-date")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTransparent} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontValueSize}
                          >
                            {currentAppointment.date}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTitleData} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontTitleData}
                          >
                            {t("registered-date")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <CalendarRange className={classes.iconTransparent} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontValueSize}
                          >
                            {currentAppointment.created ||
                              currentAppointment.date}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} className={classes.flexStart}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <Lan className={classes.iconTitleData} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontTitleData}
                          >
                            {t("clinical-area")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        <Grid item>
                          <Lan className={classes.iconTransparent} />
                        </Grid>
                        <Grid item>
                          <Typography
                            component="span"
                            color="primary"
                            className={useStyles().fontValueSize}
                          >
                            {currentAppointment.practitioner}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Card className={classes.noPadding}>
          <CardContent>
            <Card className={classes.noBorder} variant="outlined">
              <CardContent className={classes.noPadding}>
                <Typography className={classes.textAreaTitle} gutterBottom>
                  {t("summary-step-subtitle")}
                </Typography>

                {!appointment ? (
                  <>
                    <Skeleton
                      animation="wave"
                      height={80}
                      width="100%"
                      style={{ marginBottom: 3 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={80}
                      width="100%"
                      style={{ marginBottom: 3 }}
                    />
                  </>
                ) : (
                  <></>
                )}
                {stepsResults.map((step, index) =>
                  step.transcription !== "" ? (
                    <Accordion
                      style={{ border: "none" }}
                      expanded={expanded === "panel" + step.step}
                      onChange={handleChange("panel" + step.step)}
                      key={index}
                    >
                      <AccordionSummary
                        className={classes.accordionDetails}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {t("step") + " " + step.step}
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                          {step.stepName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          className={classes.marginTopTextField}
                          id="outlined-multiline-static"
                          label={t("transcription")}
                          value={step.transcription}
                          disabled
                          fullWidth
                          multiline
                          rows={4}
                          variant="outlined"
                        ></TextField>

                        {step.comprehend &&
                        step.comprehend.condition_entities &&
                        step.comprehend.condition_entities.length > 0 ? (
                          <Typography
                            className={classes.resultSubtitle}
                            gutterBottom
                          >
                            {t("findings")}
                          </Typography>
                        ) : null}

                        {step.comprehend && step.comprehend.condition_entities
                          ? step.comprehend.condition_entities.map(
                              (value, index) => {
                                return (
                                  <Grid container spacing={1} key={index}>
                                    <Grid item xs={4}>
                                      <TextField
                                        margin="dense"
                                        value={value.finding}
                                        onChange={(event) => {}}
                                        label={t("finding")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        margin="dense"
                                        value={
                                          value.presence
                                            ? t("presence-single")
                                            : t("missing")
                                        }
                                        onChange={(event) => {}}
                                        label={t("presence")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        margin="dense"
                                        value={
                                          value.attributes.length > 0
                                            ? value.attributes
                                                .map(
                                                  (attr) =>
                                                    attr.Type +
                                                    ": " +
                                                    attr.Text_es
                                                )
                                                .join(",")
                                            : "- - - - -"
                                        }
                                        onChange={(event) => {}}
                                        label={t("attributes")}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )
                          : null}

                        {step.comprehend &&
                        step.comprehend.pharma_entities &&
                        step.comprehend.pharma_entities.length > 0 ? (
                          <Typography
                            className={classes.resultSubtitle}
                            gutterBottom
                          >
                            {t("medicines")}
                          </Typography>
                        ) : null}

                        {step.comprehend && step.comprehend.pharma_entities
                          ? step.comprehend.pharma_entities.map(
                              (value, index) => {
                                return (
                                  <Grid container spacing={1} key={index}>
                                    <Grid item xs={3}>
                                      <TextField
                                        margin="dense"
                                        value={value.name}
                                        onChange={(event) => {}}
                                        label={t("name")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextField
                                        margin="dense"
                                        value={value.composition}
                                        onChange={(event) => {}}
                                        label={t("composition")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextField
                                        margin="dense"
                                        value={value.dosage}
                                        onChange={(event) => {}}
                                        label={t("dosage")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <TextField
                                        margin="dense"
                                        value={value.frequency}
                                        onChange={(event) => {}}
                                        label={t("frequency")}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )
                          : null}

                        {step.comprehend &&
                        step.comprehend.test_entities &&
                        step.comprehend.test_entities.length > 0 ? (
                          <Typography
                            className={classes.resultSubtitle}
                            gutterBottom
                          >
                            {t("exams")}
                          </Typography>
                        ) : null}

                        {step.comprehend && step.comprehend.test_entities
                          ? step.comprehend.test_entities.map(
                              (value, index) => {
                                return (
                                  <Grid container spacing={1} key={index}>
                                    <Grid item xs={4}>
                                      <TextField
                                        margin="dense"
                                        value={value.name}
                                        onChange={(event) => {}}
                                        label={t("name")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        margin="dense"
                                        value={value.value}
                                        onChange={(event) => {}}
                                        label={t("value")}
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        margin="dense"
                                        value={value.unit}
                                        onChange={(event) => {}}
                                        label={t("unit")}
                                        fullWidth
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              }
                            )
                          : null}
                      </AccordionDetails>
                    </Accordion>
                  ) : null
                )}
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

const getAppointment = (state) => ({
  appointment: state.patientEncounterDetails,
  user: state.user,
  practitioners: state.practitioners,
  organizations: state.organizations,
  appointments: state.patientAppointments,
});

export default connect(getAppointment)(AppointmentSummary);
