import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
  Skeleton,
} from "@material-ui/lab";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Divider,
  IconButton,
  Chip,
  Tooltip,
  Badge,
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  AccountSearch,
  AccountVoice,
  ClipboardPulseOutline,
  FileAlert,
  FileEyeOutline,
  MicrophonePlus,
  FlaskOutline,
  VirusOutline,
  Pill,
} from "mdi-material-ui";
import { PlusCircleOutline } from "mdi-material-ui";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import NewAppointmentDialog from "../../components/forms/NewAppointmentForm";
import {
  createAppointment,
  listAppointments,
  uploadPatientImage,
} from "../../services/fhir-api-services";
import {
  initAppointment,
  updatePatientAppointments,
  updatePatientEncounterDetails,
  updatePatientExams,
  updatePatientId,
} from "../../store/actions";
import moment from "moment";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import {
  loadAppointments,
  loadExpertSystem,
  loadPractitioners,
} from "../../services/utils/load-data-services";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layouts/Base";
import HeaderPatient from "../../components/headerPatients/HeaderPatient";
import useStyles from "./style";

const getData = (state) => ({
  user: state.user,
  patients: state.patients,
  practitioners: state.practitioners,
  appointments: state.patientAppointments,
  expertSystem: state.patientExpertSystem,
});

const FilterComponent = ({ filterText, onFilter, filterLabelText }) => (
  <TextField
    id="search"
    type="text"
    margin="dense"
    placeholder={filterLabelText}
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <AccountSearch />
        </InputAdornment>
      ),
    }}
  />
);

const PatientAppointments = ({
  props,
  user,
  patients,
  practitioners,
  appointments,
  expertSystem,
  dispatch,
}) => {
  let history = useHistory();
  const dialogRef = useRef();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  let { idPatient } = useParams();

  if (!patients) {
    history.push("/patients");
  }

  let cPatient = patients ? patients.find((pat) => pat.id === idPatient) : null;
  const [patient, setPatientValue] = useState(cPatient);
  const [filterText, setFilterText] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(
    cPatient && cPatient.photo ? cPatient.photo : "/profile.png"
  );
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);

  const handleClickOpen = () => {
    dialogRef.current.openDialog(user, [], patients, practitioners, patient);
  };

  const handleClearFilter = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  // const goToExams = () => {
  //   dispatch(updatePatientExams(null));
  //   history.push(`/patient-exams/${id}`);
  // };

  useEffect(() => {
    if (!appointments && !loadingAppointments) {
      loadAppointments(user, dispatch, idPatient);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }

    if (!expertSystem) {
      loadExpertSystem(idPatient, user, dispatch);
    }

    // loadHGT(id, user, dispatch);
  }, [user]);

  const filteredAppointments =
    appointments && patient
      ? Object.values(appointments).filter(
          (item) =>
            (item.appointmentName &&
              item.appointmentName
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item.practitioner &&
              item.practitioner
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item.appointmentType &&
              item.appointmentType
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item.practitionerName &&
              item.practitionerName
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item.date &&
              item.date.toLowerCase().includes(filterText.toLowerCase()))
        )
      : [];

  const goToForm = (appointment, id) => {
    dispatch(initAppointment());
    dispatch(updatePatientId(id));
    history.push(`/patient/${id}/appointment-form/${appointment.id}`);
  };

  const goToDetails = (appointment) => {
    dispatch(updatePatientEncounterDetails(null));
    history.push(`/appointment-details/${appointment.id}`);
  };

  const uploadProfilePhoto = (files) => {
    if (files && files.length > 0) {
      let fr = new FileReader();
      fr.onload = function (e) {
        setLoadingPhoto(true);
        uploadPatientImage(
          idPatient,
          e.target.result.toString(),
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            setProfilePhoto(e.target.result.toString());
            setLoadingPhoto(false);
          })
          .catch((e) => {
            setLoadingPhoto(false);
            console.log(e);
          });
      };
      fr.readAsDataURL(files[0]);
    }
  };

  const addNewAppointment = (a, a_id) => {
    if (appointments) {
      let nAppointments = appointments;
      nAppointments.push(a);
      dispatch(updatePatientAppointments(nAppointments));
      dispatch(initAppointment());
      dispatch(updatePatientId(a_id));
      history.push(`patient/${a_id}/appointment-form/${appointment.a_id}`);
    }
  };

  const getInteractionIcon = (i, count, index) => {
    if (count > 0) {
      switch (i) {
        case "statements":
          return (
            <Tooltip
              key={index}
              title={t("patient-appointment-previous-medication")}
            >
              <Badge
                badgeContent={count}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                color="secondary"
              >
                <AccountVoice style={{ marginLeft: 10 }} color="secondary" />
              </Badge>
            </Tooltip>
          );
        case "requests":
          return (
            <Tooltip
              key={index}
              title={t("patient-appointment-prescription-drugs")}
            >
              <Badge
                badgeContent={count}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                color="secondary"
              >
                <Pill style={{ marginLeft: 10 }} color="secondary" />
              </Badge>
            </Tooltip>
          );
        case "observations":
          return (
            <Tooltip key={index} title={t("patient-appointment-exams-results")}>
              <Badge
                badgeContent={count}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                color="secondary"
              >
                <FileEyeOutline style={{ marginLeft: 10 }} color="secondary" />
              </Badge>
            </Tooltip>
          );
        case "symptoms":
          return (
            <Tooltip key={index} title={t("patient-appointment-new-symptoms")}>
              <Badge
                badgeContent={count}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                color="secondary"
              >
                <VirusOutline style={{ marginLeft: 10 }} color="secondary" />
              </Badge>
            </Tooltip>
          );
        case "diagnostics":
          return (
            <Tooltip key={index} title={t("patient-appointment-new-diagnosis")}>
              <Badge
                badgeContent={count}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                color="secondary"
              >
                <ClipboardPulseOutline
                  style={{ marginLeft: 10 }}
                  color="secondary"
                />
              </Badge>
            </Tooltip>
          );
        default:
          return (
            <Tooltip
              key={index}
              title={t("patient-appointment-previous-medication")}
            >
              <Badge
                badgeContent={count}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                color="secondary"
              >
                <FlaskOutline style={{ marginLeft: 10 }} color="secondary" />
              </Badge>
            </Tooltip>
          );
      }
    } else {
      return <></>;
    }
  };
  return (
    <Layout clientHeader>
      <Card className={classes.noPadding} elevation={0}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={useStyles().displayContent}
          >
            <Grid container alignItems="flex-start" item={true} xs={6}>
              <Button
                className={useStyles().button}
                classes={{ endIcon: classes.buttonAddStyle }}
                style={{ marginRight: 10 }}
                endIcon={<MicrophonePlus />}
                onClick={handleClickOpen}
                variant="contained"
                color="secondary"
                disableElevation
              >
                <span className={useStyles().spanButton}>
                  {t("new-medical-care")}
                </span>
              </Button>
              {/* <Button
                className={useStyles().button}
                classes={{ endIcon: classes.buttonAddStyle }}
                style={{ marginRight: 10 }}
                endIcon={<FlaskOutline />}
                onClick={goToExams}
                variant="contained"
                color="secondary"
                disableElevation
              >
                <span className={useStyles().spanButton}>{t("see-exams")}</span>
              </Button> */}
            </Grid>
            <Grid
              container
              item={true}
              xs={6}
              className={useStyles().rightAlign}
            >
              {/* <Button
                className={useStyles().button}
                classes={{ endIcon: classes.buttonAddStyle }}
                style={{ backgroundColor: "#FA6A09" }}
                endIcon={<ClipboardPulseOutline style={{ color: "white" }} />}
                onClick={goToMedicalRecord}
                variant="contained"
                disableElevation
              >
                <span
                  className={useStyles().spanButton}
                  style={{ color: "white" }}
                >
                  {t("look-medical-record")}
                </span>
              </Button> */}
            </Grid>
          </Grid>

          <Grid
            container
            item={true}
            xs={12}
            className={useStyles().rightAlign}
          >
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClearFilter}
              filterText={filterText}
              filterLabelText={t("filter-label-text")}
            />
          </Grid>
          <Timeline className={classes.noPadding}>
            {filteredAppointments.map((appoint, index, id) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent
                  className={classes.displayHidden}
                ></TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={{ backgroundColor: "#92CEF6" }}>
                    {/*{appoint.icon}*/}
                  </TimelineDot>
                  <TimelineConnector
                    style={{ backgroundColor: "#92CEF6", width: 4 }}
                  />
                </TimelineSeparator>
                <TimelineContent className={classes.noRightPadding}>
                  <Card
                    className={classes.cardClass}
                    elevation={0}
                    variant="outlined"
                    style={{
                      backgroundColor:
                        appoint.appointmentName === "Control"
                          ? "#fff"
                          : "#F1F8FF",
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                        style={{ justifyContent: "space-between" }}
                      >
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="column"
                            spacing={3}
                            style={{ justifyContent: "space-between" }}
                          >
                            <Grid
                              item
                              style={{ paddingTop: 1, paddingBottom: 1 }}
                            >
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    component={"span"}
                                    className={classes.title}
                                  >
                                    <b style={{ color: "#021842" }}>
                                      {appoint.appointmentName}
                                    </b>
                                    <span
                                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                    >
                                      {" "}
                                      {appoint.date}{" "}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    component={"span"}
                                    className={classes.title}
                                  >
                                    <b style={{ color: "#021842" }}>
                                      {appoint.practitioner}
                                    </b>
                                    <span
                                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                    >
                                      {" "}
                                      {appoint.practitionerName}{" "}
                                    </span>
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  {appoint.status === "booked" ? (
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() =>
                                        goToForm(appoint, appoint.patientObj.id)
                                      }
                                    >
                                      <FileAlert />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => goToDetails(appoint)}
                                    >
                                      <ChevronRightIcon />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Divider style={{ marginRight: 70 }} />
                            <Grid item>
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item>
                                  <Typography
                                    component={"span"}
                                    style={{ color: "#021842" }}
                                  >
                                    {appoint.appointmentType}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {Object.keys(appoint.interactions).map(
                                    (k, index) =>
                                      getInteractionIcon(
                                        k,
                                        appoint.interactions[k],
                                        index
                                      )
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
          {!appointments ? (
            <Timeline className={classes.noPadding}>
              <TimelineItem>
                <TimelineOppositeContent
                  className={classes.displayHidden}
                ></TimelineOppositeContent>
                <TimelineSeparator>
                  <Skeleton animation="wave" width={40} height={40} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={classes.noRightPadding}>
                  <Card className={classes.root} variant="outlined">
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Skeleton animation="wave" width={40} height={40} />
                        </Grid>
                      </Grid>
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ) : null}
        </CardContent>
      </Card>
      <NewAppointmentDialog
        ref={dialogRef}
        addAppointment={(a, a_id) => addNewAppointment(a, a_id)}
      />
    </Layout>
  );
};

export default connect(getData)(PatientAppointments);
