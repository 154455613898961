import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  loadOrganizations,
  loadPatients,
  loadPractitioners,
  loadUsers,
  loadStatisticsData,
} from "../../services/utils/load-data-services";
import {
  initAppointment,
  updateExpertSystem,
  updatePatientAppointments,
  updatePatientId,
} from "../../store/actions";
import useStyles from "./styles";

const usePatients = (
  user,
  users,
  patients,
  practitioners,
  organizations,
  statisticsPlotData,
  dispatch
) => {
  let history = useHistory();
  const dialogRef = useRef();
  const appointmentRef = useRef();

  const [filterText, setFilterText] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const classes = useStyles();
  const [styles, setStyles] = useState({
    buttonPrimaryStyle: useStyles().button,
    buttonPrimarySpanStyle: useStyles().spanButton,
    buttonSecondaryStyle: useStyles().buttonOtherColor,
    buttonSecondarySpanStyle: useStyles().spanButton,
  });
  useEffect(() => {
    console.log(users);
    console.log(user);
    const current =
      user.username.length && users !== null
        ? users.find((u) => u.username === user.username)
        : null;
    setCurrentUser(current);
  }, [users]);

  useEffect(() => {
    if (!users.length) {
      loadUsers(user, dispatch);
    }

    if (!patients.length) {
      loadPatients(user, dispatch);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }

    if (!organizations) {
      loadOrganizations(user, dispatch);
    }

    if (!statisticsPlotData) {
      loadStatisticsData(user, dispatch);
    }
    console.log("user", user);
  }, [user]);

  const goToPatientDetails = (id) => {
    dispatch(updatePatientAppointments(null));
    dispatch(updateExpertSystem(null));
    console.log(id);
    dispatch(updatePatientId(id));
    history.push(`/patient/${id}`);
  };

  const filteredItems = patients
    ? patients.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") &&
            item.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(filterText.toLowerCase())) ||
          (item.identifier &&
            item.identifier.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.identifier.replaceAll(".", "") &&
            item.identifier
              .replaceAll(".", "")
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.lastAppointment &&
            item.lastAppointment
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.nextAppointment &&
            item.nextAppointment
              .toLowerCase()
              .includes(filterText.toLowerCase()))
      )
    : [];

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const addNewAppointment = (a, a_id) => {
    let nAppointments = [];
    nAppointments.push(a);
    dispatch(updatePatientAppointments(nAppointments));
    dispatch(initAppointment());
    history.push(`/appointment-form/${a_id}`);
  };

  const handleClickOpen = (pat, isEdit) => {
    dialogRef.current.openDialog(pat, user, isEdit, organizations);
  };

  const events = {
    goToPatientDetails,
    handleClear,
    addNewAppointment,
    handleClickOpen,
    filteredItems,
  };

  return [
    filterText,
    setFilterText,
    styles,
    classes,
    dialogRef,
    appointmentRef,
    currentUser,
    events,
  ];
};

export default usePatients;
