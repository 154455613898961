import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "react-h5-audio-player/lib/styles.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  AccountMultiple,
  AccountPlus,
  AccountSearch,
  MicrophonePlus,
  PencilOutline,
  TextBoxMultipleOutline,
} from "mdi-material-ui";
import PatientDialog from "../../components/forms/PatientDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import DataTable from "react-data-table-component";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import {
  initAppointment,
  updateExpertSystem,
  updatePatientAppointments,
  updatePractitioners,
} from "../../store/actions";
import Typography from "@material-ui/core/Typography";
import NewAppointmentDialog from "../../components/forms/NewAppointmentForm";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {
  loadOrganizations,
  loadPatients,
  loadPractitioners,
  loadUsers,
} from "../../services/utils/load-data-services";
import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import "moment/locale/es";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { searchPatients } from "../../services/fhir-api-services";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import useStyles from "./style";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "#F1F8FF",
      borderBottom: "2px solid",
      borderColor: "#FA6A09",
    },
  },
};

const getData = (state) => ({
  user: state.user,
  users: state.users,
  organizations: state.organizations,
  practitioners: state.practitioners,
});

const SearchPatient = ({
  user,
  users,
  organizations,
  practitioners,
  dispatch,
}) => {
  const classes = useStyles();
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const [filterText, setFilterText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [patients, setPatients] = React.useState([]);
  const [selectedSearchOption, setSelectedSearchOption] = React.useState("rut");
  const [searchValue, setSearchValue] = React.useState("");
  const [styles, setStyles] = React.useState({
    buttonPrimaryStyle: useStyles().button,
    buttonPrimarySpanStyle: useStyles().spanButton,
    buttonSecondaryStyle: useStyles().buttonOtherColor,
    buttonSecondarySpanStyle: useStyles().spanButton,
  });

  useEffect(() => {
    if (!users) {
      loadUsers(user, dispatch);
    }

    if (!organizations) {
      loadOrganizations(user, dispatch);
    }

    if (!practitioners) {
      loadPractitioners(user, dispatch);
    }
  }, [user]);

  const goToPatientDetails = (id) => {
    dispatch(updatePatientAppointments(null));
    dispatch(updateExpertSystem(null));
    history.push(`/patient/${id}`);
  };

  const searchPatientByAttribute = () => {
    setLoading(true);
    searchPatients(
      selectedSearchOption,
      searchValue,
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    )
      .then((response) => {
        let nPatients = [];
        response.data.forEach((pat) => {
          let patient = Object.assign(pat, {
            name: pat.given + " " + pat.family,
            lastAppointment: new Date().toISOString().substr(0, 10),
            bd: pat.birthDate,
            nextAppointment: new Date().toISOString().substr(0, 10),
          });
          patient.birthDate = new Date(patient.birthDate);
          nPatients.push(patient);
        });
        setPatients(nPatients);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const handleSelectedOptionChange = () => (event, value) => {
    setSelectedSearchOption(event.target.value);
  };

  const handleSearchValueChange = () => (event, value) => {
    setSearchValue(event.target.value);
  };

  const columns = [
    {
      name: t("patient"),
      selector: "name",
      cell: (a) => (
        <Grid container className={classes.centerOnlyVertically}>
          <Avatar
            alt="Patient avatar"
            src={a.photo}
            className={classes.small}
          />
          <span style={{ paddingLeft: 5 }}>{a.name}</span>
        </Grid>
      ),
      sortable: true,
    },
    {
      name: t("patients-rut-or-passport"),
      selector: "identifier",
      sortable: true,
      right: true,
      hide: "sm",
    },
    {
      name: t("actions"),
      cell: (a) => (
        <Grid container className={classes.centerVertically}>
          <IconButton
            size="small"
            style={{ backgroundColor: "#346DFF", marginLeft: 5 }}
            color="secondary"
            onClick={() => goToPatientDetails(a.id)}
          >
            <TextBoxMultipleOutline style={{ color: "white", padding: 2 }} />
          </IconButton>
        </Grid>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: false,
    },
  ];

  const filteredItems = patients
    ? patients.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.identifier &&
            item.identifier.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.lastAppointment &&
            item.lastAppointment
              .toLowerCase()
              .includes(filterText.toLowerCase())) ||
          (item.nextAppointment &&
            item.nextAppointment
              .toLowerCase()
              .includes(filterText.toLowerCase()))
      )
    : [];

  const currentUser =
    user && users ? users.find((u) => u.username === user.username) : null;

  return (
    <>
      <div>
        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-area")}:{" "}
                {currentUser
                  ? t(currentUser.practitioner)
                  : t("user-not-defined")}
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography>
                {t("user-medical-center")}:{" "}
                {currentUser ? currentUser.organization : t("user-not-defined")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.spaceBetween}>
            <Grid item xs={"auto"}>
              <Typography variant="h5" gutterBottom>
                <b>
                  {currentUser
                    ? currentUser.name || currentUser.username
                    : t("user-no-name")}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={"auto"}>
              <Typography variant="h7" gutterBottom>
                {moment().locale("es").format("llll")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={"auto"} className={classes.verticalAlignBlock}>
          <Grid
            container
            spacing={3}
            direction="row"
            className={classes.spaceBetween}
          >
            <Grid item xs={6}>
              <TextField
                value={searchValue}
                onChange={handleSearchValueChange()}
                id="value"
                label={t("search-patient-filter-placeholder")}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              alignItems="center"
              justify="flex-end"
              className={classes.displayFlex}
            >
              {loading ? (
                <CircularProgress className={classes.marginCircular} />
              ) : (
                <div>
                  <Button
                    classes={{ endIcon: classes.buttonAddStyle }}
                    className={styles.buttonPrimaryStyle}
                    disableElevation
                    endIcon={<AccountSearch />}
                    variant="contained"
                    onClick={() => searchPatientByAttribute()}
                    color="secondary"
                  >
                    <span className={styles.buttonPrimarySpanStyle}>
                      {t("search-patient")}
                    </span>
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <DataTable
          noHeader
          striped
          persistTableHead
          responsive
          pagination
          paginationComponentOptions={{
            rowsPerPageText: t("elements-per-page"),
            rangeSeparatorText: t("range-separator-text"),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: t("select-all-rows"),
          }}
          noDataComponent={t("search-patient-table-placeholder")}
          columns={columns}
          customStyles={customStyles}
          data={filteredItems}
        />
      </div>
    </>
  );
};

export default connect(getData)(SearchPatient);
