import { UPDATE_EXPERT_SYSTEM } from "../actionTypes";

const defaultState = {
  expertSystem: null,
};
const patientExpertSystem = (state = defaultState, { type, payload }) => {
  switch (type) {
    case UPDATE_EXPERT_SYSTEM:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default patientExpertSystem;
