export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const NEW_STEP = "NEW_STEP";
export const LOAD_APPOINTMENT = "LOAD_APPOINTMENT";
export const ADD_PATIENT_APPOINTMENT = "ADD_PATIENT_APPOINTMENT";
export const SAVE_PATIENT = "SAVE_PATIENT";
export const SAVE_AUDIO = "SAVE_AUDIO";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PATIENTS = "UPDATE_PATIENTS";
export const UPDATE_PATIENT_ID = "UPDATE_PATIENT_ID";
export const UPDATE_ORGANIZATIONS = "UPDATE_ORGANIZATIONS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_PRACTITIONERS = "UPDATE_PRACTITIONERS";
export const UPDATE_PATIENT_APPOINTMENTS = "UPDATE_PATIENT_APPOINTMENTS";
export const UPDATE_PATIENT_ENCOUNTER_DETAILS =
  "UPDATE_PATIENT_ENCOUNTER_DETAILS";
export const SAVE_PHARMACOLOGICAL_INTERACTIONS =
  "SAVE_PHARMACOLOGICAL_INTERACTIONS";
export const UPDATE_EXPERT_SYSTEM = "UPDATE_EXPERT_SYSTEM";
export const UPDATE_PATIENT_EXAMS = "UPDATE_PATIENT_EXAMS";
export const UPDATE_EXAM_TYPES = "UPDATE_EXAM_TYPES";
export const UPDATE_MEDICAL_RECORD = "UPDATE_MEDICAL_RECORD";
export const UPDATE_STATISTICS_DATA = "UPDATE_STATISTICS_DATA";
