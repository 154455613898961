import React, { useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import AppointmentFormStepName from "../../components/appointment/AppointmentFormStepName";
import AppointmentFormStepper from "../../components/appointment/AppointmentFormStepper";
import { nextStep, savePatientData } from "../../store/actions";
import { connect } from "react-redux";
import { analyzeDataComprehendMedical } from "../../services/structure-data-services";
import { useTranslation } from "react-i18next";
import {
  CardContent,
  Card,
  Grid,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
  Typography,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Qrcode } from "mdi-material-ui";
import { useStyles } from "./styles";

const getSummary = (state) => ({
  user: state.user,
  summary: state.appointmentStep.appointment,
});

const AppointmentStep = ({
  currentAppointment,
  step,
  summary,
  user,
  subStepName,
  dispatch,
  currentStep,
  maxSteps,
  maxStepsAllForm,
  stepName,
  stepNumber,
}) => {
  const { patientName, appointmentName: motive } = currentAppointment;
  const classes = useStyles();
  const { t } = useTranslation();
  const [missingAudio, setMissingAudio] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [comprehend, setComprehend] = useState(
    step in summary && summary[step].comprehend
      ? { data: summary[step].comprehend }
      : {}
  );
  const [changed, setChanged] = useState(false);
  const [transcribeOption, setTranscribeOption] = useState("Streaming");
  const [uploading, setUploading] = useState(false);
  const [loadingComprehend, setLoadingComprehend] = useState(false);
  const [transcriptionId, setTranscriptionId] = useState(
    step in summary && summary[step].transcriptionId
      ? summary[step].transcriptionId
      : ""
  );
  //const [currentTranscription, setCurrentTranscription] = useState("");

  const [steps, setSteps] = useState([
    { id: 1, label: "Antecedentes", status: 0 },
    { id: 2, label: "Ananmesis", status: 0 },
    { id: 3, label: "Historia Actual", status: 0 },
    { id: 4, label: "Exploración", status: 0 },
    { id: 5, label: "Examen", status: 0 },
    { id: 7, label: "Diagnóstico", status: 0 },
    { id: 8, label: "Tratamiento", status: 0 },
    { id: 9, label: "Revision", status: 0 },
  ]);

  let currentTranscription = "";
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMissingAudio(false);
  };
  useEffect(() => {
    currentTranscription =
      step in summary && summary[step].transcription
        ? summary[step].transcription
        : "";
    setTranscription(
      step in summary && summary[step].transcription
        ? summary[step].transcription
        : ""
    );
  }, []);
  useEffect(() => {
    if (transcription === "" && currentTranscription !== transcription) {
      setTranscription(currentTranscription);
      console.log(transcription);
    }
  }, [transcription]);

  const handleTextChange = (event) => {
    setChanged(true);
    setTranscription(event.target.value);
  };

  const updateStreamingTextHandler = (text) => {
    setTranscription(transcription ? transcription + " " + text : text);

    setChanged(true);
  };

  const sendComprehendMedical = () => {
    //setLoadingComprehend(true);
    setComprehend({});

    return analyzeDataComprehendMedical(
      transcription,
      user.signInUserSession.idToken.jwtToken,
      user.signInUserSession.accessToken.jwtToken
    );
  };

  const cleanAll = () => {
    setTranscription(null);
    setComprehend({});
    setChanged(true);
  };

  const toBackStep = () => {
    console.log("next");
  };

  const savePatientHistory = () => {
    console.log(currentStep);
    if (transcription && (changed || summary.loaded)) {
      console.log("here");
      sendComprehendMedical()
        .then((response) => {
          setComprehend(response);
          const data = {
            step: currentStep,
            appointment: {
              loaded: false,
              transcription: transcription,
              transcriptionId: transcriptionId,
              comprehend: response.data,
              stepName: subStepName,
            },
          };
          dispatch(savePatientData(data));
          setTimeout(() => {
            dispatch(nextStep());
          }, 0);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        savePatientData({
          step: step,
          appointment: {
            transcription: transcription,
            transcriptionId: transcriptionId,
            comprehend: comprehend.data,
            stepName: subStepName,
          },
        })
      );
      dispatch(nextStep());
    }
  };

  return (
    <>
      <div>
        <Card elevation={0} className={classes.noPadding} square>
          <CardContent>
            <Card className={classes.noBorder} variant="outlined">
              <CardContent className={classes.noPadding}>
                {parseInt(currentStep) === 7 ? (
                  <AppointmentFormStepper
                    uploading={uploading}
                    patientName={patientName}
                    motive={motive}
                    stepName={stepName}
                    subStepName={subStepName}
                    stepNumber={stepNumber}
                    maxSteps={steps}
                    steps={steps}
                    activeStep={currentStep}
                    onChangeTranscribeOption={(value) =>
                      setTranscribeOption(value)
                    }
                  />
                ) : (
                  <>
                    <AppointmentFormStepper
                      uploading={uploading}
                      patientName={patientName}
                      motive={motive}
                      stepName={stepName}
                      subStepName={subStepName}
                      stepNumber={stepNumber}
                      maxSteps={steps}
                      steps={steps}
                      activeStep={currentStep}
                      onChangeTranscribeOption={(value) =>
                        setTranscribeOption(value)
                      }
                    />
                    {/* <AppointmentFormStepName
                      step={step}
                      activeStep={currentStep}
                      maxSteps={maxSteps}
                      currentStep={currentStep}
                      maxStepsAllForm={maxStepsAllForm}
                      transcribeOption={transcribeOption}
                      updateStreamingText={updateStreamingTextHandler.bind(
                        this
                      )}
                      transcribing={uploading}
                    /> */}
                    {/* <Typography
                      variant="subtitle1"
                      style={{ marginTop: "50px" }}
                    >
                      {t("transcription")}
                    </Typography>
                    <TextField
                      id="outlined-multiline-static"
                      value={transcription || ""}
                      onChange={(e) => handleTextChange(e)}
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                    /> */}
                    {console.log(transcription)}
                    <Grid container direction="row">
                      <Grid item xs={6} md={6} className={classes.flexStart}>
                        <Button
                          className={useStyles().buttonOtherColor}
                          disabled={loadingComprehend}
                          style={{ borderColor: "#346DFF" }}
                          disableElevation
                          variant="outlined"
                          onClick={() => cleanAll()}
                        >
                          <span
                            className={
                              (useStyles().spanButton, classes.colorBlue)
                            }
                          >
                            {t("clean-all")}
                          </span>
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} className={classes.flexEnd}>
                        {!loadingComprehend ? (
                          <>
                            <Button
                              className={classes.marginTopTextField}
                              onClick={() => {
                                console.log("qreader");
                              }}
                              variant="contained"
                              color="secondary"
                              style={{ marginRight: "15px" }}
                            >
                              <IconButton
                                style={{ padding: 0 }}
                                component="span"
                              >
                                <Qrcode className={classes.colorWhite} />
                              </IconButton>
                            </Button>
                            <Button
                              className={useStyles().buttonOtherColor}
                              disabled={step === "patientHistory"}
                              style={{ borderColor: "#346DFF" }}
                              disableElevation
                              variant="outlined"
                              onClick={() => {
                                toBackStep();
                              }}
                            >
                              <span
                                className={
                                  (useStyles().spanButton, classes.colorBlue)
                                }
                              >
                                {t("Atrás")}
                              </span>
                            </Button>
                            <Button
                              className={classes.marginTopTextField}
                              disabled={uploading}
                              onClick={() => {
                                savePatientHistory();
                              }}
                              variant="contained"
                              color="secondary"
                            >
                              {t("next")}
                            </Button>
                          </>
                        ) : (
                          <CircularProgress className={classes.marginRight} />
                        )}
                      </Grid>
                    </Grid>

                    <Snackbar
                      open={missingAudio}
                      autoHideDuration={1000}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      onClose={closeSnackbar}
                    >
                      <Alert
                        onClose={closeSnackbar}
                        color="info"
                        severity="error"
                      >
                        {t("no-audio-saved")}
                      </Alert>
                    </Snackbar>
                  </>
                )}
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default connect(getSummary)(AppointmentStep);
