import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bgOrange: {
    backgroundColor: "#FA6A09",
  },
  bgWhite: {
    backgroundColor: "#FFF",
  },
  colorWhite: {
    color: "#FFF",
  },
  textField: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  marginDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "border-color": "rgba(255, 255, 255, 0.5)",
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  welcome: {
    marginTop: theme.spacing(10),
    color: "white",
  },
  margin: {
    margin: theme.spacing(1),
  },
  forgotPassword: {
    marginTop: theme.spacing(3),
    "text-align": "center",
    color: "white",
  },
  whiteText: {
    color: "white",
  },
  textFieldWhite: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  title: {
    "font-size": "45px",
  },
  imageIcon: {
    height: "100%",
    width: "100%",
  },
  iconRoot: {
    marginLeft: theme.spacing(1),
    textAlign: "center",
  },
  justifyCenterVertical: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
}));

export default useStyles;
