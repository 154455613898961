import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {},
  seeMore: {
    marginTop: theme.spacing(3),
  },
  displayFlex: {
    display: "flex",
  },
  marginTop: {
    marginTop: 15,
  },
  displayHidden: {
    display: "none",
  },
  centerVertically: {
    display: "flex",
    "align-items": "center",
    margin: "auto",
    "justify-content": "center",
  },
  marginCircular: {
    margin: 10,
  },
  paper: {
    padding: "6px 16px",
  },
  marginBottom: {
    marginBottom: 5,
  },
  verticalAlign: {
    display: "flex",
    margin: "auto",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  noPadding: {
    padding: 0,
  },
  noRightPadding: {
    "padding-right": 0,
  },
  borderRadiusAvatar: {
    borderRadius: "8px 0px 8px 0px",
  },
  extraLargeAvatar: {
    width: "16vw",
    minWidth: 160,
    minHeight: 100,
    height: "25vh",
  },
  cardClass: {},
  spanButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0,
      },
    },
  },
  buttonAddStyle: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  rightAlign: {
    display: "flex",
    "justify-content": "flex-end",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  paddingTop3: {
    paddingTop: theme.spacing(2),
  },
  badgeProfile: {
    transform: "scale(1) translate(10%, 0%)",
    height: "inherit",
  },
}));

export default useStyles;
