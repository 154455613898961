import React, { useState } from "react";
import clsx from "clsx";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  InputAdornment,
  Icon,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { ShieldKeyOutline, Eye } from "mdi-material-ui";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

export default function ForgotPassword() {
  const classes = useStyles();
  let history = useHistory();
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState({
    username: "",
    code: "",
    newPassword: "",
    sentCode: false,
    loadingCode: false,
    changingPassword: false,
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const forgotPasswordHandler = () => {
    setValues({ ...values, loadingCode: true });
    Auth.forgotPassword(values.username)
      .then((data) => {
        setValues({ ...values, sentCode: true, loadingCode: false });
      })
      .catch((err) => {
        setValues({ ...values, loadingCode: false });
        console.log(err);
      });
  };

  const changePassword = () => {
    setValues({ ...values, changingPassword: true });
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(values.username, values.code, values.newPassword)
      .then((data) => {
        setValues({ ...values, changingPassword: false });
        history.push(`/login`);
      })
      .catch((err) => {
        setValues({ ...values, changingPassword: false });
        console.log(err);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid
          container
          justify="center"
          className={classes.justifyCenterVertical}
        >
          <Typography
            component="h1"
            variant="h5"
            className={clsx(classes.title, classes.whiteText)}
          >
            VOYAGER
          </Typography>
          {/* <Icon fontSize="large" classes={{ root: classes.iconRoot }}>
            <img className={classes.imageIcon} src="/logo-unit.svg" />
          </Icon> */}
        </Grid>

        {values.sentCode ? (
          <Typography className={classes.welcome}>
            {t("forgot-password-description2")}
          </Typography>
        ) : (
          <Typography className={classes.welcome}>
            {t("forgot-password-description1")}
          </Typography>
        )}
        <form className={classes.form} noValidate>
          <TextField
            label={t("forgot-password-username")}
            className={clsx(classes.margin, classes.textField)}
            value={values.username}
            onChange={handleChange("username")}
            InputLabelProps={{
              style: { color: "white" },
            }}
            InputProps={{
              classes: {
                root: classes.whiteText,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <AccountCircle className={classes.whiteText} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          {values.sentCode ? (
            <>
              <TextField
                label={t("forgot-password-code")}
                className={clsx(classes.margin, classes.textField)}
                value={values.code}
                onChange={handleChange("code")}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  classes: {
                    root: classes.whiteText,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <ShieldKeyOutline className={classes.whiteText} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <TextField
                label={t("forgot-password-new-password")}
                autoComplete="current-password"
                className={clsx(
                  classes.margin,
                  classes.textField,
                  classes.whiteText
                )}
                value={values.newPassword}
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("newPassword")}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  classes: {
                    root: classes.whiteText,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility className={classes.whiteText} />
                        ) : (
                          <VisibilityOff className={classes.whiteText} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </>
          ) : null}
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.forgotPassword}
          >
            <Grid item xs>
              <Link to="/login" className={classes.whiteText}>
                {t("forgot-password-already-have-account")}
              </Link>
            </Grid>
          </Grid>
          {values.sentCode ? (
            <Button
              fullWidth
              variant="contained"
              className={classes.bgOrange}
              onClick={(e) => {
                e.preventDefault();
                changePassword();
              }}
              className={classes.submit}
            >
              {!values.changingPassword ? (
                <span className={classes.whiteText}>
                  {t("forgot-password-change")}
                </span>
              ) : (
                <CircularProgress size={25} className={classes.colorWhite} />
              )}
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              className={classes.bgOrange}
              onClick={(e) => {
                e.preventDefault();
                forgotPasswordHandler();
              }}
              className={classes.submit}
            >
              {!values.loadingCode ? (
                <span className={classes.whiteText}>
                  {t("forgot-password-recover")}
                </span>
              ) : (
                <CircularProgress size={25} className={classes.colorWhite} />
              )}
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
}
