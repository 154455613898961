import React from "react";
import { AccountSettings, BellOutline, HomeOutline } from "mdi-material-ui";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Grid,
  Typography,
  Toolbar,
  AppBar,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";

export default function NavBarTop({ handleDrawerOpen }) {
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      style={{
        borderRadius: "15px",
        boxShadow: "0px 2px 10px 0px #0000001A",
        borderBottom: "1px solid #B8C7CF",
        backgroundColor: "#FFFFFF",
      }}
      className={clsx(classes.appBar)}
    >
      <Toolbar
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "15px",
          borderBottom: "1px solid #B8C7CF",
        }}
      >
        <IconButton
          edge="start"
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
        <Grid
          container
          justify="center"
          className={classes.justifyCenterVertical}
        >
          <Link to={"/patients"} style={{ textDecoration: "none" }}>
            <Typography
              component="h1"
              variant="h5"
              className={clsx(classes.titleVoyager)}
            >
              VOYAGER
            </Typography>
          </Link>
        </Grid>
        <Grid
          container
          justify="center"
          className={classes.justifyCenterVerticalFlexEnd}
        >
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={"/profile"}
          >
            <IconButton size="small" className={classes.toolbarButton}>
              <AccountSettings className={classes.toolbarIconColor} />
            </IconButton>
          </Link>
          <IconButton size="small" className={classes.toolbarButton}>
            <BellOutline className={classes.toolbarIconColor} />
          </IconButton>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={"/patients"}
          >
            <IconButton size="small" className={classes.toolbarButton}>
              <HomeOutline className={classes.toolbarIconColor} />
            </IconButton>
          </Link>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
