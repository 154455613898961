import { PencilOutline, TextBoxMultipleOutline } from "mdi-material-ui";
import { Grid, Avatar, IconButton } from "@material-ui/core/";

const columns = (t, classes, goToPatientDetails, handleClickOpen) => [
  {
    name: t("patient"),
    selector: "name",
    cell: (a) => (
      <Grid container className={classes.centerOnlyVertically}>
        <Avatar alt="Patient avatar" src={a.photo} className={classes.small} />
        <span style={{ paddingLeft: 5 }}>{a.name}</span>
      </Grid>
    ),
    sortable: true,
  },
  {
    name: t("patients-rut-or-passport"),
    selector: "identifier",
    sortable: true,
    right: true,
    hide: "sm",
  },
  {
    name: t("last-appointment"),
    selector: "lastAppointment",
    sortable: true,
    right: true,
    hide: "sm",
  },
  {
    name: t("next-appointment"),
    selector: "nextAppointment",
    sortable: true,
    right: true,
    hide: "sm",
  },
  {
    name: t("actions"),
    cell: (a) => (
      <Grid container className={classes.centerVertically}>
        <IconButton
          size="small"
          style={{ backgroundColor: "#E6ECF2" }}
          onClick={() => handleClickOpen(a, true)}
        >
          <PencilOutline color="secondary" />
        </IconButton>
        <IconButton
          size="small"
          style={{ backgroundColor: "#346DFF", marginLeft: 5 }}
          color="secondary"
          onClick={() => goToPatientDetails(a.id)}
        >
          <TextBoxMultipleOutline style={{ color: "white", padding: 2 }} />
        </IconButton>
      </Grid>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    sortable: false,
  },
];

export default columns;
