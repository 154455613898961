import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Grid,
  Badge,
  CircularProgress,
  IconButton,
  Avatar,
  Typography,
  Divider,
  Button,
  Paper,
  Popper,
  Grow,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import {
  PlusCircleOutline,
  ClipboardPulseOutline,
  FlaskOutline,
  Eye,
  Heart,
} from "mdi-material-ui";
import IconKidney from "../../statics/images/icons/Kidney.svg";
import IconNervios from "../../statics/images/icons/nervios.svg";
import IconHemoglobina from "../../statics/images/icons/hemoglobina.svg";
import IconPie from "../../statics/images/icons/pie.svg";
import { uploadPatientImage } from "../../services/fhir-api-services";
import MedicalRecordDialog from "../MedicalRecordDialog";
import parseExam from "../../services/utils/parseExam";
import useStyles from "./headerPatients.styles";

const HeaderPatient = ({ user, patients, medicalRecord }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  const dialogRef = useRef();
  let { idPatient } = useParams();
  if (!patients) {
    history.push("/patients");
  }

  let cPatient = patients ? patients.find((pat) => pat.id === idPatient) : null;
  const [patient, setPatientValue] = useState(cPatient);
  const [loadingPhoto, setLoadingPhoto] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(
    cPatient && cPatient.photo ? cPatient.photo : "/profile.png"
  );

  const [openInfoComplications, setOpenInfoComplications] = useState(false);
  const infoComplicationsRef = useRef(null);

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const goToMedicalRecord = () => {
    history.push(`/medical-record/${id}`);
  };

  const goToExams = () => {
    history.push(`/patient/${idPatient}/exams/`);
  };

  const uploadProfilePhoto = (files) => {
    if (files && files.length > 0) {
      let fr = new FileReader();
      fr.onload = function (e) {
        setLoadingPhoto(true);
        uploadPatientImage(
          idPatient,
          e.target.result.toString(),
          user.signInUserSession.idToken.jwtToken,
          user.signInUserSession.accessToken.jwtToken
        )
          .then((response) => {
            setProfilePhoto(e.target.result.toString());
            setLoadingPhoto(false);
          })
          .catch((e) => {
            setLoadingPhoto(false);
          });
      };
      fr.readAsDataURL(files[0]);
    }
  };

  const handleToggle = () => {
    setOpenInfoComplications((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      infoComplicationsRef.current &&
      infoComplicationsRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenInfoComplications(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenInfoComplications(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && openInfoComplications === false) {
      infoComplicationsRef.current.focus();
    }

    prevOpen.current = openInfoComplications;
  }, [openInfoComplications]);

  const birthday_parts = medicalRecord
    ? medicalRecord.patient_birthDate
      ? medicalRecord.patient_birthDate.split("-")
      : ""
    : "";
  const birthday = medicalRecord
    ? new Date(
        birthday_parts[1] + "/" + birthday_parts[2] + "/" + birthday_parts[0]
      )
    : "";
  const ageDifMs = medicalRecord ? Date.now() - birthday.getTime() : "";
  console.log(medicalRecord);
  return (
    <div>
      <Card className={classes.bgHeaderPatient} elevation={0}>
        <CardContent className={classes.contentHeaderPatient}>
          <Card className={classes.cardHeaderPatient} variant="outlined">
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  classes={{ badge: classes.badgeProfile }}
                  badgeContent={
                    <>
                      {loadingPhoto ? (
                        <CircularProgress
                          className={classes.circularProgress}
                        />
                      ) : (
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="upload-photo"
                            name="upload-photo"
                            onChange={(e) => uploadProfilePhoto(e.target.files)}
                          />
                          <IconButton style={{ padding: 0 }} component="span">
                            <PlusCircleOutline
                              className={classes.iconAddProfilePic}
                            />
                          </IconButton>
                        </label>
                      )}
                    </>
                  }
                >
                  <Avatar
                    className={clsx(
                      classes.extraLargeAvatar,
                      classes.borderRadiusAvatar,
                      classes.avatarHeaderPatient
                    )}
                    alt="Patient avatar"
                    src={profilePhoto}
                  />
                </Badge>
                <Grid item xs={8}>
                  <Grid container className={classes.spaceBetween}>
                    <Grid item xs={12} sm={12}>
                      <Typography className={classes.textItem}>
                        <span className={classes.contentTextItem}>
                          <span className={classes.textItemName}>
                            <PersonOutlineIcon />
                            {t("patient-appointment-patient-name")}
                          </span>
                          <span className={classes.w30}>
                            {t("identifier")}:{" "}
                          </span>
                          <span className={classes.w20}>
                            {t("medical-record-patient-sex")}
                            {/* TODO verificar el lenguaje */}
                          </span>
                        </span>
                      </Typography>
                      <Typography className={classes.textContent}>
                        <span className={classes.w50}>
                          {patient ? patient.given + " " + patient.family : ""}
                        </span>
                        <span className={classes.w30}>
                          {patient ? patient.identifier : ""}
                        </span>
                        <span className={classes.w20}>
                          {patient ? t(patient.gender) : ""}
                        </span>
                      </Typography>
                    </Grid>
                    <Divider className={classes.marginBottom} />
                  </Grid>
                  <Divider className={classes.dividerOrange} />
                  <Grid container direction="row">
                    <Grid item xs={12} sm={12}>
                      <Grid container direction="row">
                        <Grid item xs={6}>
                          <Typography
                            component={"span"}
                            className={classes.title}
                          >
                            <span className={classes.textBlue13W500}>
                              {t("Hemoglobina Glicosilada")}:
                            </span>
                          </Typography>
                          <br />
                          <Grid container alignItems="center">
                            <Paper className={classes.paperInformation}>
                              <img
                                src={IconHemoglobina}
                                style={{ width: "17px", height: "30px" }}
                                alt=""
                              />
                            </Paper>
                            <Badge className={classes.badgeBlue}>
                              <Typography
                                component={"span"}
                                className={classes.title}
                              >
                                <span className={classes.textBlue16W600}>
                                  {t(
                                    medicalRecord.exams[
                                      "Hemoglobina glicosilada en sangre."
                                    ].value +
                                      medicalRecord.exams[
                                        "Hemoglobina glicosilada en sangre."
                                      ].unit
                                  )}
                                </span>
                              </Typography>
                            </Badge>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            component={"span"}
                            className={classes.title}
                          >
                            <span className={classes.textBlue13W400}>
                              {t("Complicaciones")}:
                            </span>
                          </Typography>
                          <br />
                          <div className={classes.displayFlex}>
                            <Button className={classes.paperInformation}>
                              <Eye style={{ color: "white", fontSize: 30 }} />
                            </Button>

                            <Button
                              ref={infoComplicationsRef}
                              aria-controls={
                                open ? "menu-list-grow" : undefined
                              }
                              aria-haspopup="true"
                              onClick={handleToggle}
                              className={classes.paperInformation}
                            >
                              <img src={IconKidney} alt="" />
                            </Button>
                            <Button className={classes.paperInformation}>
                              <Heart style={{ color: "white", fontSize: 30 }} />
                            </Button>
                            <Button className={classes.paperInformation}>
                              <img
                                src={IconPie}
                                alt=""
                                style={{
                                  width: "27px",
                                  height: "31px",
                                  marginLeft: "-3px",
                                }}
                              />
                            </Button>
                            <Button className={classes.paperInformation}>
                              <img
                                src={IconNervios}
                                alt=""
                                style={{ width: "27px", height: "31px" }}
                              />
                            </Button>
                          </div>
                          <Popper
                            open={openInfoComplications}
                            anchorEl={infoComplicationsRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            className={classes.dropdownInfoComplications}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={openInfoComplications}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <MenuItem
                                        className={
                                          classes.itemTitleInfoComplications
                                        }
                                      >
                                        <span>Creatinina</span>
                                        <span
                                          className={classes.lineBlue}
                                        ></span>
                                      </MenuItem>
                                      <MenuItem
                                        style={{
                                          paddingLeft: "9px",
                                          paddingTop: "0",
                                          fontSize: "13px",
                                        }}
                                      >
                                        1,6 mg/dL
                                      </MenuItem>
                                      <MenuItem
                                        className={
                                          classes.itemTitleInfoComplications
                                        }
                                      >
                                        Fecha
                                      </MenuItem>
                                      <MenuItem
                                        style={{
                                          paddingLeft: "9px",
                                          paddingTop: "0",
                                          fontSize: "13px",
                                        }}
                                      >
                                        22/04/2021
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={2} item alignItems="center" direction="row" container>
                  <Grid
                    direction="column"
                    item
                    xs={6}
                    container
                    alignItems="center"
                  >
                    {/* Boton de abrir ficha */}
                    <Button
                      className={classes.buttonCircular}
                      //onClick={goToMedicalRecord}
                      onClick={() => handleClickOpenDialog(true)}
                    >
                      <ClipboardPulseOutline
                        style={{ color: "white", fontSize: 42 }}
                      />
                    </Button>
                    <span
                      className={useStyles().spanButton}
                      style={{ color: "black", marginTop: "9px" }}
                    >
                      {t("look-medical-record")}
                    </span>
                  </Grid>
                  <Grid
                    xs={6}
                    item
                    direction="column"
                    container
                    alignItems="center"
                  >
                    {/* Boton de ver examen */}
                    <Button
                      className={classes.buttonCircular}
                      onClick={goToExams}
                    >
                      <FlaskOutline style={{ color: "white", fontSize: 42 }} />
                    </Button>
                    <span
                      className={useStyles().spanButton}
                      style={{ color: "black", marginTop: "9px" }}
                    >
                      {t("see-exams")}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
      {idPatient && medicalRecord.id !== null && (
        <MedicalRecordDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          medicalRecord={medicalRecord}
          patientId={idPatient}
        />
      )}
    </div>
  );
};

export default HeaderPatient;
