import { IconButton, Grid } from "@material-ui/core";
import { PencilOutline } from "mdi-material-ui";

const columns = (t, handleClickOpen) => [
  {
    name: t("organization-columns-name"),
    selector: "name",
    sortable: true,
  },
  {
    name: t("organization-columns-code"),
    selector: "code",
    sortable: true,
    right: true,
    hide: "sm",
  },
  {
    name: t("organization-columns-address"),
    selector: "addressN",
    sortable: true,
    right: true,
    hide: "md",
  },
  {
    name: t("organization-columns-phone-number"),
    selector: "phone",
    sortable: true,
    right: true,
    hide: "md",
  },
  {
    name: t("organization-columns-actions"),
    cell: (a) => (
      <Grid container className={classes.centerVertically}>
        <IconButton
          size="small"
          style={{ backgroundColor: "#E6ECF2" }}
          onClick={() => handleClickOpen(a, true)}
        >
          <PencilOutline color="secondary" />
        </IconButton>
      </Grid>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    sortable: false,
  },
];

export default columns;
