import { makeStyles, withStyles } from "@material-ui/core/styles";
import { StepConnector } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  nameSteep: {
    color: "#B8C7CF",
    fontSize: "18px",
    fontWeight: "700",

    "&::after": {
      content: '" "',
      height: "24px",
      width: "24px",
      backgroundColor: "#FFF",
      border: "3px solid #FB8F46",
      display: "block",
      borderRadius: "50%",
      position: "relative",
      top: "6%",
      left: "42%",
    },
  },
  activeNameSteep: {
    color: "#021842",
    fontSize: "18px",
    fontWeight: "700",

    "&::after": {
      content: '" "',
      height: "37px",
      width: "37px",
      backgroundColor: "#FB8F46",
      display: "block",
      borderRadius: "50%",
      position: "relative",
      top: "0",
      left: "42%",
    },
  },
  completedNameSteep: {
    color: "#B8C7CF",
    fontSize: "18px",
    fontWeight: "700",

    "&::after": {
      content: '"\\2713"',
      height: "24px",
      width: "24px",
      backgroundColor: "#FB8F46",
      color: "#FFF",
      display: "block",
      borderRadius: "50%",
      position: "relative",
      top: "6%",
      left: "42%",
      padding: "1px 5px",
      fontSize: "17px",
    },
  },
  noPadding: {
    padding: "0 !important",
  },
  noBorder: {
    border: "none",
    "padding-bottom": 0,
  },
  appBarAttention: {
    backgroundColor: "#F1F8FF",
    paddingBottom: "2px",
    marginTop: "-5px",
    boxShadow: "none",
  },
  toolbarAttention: {
    backgroundColor: "#FFF",
    borderRadius: "0px 0px 20px 20px",
    minHeight: "100px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  contentAttention: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textAttention: {
    fontSize: "18px",
    backgroundColor: "#F1F8FF",
    borderRadius: "10px",
    padding: "5px 0 5px 15px ",
  },
  namePractitioner: {
    marginLeft: "20px",
    marginTop: "15px",
    padding: "5px 10px",
    color: "#021842",
    fontSize: "18px",
    backgroundColor: "#F1F8FF",
    borderRadius: "10px",
  },
  marginTopTextField: {
    marginTop: theme.spacing(2),
  },
  flexEnd: {
    display: "flex",
    "justify-content": "flex-end",
  },
  flexStart: {
    display: "flex",
    "justify-content": "flex-start",
  },
  marginRight: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonOtherColor: {
    backgroundColor: "#E6ECF2",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
    zIndex: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  verticalAlign: {
    display: "flex",
    margin: "auto",
  },
  fabProgress: {
    position: "absolute",
    color: "#3496FF",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  fabProgressIncomplete: {
    position: "absolute",
    color: "rgba(184, 199, 207, 0.24)",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  stepNameDiv: {
    width: 200,
    height: 200,
    "justify-content": "center",
    position: "relative",
  },
  textAlign: {
    "text-align": "center",
  },
  columnVerticalAlign: {
    "justify-content": "center",
    display: "flex",
    "flex-direction": "column",
    height: "100%",
  },
  topicTitle: {
    "font-size": 20,
    "font-weight": 100,
    "text-align": "center",
  },
  marginTopStepper: {
    marginTop: theme.spacing(5),
  },
  MuiStepLabelLabelMuiStepLabelActive: {
    color: "blue",
  },
  stepperClass: {
    width: "100%",
  },
  titleStep: {
    "margin-top": theme.spacing(3),
    "font-size": 14,
    "font-weight": 700,
    "text-align": "left",
    position: "absolute",
    top: "-60px",
    left: "30%",
  },

  buttonClass: {
    "justify-content": "flex-end",
    "margin-bottom": "auto",
    "margin-top": "auto",
    "align-items": "center",
    display: "block",
  },
  justifySpaceBetween: {
    display: "flex",
    "justify-content": "space-between",
  },
  subtitle: {
    "font-size": "14px",
  },
  formControl: {
    minWidth: "100%",
  },
  titleStepLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: "#021842",
    },
    "& .MuiStepLabel-label": {
      color: "#B8C7CF",
    },
  },
  boderColorBlue: {
    borderColor: "#346DFF",
  },
  colorWhite: {
    color: "#FFF",
  },
  colorBlue: {
    color: "#346DFF",
  },
}));

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 11px)",
    right: "calc(50% + 11px)",
  },
  active: {
    "& $line": {
      borderColor: "#FB8F46",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#FB8F46",
    },
  },
  line: {
    borderColor: "#FB8F46",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export const useQontoStepIconStyles = makeStyles({
  root: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "#fff",
    border: "3px solid #FB8F46",
  },
  active: {
    backgroundColor: "#FB8F46",
    border: "3px solid #FB8F46",
    width: 37,
    height: 37,
    position: "absolute",
    left: "37%",
    top: "-7px",
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: "50%",
  },
  completed: {
    backgroundColor: "#FB8F46",
    zIndex: 1,
    fontSize: 18,
    color: "#fff",
  },
});
