import React, { forwardRef, useImperativeHandle } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {
  Button,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  AppBar,
  Divider,
  DialogContentText,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { CloseCircleOutline } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import { checkExpertSystem } from "../../services/expert-system-services";
import useStyles from "./style";

const ExpertSystemForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [expertSystem, setExpertSystem] = React.useState(null);
  const [isEdit, setEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [values, setValue] = React.useState({
    hba1c: "",
  });

  const handleValueChange = (prop) => (event, value) => {
    setValue({
      ...values,
      [prop]: event.target.value || (value ? value.name : ""),
    });
  };

  const saveExpertSystem = () => {
    setLoading(true);
    checkExpertSystem(
      expertSystem["expert_id"],
      values.hba1c,
      user.signInUserSession.idToken.jwtToken
    )
      .then((response) => {
        setLoading(false);
        setOpen(false);
        props.checkExpert(response.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    openDialog(expert, user, isEdit) {
      setEdit(isEdit);
      setOpen(true);
      setUser(user);
      setExpertSystem(expert);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppBar
            position="static"
            className={classes.appBarStyle}
            elevation={0}
          >
            <Toolbar variant="dense">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={useStyles().displayContent}
              >
                <Grid container item={true} xs={8}>
                  <Typography
                    component="h2"
                    variant="h6"
                    className={useStyles().titleStyle}
                  >
                    {t("expertSystemTitle")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="flex-end"
                  className={useStyles().rightAlign}
                  item={true}
                  xs={4}
                >
                  <IconButton
                    color="primary"
                    aria-label="close button"
                    onClick={handleClose}
                  >
                    <CloseCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>
              {t("expertSystemDescription")}
            </DialogContentText>
            <Divider className={classes.marginDivider} />
            <Grid container spacing={3} className={classes.marginPerGrid}>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  value={values.hba1c}
                  onChange={handleValueChange("hba1c")}
                  label={t("HbA1c")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <CircularProgress className={classes.marginCircular} />
            ) : (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => saveExpertSystem()}
                >
                  <span className={classes.whiteText}>{t("save")}</span>
                </Button>
              </div>
            )}
          </DialogActions>
        </MuiPickersUtilsProvider>
      </Dialog>
    </>
  );
});

export default ExpertSystemForm;
